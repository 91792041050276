import { createSlice, prepareAutoBatched } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import { TableEvent } from '../components/pages/events/eventTable';
import { EventCommentDto, EventDto } from '../api/eventApi';

type EventState = {
    events: TableEvent[];
};

type AcknowledgeState = 'Acknowledged' | 'Unacknowledged';

const initialState = { events: [] } as EventState;

export const eventsSlice = createSlice({
    name: 'events',
    initialState,
    reducers: {
        setCheckedForId: (state: EventState, action: PayloadAction<number>) => {
            const event = state.events.find((e) => e.id === action.payload);
            if (event) {
                event.isChecked = !event.isChecked;
            }
        },
        checkAll: (state: EventState, action: PayloadAction<AcknowledgeState>) => {
            state.events = state.events.map((e) => ({
                ...e,
                isChecked: action.payload === 'Acknowledged' ? e.isAcknowledged : !e.isAcknowledged,
            }));
        },
        uncheckAll: (state: EventState) => {
            state.events = state.events.map((e) => ({ ...e, isChecked: false }));
        },
        setEvents: (state: EventState, action: PayloadAction<TableEvent[]>) => {
            state.events = action.payload;
        },
        setDurationForEvent: {
            reducer: (
                state: EventState,
                action: PayloadAction<{ event: TableEvent; duration: number }>,
            ) => {
                const eventToUpdate = state.events.find((e) => e.id === action.payload.event.id);
                if (!eventToUpdate) {
                    return;
                }

                eventToUpdate.duration = action.payload.duration;
            },
            prepare: prepareAutoBatched<{ event: TableEvent; duration: number }>(),
        },
        addEvent: {
            reducer: (state: EventState, action: PayloadAction<EventDto>) => {
                state.events = state.events.filter((e) => e.id !== action.payload.id);

                state.events.push({
                    ...action.payload,
                    isChecked: false,
                    isAcknowledged: !!action.payload.acknowledgedTime,
                });
            },
            prepare: prepareAutoBatched<EventDto>(),
        },
        resolveEvent: {
            reducer: (state: EventState, action: PayloadAction<EventDto>) => {
                if (!state.events.some((e) => e.id === action.payload.id)) {
                    return;
                }

                state.events = state.events.filter((e) => e.id !== action.payload.id);
            },
            prepare: prepareAutoBatched<EventDto>(),
        },
        acknowledgeStoreEvent: (state: EventState, action: PayloadAction<EventDto>) => {
            const event = state.events.find((e) => e.id === action.payload.id);
            if (!event) {
                return;
            }

            event.isAcknowledged = true;
            event.acknowledgedTime = action.payload.acknowledgedTime;
        },
        unacknowledgeStoreEvent: (state: EventState, action: PayloadAction<EventDto>) => {
            const event = state.events.find((e) => e.id === action.payload.id);
            if (!event) {
                return;
            }

            event.isAcknowledged = false;
            event.acknowledgedTime = null;
        },
        setLastComment: (
            state: EventState,
            action: PayloadAction<{ events: EventDto[]; comment: string }>,
        ) => {
            const eventIds = action.payload.events.map((e) => e.id);
            const eventsToUpdate = state.events.filter((e) => eventIds.includes(e.id));
            eventsToUpdate.forEach((e) => (e.lastComment = action.payload.comment));
        },
        addComment: (state: EventState, action: PayloadAction<EventCommentDto>) => {
            const eventToUpdate = state.events.find((e) => e.id === action.payload.eventId);
            if (!eventToUpdate) {
                return;
            }
            if (eventToUpdate.eventComments === undefined) {
                eventToUpdate.eventComments = [];
            }
            eventToUpdate.eventComments.push(action.payload);
            eventToUpdate.lastComment = action.payload.comment;
        },
    },
});

export const {
    setCheckedForId,
    checkAll,
    uncheckAll,
    setEvents,
    setDurationForEvent,
    addEvent,
    resolveEvent,
    acknowledgeStoreEvent,
    unacknowledgeStoreEvent,
    setLastComment,
    addComment,
} = eventsSlice.actions;
export const getTableEvents = (state: { events: EventState }): TableEvent[] => state.events.events;
export const getEvent =
    (id: number | undefined) =>
    (state: { events: EventState }): TableEvent | undefined =>
        state.events.events.find((e) => e.id === id);
