import React from 'react';
import { Link, makeStyles, createStyles } from '@material-ui/core';
import { NavLink } from 'react-router-dom';
import { CustomTheme } from '../../../styles/theme';
import AddIcon from '@material-ui/icons/Add';
import { checkUserPermission } from '../../../auth/authChecker';
import { AccessLevels } from '../../../auth/authAccessLevels';

const useStyles = makeStyles((theme: CustomTheme) =>
    createStyles({
        link: {
            margin: theme.spacing(2),
            marginLeft: 0,
            color: theme.palette.text.secondary,
            opacity: '50%',
        },
        activeLink: {
            margin: theme.spacing(2),
            marginLeft: 0,
            opacity: '100%',
        },
        settingsNav: {
            backgroundColor: theme.palette.extraColors.background1,
            listStyleType: 'none',
            '& li': {
                textAlign: 'center',
                float: 'left',
            },
            height: 55,
            padding: '1rem 1.5rem',
        },
        rightLink: {
            color: theme.palette.text.secondary,
            float: 'right',
        },
        addEventText: {
            lineHeight: '24px',
            marginRight: 5,
        },
        addEventIcon: {
            verticalAlign: 'bottom',
        },
    }),
);

interface SettingsNavigationProps {
    addText: string;
    addAction: () => void;
}

export const SettingsNavigation = (props: SettingsNavigationProps) => {
    const { addText, addAction } = props;
    const classes = useStyles();

    return (
        <div className={classes.settingsNav}>
            <li>
                <Link
                    component={NavLink}
                    to="/settings/monitoring"
                    className={classes.link}
                    activeClassName={classes.activeLink}
                >
                    Monitoring Groups
                </Link>
            </li>
            <li>
                <Link
                    component={NavLink}
                    to="/settings/ppa"
                    className={classes.link}
                    activeClassName={classes.activeLink}
                >
                    PPA Conditions
                </Link>
            </li>
            <li>
                <Link
                    component={NavLink}
                    to="/settings/user"
                    className={classes.link}
                    activeClassName={classes.activeLink}
                >
                    User Settings
                </Link>
            </li>
            {checkUserPermission(AccessLevels.Administrator) ? (
                <Link onClick={addAction} className={classes.rightLink} component="button">
                    <span className={classes.addEventText}>{addText}</span>
                    <AddIcon className={classes.addEventIcon} />
                </Link>
            ) : (
                <></>
            )}
        </div>
    );
};
