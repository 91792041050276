import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useCommentOnEventMutation } from '../../../../api/apiSlice';
import { EventCommentDto } from '../../../../api/eventApi';
import { setIsOpen } from '../../../../state/eventPopupSlice';
import { addComment, getEvent } from '../../../../state/eventsSlice';
import { RootState } from '../../../../state/store';
import { EventPopup } from '../eventsPopup';
import { formatDurationForDisplay } from '../eventTableRow';

const EventDetailsPopoutContainer = () => {
    const dispatch = useDispatch();
    const isOpen = useSelector((state: RootState) => state.eventPopout.isOpen);
    const eventId = useSelector((state: RootState) => state.eventPopout.eventId);
    const [commentOnEvent, { isLoading }] = useCommentOnEventMutation();
    const [commentMessage, setCommentMessage] = useState('');

    const event = useSelector(getEvent(eventId));

    useEffect(() => {
        if (!isOpen) {
            setCommentMessage('');
        }
    }, [isOpen]);

    const addCommentCallback = async (
        newComment: string,
        commentList: EventCommentDto[],
        setCommentList: (comments: EventCommentDto[]) => void,
    ) => {
        if (!event) {
            return;
        }
        try {
            const data = await commentOnEvent({ event, comment: newComment }).unwrap();
            dispatch(addComment(data));
            setCommentList([...commentList, data]);
            setCommentMessage('Successfully added comment');
        } catch (err) {
            console.log(err);
            setCommentMessage('Failed to add comment');
        }
    };

    return (
        <>
            {isOpen && event && (
                <EventPopup
                    durationString={formatDurationForDisplay(event.duration ?? 0)}
                    currentData={event}
                    handleClose={() => dispatch(setIsOpen(false))}
                    addCommentLoading={isLoading}
                    commentMessage={commentMessage}
                    addComment={addCommentCallback}
                />
            )}
        </>
    );
};

export { EventDetailsPopoutContainer };
