import React, { useState, useEffect } from 'react';
import {
    Table,
    Link,
    makeStyles,
    createStyles,
    TableRow,
    TableCell,
    TableContainer,
} from '@material-ui/core';
import { SettingsNavigation } from '../settingsNavigation';
import { SortableTableHeader, HeadCell, Order } from '../../../reusable/sortableTableHeader';
import { SortableTableBody } from '../../../reusable/sortableTableBody';
import { SiteDto, getSites } from '../../../../api/siteApi';
import { MonitoringGroupDto, getAllMonitoringGroups } from '../../../../api/monitoringGroupApi';
import { CustomTheme } from '../../../../styles/theme';
import { MonitoringGroupPopup } from './monitoringGroupsPopup';
import {  checkUserPermission } from '../../../../auth/authChecker';
import { AccessLevels } from '../../../../auth/authAccessLevels';

const useStyles = makeStyles((theme: CustomTheme) =>
    createStyles({
        monitoringGroupsRoot: {
            boxShadow: '0px 2px 4px #FFFFFF29',
        },
        monitoringGroupsContent: {
            padding: '1.5rem',
            paddingTop: '0.5rem',
        },
        table: {
            width: 'auto',
        },
        editLink: {
            color: theme.palette.primary.light,
        },
        tableBody: {
            maxHeight: 'calc(100vh - 240px)',
            overflowY: 'auto',
        },
    }),
);

export const MonitoringGroupsPage = () => {
    const classes = useStyles();

    const [order, setOrder] = useState<Order>('asc');
    const [orderBy, setOrderBy] = useState<keyof MonitoringGroupDto>('name');
    const headCells: HeadCell<MonitoringGroupDto>[] = [
        { id: 'name', label: 'Name', isSortable: true, dataField: 'name' },
        { id: 'siteList', label: 'Sites', isSortable: false },
        { id: 'notificationList', label: 'Notification List', isSortable: false },
        { id: 'edit', label: '', isSortable: false },
    ];
    const [monitoringGroups, setMonitoringGroups] = useState<MonitoringGroupDto[]>([]);
    const [isEditing, setIsEditing] = useState(false);
    const [isNewData, setIsNewData] = useState(false);
    const [siteList, setSiteList] = useState<SiteDto[]>([]);
    const [infoForEdit, setInfoForEdit] = useState(
        monitoringGroups.length > 0 ? monitoringGroups[0] : null,
    );

    useEffect(() => {
        const fetchSites = async () => {
            const result = await getSites(undefined, undefined, false);
            if (result.data) {
                result.data.sort(sortSites);
                setSiteList(result.data);
            }
            setIsNewData(true);
        };
        fetchSites();
    }, []);

    const sortSites = (a: SiteDto, b: SiteDto) => {
        if (a.name.toLowerCase() < b.name.toLowerCase()) return -1;
        if (a.name.toLowerCase() > b.name.toLowerCase()) return 1;
        else return 0;
    };

    useEffect(() => {
        if (isNewData) {
            const fetchMonitoringGroups = async () => {
                const result = await getAllMonitoringGroups();
                if (result.data) {
                    setMonitoringGroups(result.data);
                }
                setIsNewData(false);
            };

            fetchMonitoringGroups();
        }
    }, [isNewData]);

    const getSitesForDisplay = (siteKeys: string[]) => {
        var displaySites: SiteDto[] = [];
        var siteKeyCopy = [...siteKeys];
        siteKeyCopy.forEach((key) => {
            var site = siteList.find((e) => e.key === key);
            if (site) {
                displaySites.push(site);
            } else {
                console.log(`Error: site not found from key ${key}`);
            }
        });
        displaySites.sort(sortSites);
        return displaySites;
    };

    const openEditEvent = (rowID: number) => {
        var group = monitoringGroups.find((group) => group.id === rowID);
        if (group) {
            setInfoForEdit(group);
            setIsEditing(true);
        } else {
            console.log('Error: site not found');
        }
    };

    const openNewEvent = () => {
        setInfoForEdit(null);
        setIsEditing(true);
    };

    const handleClose = (newData: boolean) => {
        setIsEditing(false);
        setIsNewData(newData);
    };

    return (
        <>
            <div className={classes.monitoringGroupsRoot}>
                <SettingsNavigation addText={'Add New Monitoring Group'} addAction={openNewEvent} />
                <div className={classes.monitoringGroupsContent}>
                    <TableContainer className={classes.tableBody}>
                        <Table className={classes.table} aria-label="simple table">
                            <SortableTableHeader
                                order={order}
                                orderBy={orderBy}
                                headCells={headCells}
                                setOrder={setOrder}
                                setOrderBy={setOrderBy}
                            />
                            <SortableTableBody
                                order={order}
                                orderBy={orderBy}
                                data={monitoringGroups}
                                renderRow={(row: MonitoringGroupDto) => {
                                    return (
                                        <TableRow hover key={row.id}>
                                            <TableCell padding="none">{row.name}</TableCell>
                                            <TableCell>
                                                {getSitesForDisplay(row.siteKeys).map(
                                                    (site, index) => {
                                                        return (
                                                            <span key={site.key}>
                                                                {`${site.name}`}
                                                                <br />
                                                            </span>
                                                        );
                                                    },
                                                )}
                                            </TableCell>
                                            <TableCell>
                                                {row.notificationEmails
                                                    .split(',')
                                                    .map((email, index) => {
                                                        return (
                                                            <span key={index}>
                                                                {email}
                                                                <br />
                                                            </span>
                                                        );
                                                    })}
                                            </TableCell>
                                            {checkUserPermission(AccessLevels.Administrator) && (
                                                <TableCell>
                                                    <Link
                                                        component="button"
                                                        className={classes.editLink}
                                                        onClick={() => openEditEvent(row.id)}
                                                    >
                                                        Edit
                                                    </Link>
                                                </TableCell>
                                            )}
                                        </TableRow>
                                    );
                                }}
                            />
                        </Table>
                    </TableContainer>
                </div>
            </div>
            {isEditing && checkUserPermission(AccessLevels.Administrator) ? (
                <MonitoringGroupPopup
                    currentData={infoForEdit}
                    handleClose={(newData: boolean) => handleClose(newData)}
                    siteList={siteList}
                />
            ) : null}
        </>
    );
};
