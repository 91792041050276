import {
    makeStyles,
    createStyles,
    FormControl,
    FormGroup,
    FormControlLabel,
    Checkbox,
} from '@material-ui/core';
import React from 'react';
import { CustomTheme } from '../../styles/theme';
import * as linq from 'linq';

const useStyles = makeStyles((theme: CustomTheme) =>
    createStyles({
        optionCheckbox: {
            padding: 0,
            paddingRight: 5,
            color: theme.palette.extraColors.iconColor1,
        },
        optionsGroup: {
            height: 120,
            overflowX: 'hidden',
            overflowY: 'scroll',
            padding: 0,
            paddingLeft: 10,
            flexDirection: 'column',
            flexWrap: 'nowrap',
            '&::-webkit-scrollbar': {
                width: 5,
            },
            '&::-webkit-scrollbar-thumb': {
                backgroundColor: theme.palette.text.secondary,
                height: 5,
            },
        },
    }),
);

interface CheckboxMultiSelectProps<T> {
    options: T[];
    selectedOptions: string[] | number[];
    displayAccessor: (option: T) => string;
    valueAccessor: (option: T) => string | number;
    onOptionSelect: (option: T) => void;
}

export const optionIsSelected = (
    optionKey: string | number,
    selectedOptions: string[] | number[],
) => {
    let found = false;

    if (typeof optionKey === 'string') {
        found = linq.from(selectedOptions as string[]).contains(optionKey);
    } else if (typeof optionKey === 'number') {
        found = linq.from(selectedOptions as number[]).contains(optionKey);
    }

    return found;
};

export function CheckboxMultiSelect<T>({
    options,
    selectedOptions,
    displayAccessor,
    valueAccessor,
    onOptionSelect,
}: CheckboxMultiSelectProps<T>) {
    const classes = useStyles();

    return (
        <FormControl fullWidth={true}>
            <FormGroup className={classes.optionsGroup}>
                {options.map((option) => {
                    return (
                        <FormControlLabel
                            control={
                                <Checkbox
                                    className={classes.optionCheckbox}
                                    checked={optionIsSelected(
                                        valueAccessor(option),
                                        selectedOptions,
                                    )}
                                    onChange={(e) => onOptionSelect(option)}
                                    name={displayAccessor(option)}
                                />
                            }
                            label={displayAccessor(option)}
                            key={valueAccessor(option)}
                        />
                    );
                })}
            </FormGroup>
        </FormControl>
    );
}
