export enum EventType {
    Fault = 'Fault',
    DataOutage = 'DataOutage',
    PpaBreach = 'PpaBreach',
    InService = 'InService',
    CustomAction = 'CustomAction',
    Performance = 'Performance',
}

export const formatTypeForDisplay = (type: EventType) => {
    var typeDisplay = type.toString();
    typeDisplay = typeDisplay.replace(/([A-Z])/g, ' $1').trim() + ' ';
    return typeDisplay;
};
