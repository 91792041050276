import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { AzureAD } from 'react-aad-msal';

import './index.css';

import App from './App';
import { authProvider } from './auth/authProvider';
import { store } from './state/store';

ReactDOM.render(
    <AzureAD provider={authProvider} forceLogin={true}>
        <Provider store={store}>
            <App />
        </Provider>
    </AzureAD>,
    document.getElementById('root'),
);
