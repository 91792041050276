import React from 'react';
import {
    makeStyles,
    createStyles,
    DialogTitle,
    Dialog,
    DialogContent,
    Link,
} from '@material-ui/core';
import { CustomTheme } from '../../../../styles/theme';
import CloseIcon from '@material-ui/icons/Close';

const useStyles = makeStyles((theme: CustomTheme) =>
    createStyles({
        popupHeader: {
            backgroundColor: theme.palette.extraColors.background1,
            padding: '1rem',
            paddingLeft: '1.5rem',
            color: theme.palette.text.secondary,
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
        },
        closeLink: {
            color: theme.palette.text.secondary,
        },
        closeText: {
            lineHeight: '24px',
            fontSize: 15,
            marginRight: 5,
        },
        closeIcon: {
            verticalAlign: 'bottom',
        },
        popupText: {
            color: theme.palette.primary.light,
        },
    }),
);

interface UserSettingPopupProps {
    handleClose: () => void;
}

export const UserSettingPopup = (props: UserSettingPopupProps) => {
    const classes = useStyles();
    const { handleClose } = props;

    return (
        <Dialog open={true} maxWidth={'sm'} fullWidth={true}>
            <DialogTitle>
                <div className={classes.popupHeader}>
                    <span>{'Add User'}</span>
                    <Link onClick={handleClose} className={classes.closeLink} component="button">
                        <span className={classes.closeText}>Close</span>
                        <CloseIcon className={classes.closeIcon} />
                    </Link>
                </div>
            </DialogTitle>
            <DialogContent>
                <div className={classes.popupText}>Please contact SMARTSupport to add a new user</div>
            </DialogContent>
        </Dialog>
    );
};
