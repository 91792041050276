import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
import WarningIcon from '@material-ui/icons/Warning';
import React from 'react';
import { EventCommentDto, EventDto } from '../../../../api/eventApi';
import { CustomTheme } from '../../../../styles/theme';
import { openEmail } from './email-utils';
import { bodyGeneration } from './bodyGeneration';

const useStyles = makeStyles((theme: CustomTheme) =>
    createStyles({
        container: {
            margin: '5px',
            padding: '2px',
            textAlign: 'center',
        },
        loading: {
            width: '100%',
        },
        button: {
            width: '100%',
            backgroundColor: theme.palette.primary.main,
            color: theme.palette.extraColors.opposite,
            borderRadius: 0,
        },
    }),
);

type EscalateEventButtonProps = {
    loading: boolean;
    event: EventDto;
    commentList: EventCommentDto[];
    currentUserName: string;
};

const EscalateEventButton = ({
    loading,
    event,
    commentList,
    currentUserName,
}: EscalateEventButtonProps) => {
    const classes = useStyles();

    if (loading) {
        return (
            <div className={classes.container} data-testid='loading'>
                <CircularProgress className={classes.loading} />
            </div>
        );
    }
    
    const subject = `${event.siteName} - ${(event.assetName ?? 'Site Event')} - ${event.eventType}`; 
    const cc = process.env.REACT_APP_ESCALATE_EVENTS_CC_EMAIL;
    const body = bodyGeneration(event, commentList, currentUserName);

    const onClick = (e: React.MouseEvent<HTMLElement>) => {
        openEmail({ to: '', subject, cc, body });
        e.preventDefault();
    };

    return (
        <div className={classes.container}>
            <Tooltip title='Open an email to escalate this event to the relevant asset manager.'>
                <Button className={classes.button} endIcon={<WarningIcon />} onClick={onClick}>
                    Escalate
                </Button>
            </Tooltip>
        </div>
    );
};

export { EscalateEventButton };
