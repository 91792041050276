import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import { FilterPair } from '../components/reusable/tableFilterPopup';
import { EventDto } from '../api/eventApi';

type EventFiltersState = {
    filters: FilterPair<EventDto>[];
};

const initialState = { filters: [] } as EventFiltersState;

export const eventFiltersSlice = createSlice({
    name: 'eventFilters',
    initialState,
    reducers: {
        setFilters: (state: EventFiltersState, action: PayloadAction<FilterPair<EventDto>[]>) => {
            state.filters = action.payload;
        },
    },
});

export const { setFilters } = eventFiltersSlice.actions;

export const getFilters = (state: { eventFilters: EventFiltersState }): FilterPair<EventDto>[] =>
    state.eventFilters.filters;
