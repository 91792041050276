import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { useCommentOnManyEventsMutation } from '../../../../api/apiSlice';
import { setLastComment } from '../../../../state/eventsSlice';
import { EventNewComment } from '../eventNewComment';
import { TableEvent } from '../eventTable';

type EventSelectionPopoutCommentProps = {
    events: TableEvent[];
    commentsMessage: string;
    setCommentsMessage: (message: string) => void;
};

const EventSelectionPopoutComment = ({
    events,
    commentsMessage,
    setCommentsMessage,
}: EventSelectionPopoutCommentProps) => {
    const [commentOnManyEvents, { isLoading: commentLoading }] = useCommentOnManyEventsMutation();
    const dispatch = useDispatch();

    const addComment = useCallback(
        async (comment: string) => {
            try {
                const comments = await commentOnManyEvents({ events, comment }).unwrap();
                dispatch(setLastComment({ events, comment }));
                setCommentsMessage(`${comments.length} Comments Added`);
            } catch (error) {
                setCommentsMessage('Failed to post comment');
            }
        },
        [commentOnManyEvents, dispatch, events, setCommentsMessage],
    );

    return (
        <EventNewComment
            addComment={addComment}
            loading={commentLoading}
            commentsMessage={commentsMessage}
        />
    );
};

export { EventSelectionPopoutComment };
