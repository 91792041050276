import { NamedEntity } from '../utils/array-utils';
import { BASE_API_URL, HttpMethod, httpRequest, ApiResponse } from './sharedApi';

const MARKETS_URL = `${BASE_API_URL}/markets`;

export type MarketDto = {
    key: string;
} & NamedEntity;

export const getMarkets = (): Promise<ApiResponse<MarketDto[]>> => {
    return httpRequest<MarketDto[]>(MARKETS_URL, HttpMethod.GET);
};
