import React from 'react';
import {
    makeStyles,
    createStyles,
    Typography,
    FormControl,
    MenuItem,
    Select,
} from '@material-ui/core';

import { MarketDto } from '../../api/marketApi';
import { CustomTheme } from '../../styles/theme';
import { ExpandMore } from '@material-ui/icons';
import { MonitoringGroupDto } from '../../api/monitoringGroupApi';
import { selectMenuProp } from '../pages/exportEvents/exportEventsPage';

const useStyles = makeStyles((theme: CustomTheme) =>
    createStyles({
        filterList: {
            display: 'flex',
            alignItems: 'center',
            '& > div': {
                display: 'flex',
                alignItems: 'center',
                margin: theme.spacing(1),
            },
            color: theme.palette.text.secondary,
            marginLeft: 30,
        },
        filterDropdown: {
            backgroundColor: theme.palette.extraColors.background2,
            border: 0,
        },
        expandIcons: {
            color: theme.palette.extraColors.iconColor2,
            position: 'absolute',
            right: 0,
            top: 'calc(50% - 10px)',
            pointerEvents: 'none',
        },
        dropdownText: {
            paddingTop: 3,
            fontWeight: 'bold',
        },
        siteCount: {
            color: theme.palette.extraColors.text6,
        },
    }),
);

export type FilterListProps = {
    selectedMarketKey: string;
    setSelectedMarketKey: (key: string) => void;
    markets: MarketDto[];
    selectedMonitoringGroupId: number;
    setMonitoringGroupId: (id: number) => void;
    monitoringGroups: MonitoringGroupDto[];
    siteCount: number;
};

const FilterList = (props: FilterListProps) => {
    const classes = useStyles();
    const {
        selectedMarketKey,
        setSelectedMarketKey,
        markets,
        selectedMonitoringGroupId,
        setMonitoringGroupId,
        monitoringGroups,
        siteCount,
    } = props;

    return (
        <div className={classes.filterList}>
            <div>
                <Typography variant="body2">Market:</Typography>
                <FormControl className={classes.filterDropdown}>
                    <Select
                        value={selectedMarketKey}
                        onChange={(event) => setSelectedMarketKey(event.target.value as string)}
                        IconComponent={() => <ExpandMore className={classes.expandIcons} />}
                        className={classes.dropdownText}
                        MenuProps={selectMenuProp}
                    >
                        {markets?.map((m) => (
                            <MenuItem key={m.key} value={m.key}>
                                {m.name}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
            </div>
            <div>
                <Typography variant="body2">Monitoring Group:</Typography>
                <FormControl className={classes.filterDropdown}>
                    <Select
                        value={selectedMonitoringGroupId}
                        onChange={(event) => setMonitoringGroupId(event.target.value as number)}
                        IconComponent={() => <ExpandMore className={classes.expandIcons} />}
                        className={classes.dropdownText}
                        MenuProps={selectMenuProp}
                    >
                        <MenuItem value={0}>All</MenuItem>
                        {monitoringGroups?.map((m) => (
                            <MenuItem key={m.id} value={m.id}>
                                {m.name}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
            </div>
            <div className={classes.siteCount}>|</div>
            <div className={classes.siteCount}>
                <span>{siteCount} sites</span>
            </div>
        </div>
    );
};

export { FilterList };
