import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import { RecurringEventDto } from '../api/recurringEventApi';

type ScheduledEventsState = {
    recurringEvents: RecurringEventDto[];
};

const initialState = { recurringEvents: [] } as ScheduledEventsState;

export const scheduledEventsSlice = createSlice({
    name: 'scheduledEvents',
    initialState,
    reducers: {
        setRecurringEvents: (
            state: ScheduledEventsState,
            action: PayloadAction<RecurringEventDto[]>,
        ) => {
            state.recurringEvents = action.payload;
        },
        createOrUpdateRecurringEvent: (
            state: ScheduledEventsState,
            action: PayloadAction<RecurringEventDto>,
        ) => {
            state.recurringEvents = state.recurringEvents.filter((r) => r.id !== action.payload.id);
            state.recurringEvents.push(action.payload);
        },
        deleteRecurringEvent: (state: ScheduledEventsState, action: PayloadAction<number>) => {
            state.recurringEvents = state.recurringEvents.filter((r) => r.id !== action.payload);
        },
    },
});

export const { setRecurringEvents, createOrUpdateRecurringEvent, deleteRecurringEvent } =
    scheduledEventsSlice.actions;

export const getRecurringEventsSelector = (state: {
    scheduledEvents: ScheduledEventsState;
}): RecurringEventDto[] => state.scheduledEvents.recurringEvents;
