export enum FilterOperator {
    EqualTo = 'EqualTo',
    Contains = 'Contains',
}

export const formatOperatorForDisplay = (operator: FilterOperator) => {
    var operatorDisplay = operator.toString();
    operatorDisplay = operatorDisplay.replace(/([A-Z])/g, ' $1').trim() + ' ';
    return operatorDisplay;
};
