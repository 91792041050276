import React, { useEffect, useState } from 'react';
import { TableBody } from '@material-ui/core';
import { Order } from './sortableTableHeader';
import { FilterOperator } from '../../enums/filterOperator';
import { FilterPair } from './tableFilterPopup';

function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

function getComparator<T>(
    order: Order,
    orderBy: keyof T,
    customSort?: customSortFunction<T>[],
): (a: T, b: T) => number {
    if (customSort) {
        var sort = customSort.find((sort) => sort.field === orderBy);
        if (sort?.function) {
            var compareFunc = sort.function;
            return order === 'desc' ? (a, b) => compareFunc(a, b) : (a, b) => -compareFunc(a, b);
        }
    }
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort<T>(array: T[], comparator: (a: T, b: T) => number) {
    const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
}

interface customSortFunction<T> {
    field: keyof T;
    function: (a: T, b: T) => number;
}

interface SortableTableBodyProps<T> {
    order: Order;
    orderBy: keyof T;
    data: T[];
    renderRow: (rowData: T) => JSX.Element;
    customSort?: customSortFunction<T>[];
    filters?: FilterPair<T>[];
}

export function SortableTableBody<T>(props: SortableTableBodyProps<T>) {
    const { order, orderBy, data, renderRow, customSort, filters } = props;

    const [filteredData, setFilteredData] = useState<T[]>([]);

    useEffect(() => {
        const toLowerCaseString = (input: any) => {
            return ('' + input).toLowerCase();
        };
        if (data.length > 0 && filters) {
            var tempData = [...data];
            filters.forEach((filter) => {
                switch (filter.operator) {
                    case FilterOperator.EqualTo:
                        tempData = tempData.filter(
                            (dataPoint) =>
                                //eslint-disable-next-line
                                toLowerCaseString(dataPoint[filter.field]) ==
                                toLowerCaseString(filter.value),
                        );
                        break;
                    case FilterOperator.Contains:
                        tempData = tempData.filter(
                            (dataPoint) =>
                                toLowerCaseString(dataPoint[filter.field]).indexOf(
                                    toLowerCaseString(filter.value),
                                ) >= 0,
                        );
                        break;
                }
            });
            setFilteredData(tempData);
        } else {
            setFilteredData(data);
        }
    }, [filters, data]);

    return (
        <TableBody>
            {stableSort(filteredData, getComparator(order, orderBy, customSort)).map((row) => {
                return renderRow(row);
            })}
        </TableBody>
    );
}
