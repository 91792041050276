import { createStyles, makeStyles, Popover } from '@material-ui/core';
import React from 'react';
import { CustomTheme } from '../../../styles/theme';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faThumbtack } from '@fortawesome/free-solid-svg-icons';
import CheckIcon from '@material-ui/icons/Check';
import { useRowStyles } from './liveStatusRow';

const useStyles = makeStyles((theme: CustomTheme) =>
    createStyles({
        legendRoot: {
            fontSize: 12,
            border: '1px solid #FFFFFF29',
            lineHeight: 1.1,
        },
        legendRow: {
            display: 'flex',
            flexDirection: 'row',
            width: 540,
            padding: 20,
            borderBottom: '1px solid ' + theme.palette.extraColors.border3,
        },
        alignCenter: {
            alignItems: 'center',
        },
        statusIcon: {
            marginRight: 5,
            width: 70,
        },
        assetCircle: {
            borderRadius: '50%',
            width: 26,
            height: 26,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            fontSize: 11,
            marginRight: 8,
            marginBottom: 5,
        },
        statusIconText: {
            marginLeft: 25,
            width: 250,
        },
        pinLegend: {
            marginLeft: 15,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
        },
        pinText: {
            marginLeft: 45,
            width: 330,
        },
        gridNameBox: {
            marginRight: 15,
        },
        gridText: {
            marginLeft: 15,
            width: 230,
        },
        bottomRow: {
            borderBottom: 'none !important',
        },
        warningHighlight: {
            backgroundColor: theme.palette.extraColors.background5,
        },
        warningText: {
            padding: '10px 20px',
            color: theme.palette.extraColors.text2,
            fontSize: 11,
        },
        windSpeedText: {
            backgroundColor: theme.palette.extraColors.background4,
            color: theme.palette.extraColors.text4, 
            fontSize: 12,
            padding: '1px 4px',
        },
        highWindSpeedText: {
            backgroundColor: theme.palette.extraColors.button1,
            color: theme.palette.extraColors.text5, 
            fontSize: 12,
            padding: '1px 4px',
        },
        windSpeedBox: {
            marginRight: 5,
        },
    }),
);

interface LiveStatusLegendProps {
    handleClose: () => void;
}

export const LiveStatusLegend = (props: LiveStatusLegendProps) => {
    const classes = useStyles();
    const rowStyles = useRowStyles();
    const { handleClose } = props;
    return (
        <Popover
            anchorReference="anchorPosition"
            anchorPosition={{ top: 190, left: window.innerWidth - 50 }}
            anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
            }}
            transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
            }}
            open={true}
            onClose={handleClose}
        >
            <div className={classes.legendRoot}>
                <div className={classes.legendRow}>
                    <div className={classes.statusIcon}>
                        <div className={classes.assetCircle + ' ' + rowStyles.statusAvailable}>
                            <span>
                                <CheckIcon className={rowStyles.checkmark} />
                            </span>
                        </div>
                        Asset available
                    </div>
                    <div className={classes.statusIcon}>
                        <div className={classes.assetCircle + ' ' + rowStyles.statusUnavailable}>
                            <span>T01</span>
                        </div>
                        Asset unavailable
                    </div>
                    <div className={classes.statusIcon}>
                        <div className={classes.assetCircle + ' ' + rowStyles.statusService}>
                            <span>T01</span>
                        </div>
                        Asset in service
                    </div>
                    <div className={classes.statusIcon}>
                        <div className={classes.assetCircle + ' ' + rowStyles.statusNoData}>
                            <span>T01</span>
                        </div>
                        No data
                    </div>
                </div>
                <div className={classes.legendRow + ' ' + classes.alignCenter}>
                    <div
                        className={`${classes.assetCircle} ${rowStyles.statusUnavailable} ${rowStyles.flicker}`}
                    >
                        <span>T01</span>
                    </div>
                    <div
                        className={`${classes.assetCircle} ${rowStyles.statusService} ${rowStyles.flicker}`}
                    >
                        <span>T01</span>
                    </div>

                    <div
                        className={`${classes.assetCircle} ${rowStyles.statusNoData} ${rowStyles.flicker}`}
                    >
                        <span>T01</span>
                    </div>
                    <div className={classes.statusIconText}>
                        A flashing asset status icon indicates that there are assets with
                        unacknowledged problems on site.
                    </div>
                </div>
                <div className={classes.legendRow + ' ' + classes.alignCenter}>
                    <div className={classes.pinLegend}>
                        <div>
                            <FontAwesomeIcon icon={faThumbtack} className={rowStyles.unpinned} />
                        </div>
                        <div>unpinned</div>
                    </div>
                    <div className={classes.pinLegend}>
                        <div>
                            <FontAwesomeIcon icon={faThumbtack} className={rowStyles.pinned} />
                        </div>
                        <div>pinned</div>
                    </div>
                    <div className={classes.pinText}>
                        Pinned sites will stay at the top of the asset status view. Unpinned sites
                        will be put into pages to fill the rest of the screen. These pages will
                        automatically cycle based on the time set in the selector on the right side
                        of the page. The time selector will accept values ranging from 1 to 60 or
                        you can leave the selector empty and the page will not cycle.
                    </div>
                </div>
                <div className={classes.legendRow + ' ' + classes.alignCenter}>
                    <div className={classes.gridNameBox}>
                        <span className={rowStyles.gridNameText}>connection one</span>
                    </div>
                    <div className={classes.gridNameBox}>
                        <span className={rowStyles.gridNameText}>connection two</span>
                    </div>
                    <div className={classes.gridText}>
                        Sites with multiple grid connections will have their assets separated into
                        groups which will be labeled with the grid connection name.
                    </div>
                </div>
                <div className={classes.legendRow}>
                    <div className={classes.warningHighlight}>
                        <div className={classes.warningText}>
                            A site highlighted in this colour has breached a PPA condition.
                        </div>
                    </div>
                </div>
                <div
                    className={
                        classes.legendRow + ' ' + classes.bottomRow + ' ' + classes.alignCenter
                    }
                >
                    <div className={classes.windSpeedBox}>
                        <span className={classes.windSpeedText}>wind speed</span>
                    </div>
                    <div className={classes.windSpeedBox + ' ' + rowStyles.flicker}>
                        <span className={classes.highWindSpeedText}>
                            {'wind speed too high for O&M (>12m/s)'}
                        </span>
                    </div>
                </div>
            </div>
        </Popover>
    );
};
