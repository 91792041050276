import React from 'react';
import {
    TableHead,
    TableRow,
    makeStyles,
    createStyles,
    TableSortLabel,
    TableCell,
    Link,
    Tooltip,
} from '@material-ui/core';
import { CustomTheme } from '../../styles/theme';
import FilterListIcon from '@material-ui/icons/FilterList';
import { FilterPair } from './tableFilterPopup';
import InfoIcon from '@material-ui/icons/Info';

export type Order = 'asc' | 'desc';

export interface HeadCell<T> {
    id: string;
    label: string;
    isSortable: boolean;
    dataField?: keyof T;
    isVisible?: boolean;
    isFilterable?: boolean;
    legend?: string;
}

export const useStyles = makeStyles((theme: CustomTheme) =>
    createStyles({
        visuallyHidden: {
            border: 0,
            clip: 'rect(0 0 0 0)',
            height: 1,
            margin: -1,
            overflow: 'hidden',
            padding: 0,
            position: 'absolute',
            top: 20,
            width: 1,
        },
        displayNone: {
            display: 'none',
        },
        filterIcon: {
            color: theme.palette.primary.light,
            marginRight: 5,
        },
        filteredColumn: {
            color: theme.palette.primary.light,
        },
        dummyButton: {
            cursor: 'auto',
        },
        legendIcon: {
            fontSize: 18,
            marginTop: 1,
            marginLeft: 5,
        },
    }),
);

interface SortableTableHeaderProps<T> {
    order: Order;
    orderBy: keyof T;
    headCells: HeadCell<T>[];
    setOrder: React.Dispatch<React.SetStateAction<Order>>;
    setOrderBy: React.Dispatch<React.SetStateAction<keyof T>>;
    filters?: FilterPair<T>[];
    openFilterPopup?: () => void;
}

export function SortableTableHeader<T>(props: SortableTableHeaderProps<T>) {
    const classes = useStyles();
    const { order, orderBy, headCells, setOrder, setOrderBy, filters, openFilterPopup } = props;
    const createSortHandler = (property: keyof T) => (event: React.MouseEvent<unknown>) => {
        const isAsc = props.orderBy === property && props.order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    return (
        <TableHead>
            <TableRow>
                {openFilterPopup ? (
                    <TableCell padding="none">
                        <Link onClick={openFilterPopup} component="button">
                            <FilterListIcon className={classes.filterIcon} />
                        </Link>
                    </TableCell>
                ) : null}
                {headCells.map((headCell) => (
                    <TableCell
                        key={headCells.indexOf(headCell)}
                        align={'left'}
                        padding={headCells.indexOf(headCell) === 0 ? 'none' : 'default'}
                        sortDirection={orderBy === headCell.id ? order : false}
                        className={
                            (headCell.isVisible === false ? classes.displayNone : '') +
                            (filters?.find((f) => f.field === headCell.id) !== undefined
                                ? classes.filteredColumn
                                : '')
                        }
                    >
                        {headCell.isSortable === true && headCell.dataField !== undefined ? (
                            <TableSortLabel
                                active={orderBy === headCell.id}
                                direction={orderBy === headCell.id ? order : 'asc'}
                                onClick={createSortHandler(headCell.dataField)}
                            >
                                {headCell.label}
                                {headCell.legend ? (
                                <Tooltip title={headCell.legend}>
                                    <InfoIcon className={classes.legendIcon} />
                                </Tooltip>
                                ) : null}
                            </TableSortLabel>
                        ) : (
                            <>
                                <TableSortLabel hideSortIcon={true} className={classes.dummyButton}>
                                    {headCell.label}
                                </TableSortLabel>
                            </>
                        )}
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
}
