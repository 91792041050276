import { EventType } from '../enums/eventType';
import { RecurrenceType } from '../enums/recurrenceType';
import { BASE_API_URL, HttpMethod, httpRequest, ApiResponse } from './sharedApi';

const RECURRING_EVENTS_URL = `${BASE_API_URL}/recurringEvents`;

export interface RecurringEventDto {
    id: number;
    startTime: Date;
    endTime: Date;
    eventType: EventType;
    code: string;
    message: string;

    technology: string;
    marketKey: string;
    marketName: string;

    siteKey: string;
    siteName: string;
    assetKey: string;
    assetName: string;

    recurrence: RecurrenceType;
    dailyRecurrence: boolean | null;
    weeklyRecurrence: number | null;
    monthlyRecurrence: number | null;
}

export const getRecurringEvents = (
    marketKey?: string,
    monitoringGroupId?: number,
): Promise<ApiResponse<RecurringEventDto[]>> => {
    var queryStrings = [];
    if (marketKey && marketKey !== undefined) {
        queryStrings.push(`marketKey=${marketKey}`);
    }
    if (monitoringGroupId && monitoringGroupId !== undefined) {
        queryStrings.push(`monitoringGroupId=${monitoringGroupId}`);
    }
    var extraUrl = queryStrings.length > 0 ? `?${queryStrings.join('&')}` : '';
    return httpRequest<RecurringEventDto[]>(`${RECURRING_EVENTS_URL}${extraUrl}`, HttpMethod.GET);
};

export const createRecurringEvent = (
    recurringEvent: RecurringEventDto,
): Promise<ApiResponse<RecurringEventDto>> => {
    return httpRequest<RecurringEventDto>(
        `${RECURRING_EVENTS_URL}`,
        HttpMethod.POST,
        recurringEvent,
    );
};

export const deleteRecurringEvent = (recurringEventId: number): Promise<ApiResponse<void>> => {
    return httpRequest<void>(`${RECURRING_EVENTS_URL}/${recurringEventId}`, HttpMethod.DELETE);
};
