import { createStyles, Link, makeStyles } from '@material-ui/core';
import React from 'react';
import AddIcon from '@material-ui/icons/Add';
import { CustomTheme } from '../../../styles/theme';

const useStyles = makeStyles((theme: CustomTheme) =>
    createStyles({
        container: {
            backgroundColor: theme.palette.extraColors.background1,
            listStyleType: 'none',
            '& li': {
                textAlign: 'center',
                float: 'left',
                marginRight: theme.spacing(4),
            },
            height: 55,
            padding: '1rem 1.5rem',
        },
        rightLink: {
            color: theme.palette.text.secondary,
            float: 'right',
        },
        addItemText: {
            lineHeight: '24px',
            marginRight: 5,
        },
        addItemIcon: {
            verticalAlign: 'bottom',
        },
        link: {
            margin: theme.spacing(2),
            marginLeft: 0,
            color: theme.palette.text.secondary,
        },
    }),
);

type TableTopBarProps = {
    userIsEditor: boolean;
    onButtonClick: () => void;
    titleText: string;
    buttonText: string;
};

const TableTopBar = ({ userIsEditor, onButtonClick, titleText, buttonText }: TableTopBarProps) => {
    const classes = useStyles();
    return (
        <div className={classes.container}>
            <span className={classes.link}>{titleText}</span>
            {userIsEditor && (
                <Link onClick={onButtonClick} className={classes.rightLink} component="button">
                    <span className={classes.addItemText}>{buttonText}</span>
                    <AddIcon className={classes.addItemIcon} />
                </Link>
            )}
        </div>
    );
};

export { TableTopBar };
