import { EventCommentDto } from "../../../../api/eventApi";

const regexTemplate = new RegExp('Event Description :(.*)\nEvent code :(.*)\nSummary of action taken :(.*)');
const expressionToRemove = /\nAre faults resettable on this asset\? :(.*)\nWas a reset attempted\? :(.*)\nWas the reset successful\? :(.*)\nHow many attempts\? :(.*)/;

const checkComment = (comment: string) => {
    return regexTemplate.test(comment);
};

const addNonetoEmptyEntries = (comment: string) => {
    return comment.replace(/:\s*$/gm, ": None");
};

const formatComment = (comment: string) => {
    return comment.replace(expressionToRemove, '');
};

const commentSectionGeneration = (commentList: EventCommentDto[]) => {
    if(commentList.length == 0)
    {
        return 'No comment has been posted on COMET\n';
    }
    var resultCommentSection = '';
    commentList.forEach(comment => {
        resultCommentSection += comment.authorName + ' at ' + startTimeStringFormat(comment.timestamp) + '\n';
        resultCommentSection += formatComment(checkComment(comment.comment) ? addNonetoEmptyEntries(comment.comment) : comment.comment) + '\n';
    });
    return resultCommentSection;
};

const startTimeStringFormat = (startTime: Date) => {
    return (new Date(startTime)).toUTCString().replace('GMT','UTC');
}

export { commentSectionGeneration };
export { startTimeStringFormat };