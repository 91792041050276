import React from 'react';
import { makeStyles, createStyles } from '@material-ui/core';
import { EventCommentDto } from '../../../api/eventApi';
import { CustomTheme } from '../../../styles/theme';
import { formatTime } from '../../reusable/formatTime';

const useStyles = makeStyles((theme: CustomTheme) =>
    createStyles({
        commentListArea: {
            backgroundColor: theme.palette.extraColors.background3,
            border: `1px solid ${theme.palette.extraColors.border2}`,
            height: '100%',
            padding: '5px 10px',
            overflowX: 'hidden',
            overflowY: 'auto',
        },
        singleCommentArea: {
            marginBottom: 10,
        },
        commentText: {
            fontSize: 16,
            lineHeight: 1.1,
            whiteSpace: 'pre-wrap',
        },
        commentDetails: {
            fontSize: 13,
            color: theme.palette.primary.main,
        },
    }),
);

type Props = {
    comments: EventCommentDto[];
};

const EventCommentList = ({ comments }: Props) => {
    const classes = useStyles();

    return (
        <div className={classes.commentListArea} data-testid="eventCommentListContainer">
            {comments
                .sort(function (a, b) {
                    if (a.id < b.id) {
                        return 1;
                    } else if (a.id > b.id) {
                        return -1;
                    }
                    return 0;
                })
                .map((comment, index) => {
                    return (
                        <div key={index} className={classes.singleCommentArea}>
                            <div className={classes.commentText}>{comment.comment}</div>
                            <div className={classes.commentDetails}>
                                {`${comment.authorName} at ${formatTime(comment.timestamp)}`}
                            </div>
                        </div>
                    );
                })}
        </div>
    );
};

export { EventCommentList };
