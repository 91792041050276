import { Table, TableCell, TableContainer, TableRow } from '@material-ui/core';
import React, { useEffect, useMemo, useState } from 'react';
import { MarketDto } from '../../../api/marketApi';
import {
    deleteRecurringEvent,
    getRecurringEvents,
    RecurringEventDto,
} from '../../../api/recurringEventApi';
import { SiteDto } from '../../../api/siteApi';
import { formatTime } from '../../reusable/formatTime';
import { SortableTableBody } from '../../reusable/sortableTableBody';
import { HeadCell, Order, SortableTableHeader } from '../../reusable/sortableTableHeader';
import { useScheduledItemPageStyles } from '../../../styles/scheduledItemPageStyle';
import { TableTopBar } from './tableTopBar';
import { CreateRecurringEventPopup } from './createRecurringEventPopup';
import { UpdateRecurringEventPopup } from './updateRecurringEventPopup';
import { ScheduledEventButtonActions } from './scheduledEventButtonActions';
import { useDispatch, useSelector } from 'react-redux';
import {
    deleteRecurringEvent as deleteRecurringEventLocal,
    getRecurringEventsSelector,
    setRecurringEvents,
} from '../../../state/scheduledEventsSlice';

type RecurringEventsTableProps = {
    selectedMarketKey: string;
    selectedMonitoringGroupId: number;
    userIsEditor: boolean;
    sites: SiteDto[];
    technologies: string[];
    markets: MarketDto[];
};

const RecurringEventsTable = ({
    selectedMarketKey,
    selectedMonitoringGroupId,
    userIsEditor,
    sites,
    technologies,
    markets,
}: RecurringEventsTableProps) => {
    const classes = useScheduledItemPageStyles();
    const dispatch = useDispatch();

    const recurringEvents = useSelector(getRecurringEventsSelector);

    const [order, setOrder] = useState<Order>('asc');
    const [recurringEventOrderBy, setRecurringEventOrderBy] =
        useState<keyof RecurringEventDto>('id');
    const recurringEventHeadCells: HeadCell<RecurringEventDto>[] = useMemo(
        () => [
            { id: 'id', label: 'Event Id', isSortable: true, dataField: 'id' },
            { id: 'technology', label: 'Tech', isSortable: true, dataField: 'technology' },
            { id: 'code', label: 'Code', isSortable: true, dataField: 'code' },
            { id: 'siteName', label: 'Site', isSortable: true, dataField: 'siteName' },
            { id: 'assetName', label: 'Asset', isSortable: true, dataField: 'assetName' },
            { id: 'startTime', label: 'Start Time', isSortable: true, dataField: 'startTime' },
            { id: 'endTime', label: 'End Time', isSortable: true, dataField: 'endTime' },
            { id: 'recurrence', label: 'Recurrence', isSortable: true, dataField: 'recurrence' },
            {
                id: 'weeklyRecurrence',
                label: 'Days',
                isSortable: true,
                dataField: 'weeklyRecurrence',
            },
            {
                id: 'monthlyRecurrence',
                label: 'Day Number',
                isSortable: true,
                dataField: 'monthlyRecurrence',
                legend: 'Set to last day of the month if Day Number exceeds the number of days in current month',
            },
            { id: 'message', label: 'Message', isSortable: true, dataField: 'message' },
            { id: 'deleteButton', label: '', isSortable: false, dataField: undefined },
        ],
        [],
    );

    const [isNewData, setIsNewData] = useState(true);
    const [eventBeingEdited, setEventBeingEdited] = useState<RecurringEventDto | undefined>(
        undefined,
    );
    const handleDeleteRecurringEvent = async (recurringEventId: number) => {
        if (window.confirm(`Delete Recurring Event with ID ${recurringEventId} ?`)) { 
            const { isSuccess } = await deleteRecurringEvent(recurringEventId);
            if (isSuccess) {
                dispatch(deleteRecurringEventLocal(recurringEventId));
            }
            setIsNewData(true);
        }
    };
    const [isScheduling, setIsScheduling] = useState(false);

    const handleRecurringEventClose = (newData: boolean) => {
        setIsScheduling(false);
        setIsNewData(newData);
    };

    useEffect(() => {
        const fetchScheduledAndRecurringEvents = async () => {
            var marketKey: string | undefined;
            var groupId: number | undefined;
            if (selectedMarketKey !== 'global') {
                marketKey = selectedMarketKey;
            }
            if (selectedMonitoringGroupId !== 0) {
                groupId = selectedMonitoringGroupId;
            }

            // Get recurring events based on market key, group id
            const recurringEventResult = await getRecurringEvents(marketKey, groupId);
            if (recurringEventResult.data) {
                dispatch(setRecurringEvents(recurringEventResult.data));
            }
            setIsNewData(false);
        };

        fetchScheduledAndRecurringEvents();
    }, [dispatch, isNewData, selectedMarketKey, selectedMonitoringGroupId]);

    return (
        <div className={classes.tableWrapper}>
            <div className={classes.scheduledItemsRoot}>
                <TableTopBar
                    onButtonClick={() => setIsScheduling(true)}
                    titleText="Scheduled Re-Occurring Events"
                    buttonText="Add New Re-Occurring Event"
                    userIsEditor={userIsEditor}
                />
                <div className={classes.scheduledItemsContent}>
                    <TableContainer className={classes.tableBody}>
                        <Table className={classes.table} aria-label="simple table" stickyHeader>
                            <SortableTableHeader
                                order={order}
                                orderBy={recurringEventOrderBy}
                                headCells={recurringEventHeadCells}
                                setOrder={setOrder}
                                setOrderBy={setRecurringEventOrderBy}
                            />
                            <SortableTableBody
                                order={order}
                                orderBy={recurringEventOrderBy}
                                data={recurringEvents}
                                renderRow={(row: RecurringEventDto) => {
                                    return (
                                        <TableRow hover key={row.id}>
                                            <TableCell padding="none">{row.id}</TableCell>
                                            <TableCell>{row.technology}</TableCell>
                                            <TableCell>{row.code}</TableCell>
                                            <TableCell>{row.siteName}</TableCell>
                                            <TableCell>{row.assetName ?? ''}</TableCell>
                                            <TableCell>{formatTime(row.startTime)}</TableCell>
                                            <TableCell>
                                                {row.endTime ? formatTime(row.endTime) : ''}
                                            </TableCell>
                                            <TableCell>{row.recurrence ?? ''}</TableCell>
                                            <TableCell>{row.weeklyRecurrence ?? ''}</TableCell>
                                            <TableCell>{row.monthlyRecurrence ?? ''}</TableCell>
                                            <TableCell>{row.message ?? ''}</TableCell>
                                            <TableCell>
                                                <ScheduledEventButtonActions
                                                    editEnabled
                                                    editAction={() => setEventBeingEdited(row)}
                                                    deleteAction={() =>
                                                        handleDeleteRecurringEvent(row.id)
                                                    }
                                                />
                                            </TableCell>
                                        </TableRow>
                                    );
                                }}
                            />
                        </Table>
                    </TableContainer>
                </div>
            </div>
            {isScheduling && userIsEditor && (
                <CreateRecurringEventPopup
                    handleClose={(newData: boolean) => handleRecurringEventClose(newData)}
                    siteList={sites}
                    marketList={markets}
                    technologyList={technologies}
                    filterMarketKey={selectedMarketKey}
                />
            )}
            {!!eventBeingEdited && userIsEditor && (
                <UpdateRecurringEventPopup
                    handleClose={(newData: boolean) => setEventBeingEdited(undefined)}
                    siteList={sites}
                    marketList={markets}
                    technologyList={technologies}
                    filterMarketKey={selectedMarketKey}
                    recurringEvent={eventBeingEdited}
                />
            )}
        </div>
    );
};
export { RecurringEventsTable };

