import {
    Button,
    createStyles,
    Dialog,
    DialogContent,
    DialogTitle,
    Link,
    makeStyles,
} from '@material-ui/core';
import React, { useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getTableEvents, setCheckedForId } from '../../../../state/eventsSlice';
import { CustomTheme } from '../../../../styles/theme';
import CloseIcon from '@material-ui/icons/Close';
import { EventSelectionPopoutComment } from './eventSelectionPopoutComment';
import { EventSelectionPopoutStatusChange } from './eventSelectionPopoutStatusChange';

type EventSelectionPopoutProps = {
    viewAcknowledged: boolean;
    userCanEdit: boolean;
};

const useStyles = makeStyles((theme: CustomTheme) =>
    createStyles({
        editEventsButton: {
            backgroundColor: theme.palette.primary.main,
            color: theme.palette.extraColors.opposite,
            fontWeight: 'bold',
            padding: '12px',
            marginRight: theme.spacing(2),
            borderRadius: '2px',
            height: '10px',
        },
        container: {
            display: 'flex',
            alignItems: 'center',
            height: '60%',
        },
        eventLabel: {
            color: theme.palette.extraColors.text6,
            marginRight: theme.spacing(2),
        },
        popupHeader: {
            backgroundColor: theme.palette.extraColors.background1,
            padding: '1rem',
            paddingLeft: '1.5rem',
            color: theme.palette.text.secondary,
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
        },
        closeLink: {
            color: theme.palette.text.secondary,
        },
        closeText: {
            lineHeight: '24px',
            fontSize: 15,
            marginRight: 5,
        },
        closeIcon: {
            verticalAlign: 'bottom',
        },
        labelContent: {
            paddingTop: '0.5rem',
            paddingBottom: '0.5rem',
        },
    }),
);

const EventSelectionPopout = ({ viewAcknowledged, userCanEdit }: EventSelectionPopoutProps) => {
    const classes = useStyles();
    const [isOpen, setIsOpen] = useState(false);
    const [commentsMessage, setCommentsMessage] = useState('');
    const [statusChangeMessage, setStatusChangeMessage] = useState('');
    const dispatch = useDispatch();

    const events = useSelector(getTableEvents);
    const selectedEvents = useMemo(() => events.filter((e) => e.isChecked), [events]);

    const handleClose = () => {
        setIsOpen(false);
        setCommentsMessage('');
        setStatusChangeMessage('');
        selectedEvents.forEach((e) => dispatch(setCheckedForId(e.id)));
    };

    return (
        <div className={classes.container}>
            <div className={classes.eventLabel}>{selectedEvents.length} selected</div>
            <Button
                onClick={() => setIsOpen(true)}
                disabled={selectedEvents.length <= 0}
                className={classes.editEventsButton}
            >
                Edit Events
            </Button>
            <Dialog open={isOpen} maxWidth="xs" fullWidth onClose={handleClose}>
                <DialogTitle>
                    <div className={classes.popupHeader}>
                        <span>Edit Events</span>
                        <Link
                            onClick={handleClose}
                            className={classes.closeLink}
                            component="button"
                        >
                            <span className={classes.closeText}>Close</span>
                            <CloseIcon className={classes.closeIcon} />
                        </Link>
                    </div>
                </DialogTitle>
                <DialogContent>
                    <div>
                        {userCanEdit && (
                            <EventSelectionPopoutStatusChange
                                events={selectedEvents}
                                statusChangeMessage={statusChangeMessage}
                                setStatusChangeMessage={setStatusChangeMessage}
                                viewAcknowledged={viewAcknowledged}
                            />
                        )}
                        <div className={classes.labelContent}>Add Comment To All</div>
                        <EventSelectionPopoutComment
                            events={selectedEvents}
                            commentsMessage={commentsMessage}
                            setCommentsMessage={setCommentsMessage}
                        />
                    </div>
                </DialogContent>
            </Dialog>
        </div>
    );
};

export { EventSelectionPopout };
