export enum ReturnToServiceOption {
    DoNotInclude = 'DoNotInclude',
    Include = 'Include',
    OnlyInclude = 'OnlyInclude',
}

export const formatOptionForDisplay = (option: ReturnToServiceOption) => {
    var optionDisplay = option.toString();
    optionDisplay = optionDisplay.replace(/([A-Z])/g, ' $1').trim() + ' ';
    return optionDisplay;
};
