import React, { useState, useEffect } from 'react';
import { Grid, Select, MenuItem, FormControl, TextField } from '@material-ui/core';
import { useScheduledItemPopupStyles } from '../../../styles/scheduledItemPopupStyle';
import ExpandMore from '@material-ui/icons/ExpandMore';
import { PopupContainer } from '../../reusable/popupContainer';
import { SiteDto } from '../../../api/siteApi';
import { MarketDto } from '../../../api/marketApi';
import DateFnsUtils from '@date-io/date-fns';
import { KeyboardDateTimePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import { convertFromUTC, convertToUTC, typedFormat } from '../../reusable/formatTime';
import { selectMenuProp } from '../exportEvents/exportEventsPage';
import { durationInDays } from '../../../helpers/dateAndTime';
import { RecurrenceSelector } from './recurrenceSelector';
import { RecurrenceDay } from '../../../enums/recurrenceDay';
import { RecurrenceType } from '../../../enums/recurrenceType';
import { RecurringEventDto } from '../../../api/recurringEventApi';
import { ApiResponse } from '../../../api/sharedApi';
import { AssetSelection } from './formComponents/assetSelection';

interface RecurringEventPopupProps {
    handleClose: (newData: boolean) => void;
    siteList: SiteDto[];
    marketList: MarketDto[];
    technologyList: string[];
    initialRecurringEvent: RecurringEventDto;
    submitButtonText: string;
    submitAction: (recurringEvent: RecurringEventDto) => Promise<ApiResponse<RecurringEventDto>>;
}

export const RecurringEventPopup = (props: RecurringEventPopupProps) => {
    const classes = useScheduledItemPopupStyles();
    const {
        handleClose,
        siteList,
        marketList,
        technologyList,
        initialRecurringEvent,
        submitButtonText,
        submitAction,
    } = props;

    const [marketSiteList, setMarketSiteList] = useState<SiteDto[]>([]);
    const [updatedData, setUpdatedData] = useState(initialRecurringEvent);
    const [warningText, setWarningText] = useState<string | undefined>(undefined);

    const [recurrence, setRecurrence] = useState(initialRecurringEvent.recurrence ?? RecurrenceType.Daily);
    const [recurringDay, setRecurringDay] = useState(initialRecurringEvent.monthlyRecurrence ?? 1);
    const [weeklyRecurrence, setWeeklyRecurrence] = useState(RecurrenceDay.None);
    const [selectorError, setSelectorError] = useState(false);

    const [selectedMarketKey, setSelectedMarketKey] = useState(
        marketList.find((m) => m.key === initialRecurringEvent.marketKey)?.key ?? 'global',
    );
    const [selectedTechnology, setSelectedTechnology] = useState(technologyList.find((t) => t === initialRecurringEvent.technology) ?? 'All');

    useEffect(() => {
        const checkCurrentSite = (filteredList: SiteDto[]) => {
            var found = filteredList.find((site) => site.key === updatedData.siteKey);
            if (!found) {
                var siteKey = '';
                var siteName = '';
                if (filteredList.length > 0) {
                    siteKey = filteredList[0].key;
                    siteName = filteredList[0].name;
                }
                if (updatedData.siteKey !== '') {
                    console.log('Error: site no longer matches filters');
                }

                setUpdatedData({
                    ...updatedData,
                    siteKey: siteKey,
                    siteName: siteName,
                    assetName: '',
                    assetKey: '',
                });
            }
        };

        if (siteList.length > 0) {
            var filteredList = siteList.filter(
                (site) =>
                    [site.marketKey, 'global'].includes(selectedMarketKey) &&
                    [site.technology, 'All'].includes(selectedTechnology),
            );
            setMarketSiteList(filteredList);
            checkCurrentSite(filteredList);
        }
    }, [siteList, selectedMarketKey, selectedTechnology, updatedData]);

    const validateInputs = (data: RecurringEventDto) => {
        if (data.siteKey === '') {
            return 'Error: No site selected';
        } else if (new Date(data.startTime) >= new Date(data.endTime)) {
            return 'Error: End time must be after start time';
        } else {
            return '';
        }
    };

    useEffect(() => {
        const checkEventDuration = () => {
            var duration = durationInDays(updatedData.startTime, updatedData.endTime);

            if (duration > 0) {
                setWarningText('Warning: Scheduled event would equal or exceed 1 day in length');
            } else {
                setWarningText(undefined);
            }
        };

        checkEventDuration();
    }, [updatedData.startTime, updatedData.endTime]);

    const updateRecurrence = (recurrenceValue: RecurrenceType) => {
        setRecurrence(recurrenceValue);
        if(recurrenceValue == RecurrenceType.Weekly)
        {
            setWeeklyRecurrence(RecurrenceDay.None);
        }
        setUpdatedData({
            ...updatedData,
            recurrence: recurrenceValue,
            monthlyRecurrence: (recurrenceValue == RecurrenceType.Daily || recurrenceValue == RecurrenceType.Weekly) ? null : recurringDay, 
            weeklyRecurrence: (recurrenceValue == RecurrenceType.Daily || recurrenceValue == RecurrenceType.Monthly) ? null : weeklyRecurrence,
            // update previous recurrence value when switching recurrence types (this allows also the choice of the default value of the Monthly Recurrence)
        });
    };

    const updateWeeklyRecurrence = (day: RecurrenceDay, selected: boolean) => {
        var newWeeklyRecurrence = weeklyRecurrence;
        if (selected) {
            newWeeklyRecurrence += day;
        } else {
            newWeeklyRecurrence -= day;
        }
        setWeeklyRecurrence(newWeeklyRecurrence);
        setUpdatedData({
            ...updatedData,
            weeklyRecurrence: newWeeklyRecurrence,
        });
    };

    const updateRecurringDay = (dayInput: string) => {
        const dayNumber = Number(dayInput);
        if (dayNumber > 31 || dayNumber < 1) {
            setSelectorError(true);
        } else {
            setSelectorError(false);
        }
        setRecurringDay(dayNumber);
        setUpdatedData({
            ...updatedData,
            monthlyRecurrence: dayNumber,
        });
    };

    return (
        <PopupContainer
            titleText={submitButtonText}
            isEditing={false}
            handleClose={handleClose}
            addFunction={submitAction}
            dataCopy={updatedData}
            validateInput={validateInputs}
            warningText={warningText}
            popupSize="sm"
            getContent={() => {
                return (
                    <Grid container className={classes.popupContent} alignItems="center">
                        <Grid item xs={12}>
                            Recurrence Type
                        </Grid>
                        <Grid item xs={12}>
                            <RecurrenceSelector
                                recurrence={recurrence}
                                setRecurrence={updateRecurrence}
                                weeklyRecurrence={weeklyRecurrence}
                                updateWeeklyRecurrence={updateWeeklyRecurrence}
                                recurringDay={recurringDay}
                                updateRecurringDay={updateRecurringDay}
                                selectorError={selectorError}
                            />
                        </Grid>
                        <Grid item xs={5}>
                            Market
                        </Grid>
                        <Grid item xs={7}>
                            <FormControl fullWidth={true}>
                                <Select data-testid="market"
                                    value={selectedMarketKey}
                                    disabled={false}
                                    onChange={(e) => {
                                        var key = e.target.value as string;
                                        var market = marketList.find((e) => e.key === key);
                                        if (market) {
                                            setSelectedMarketKey(market.key);
                                        } else {
                                            console.log('Error: market not found');
                                        }
                                    }}
                                    IconComponent={() => (
                                        <ExpandMore className={classes.expandIcons} />
                                    )}
                                    MenuProps={selectMenuProp}
                                >
                                    <MenuItem key="global" value="global">
                                        Global
                                    </MenuItem>
                                    {marketList.map((market) => {
                                        if (market.key !== 'global') {
                                            return (
                                                <MenuItem key={market.key} value={market.key}>
                                                    {market.name}
                                                </MenuItem>
                                            );
                                        }
                                        return '';
                                    })}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={5}>
                            Technology
                        </Grid>
                        <Grid item xs={7}>
                            <FormControl fullWidth={true}>
                                <Select data-testid="technology"
                                    value={selectedTechnology}
                                    disabled={false}
                                    onChange={(e) => {
                                        var tech = e.target.value as string;
                                        var market = technologyList.find((t) => t === tech);
                                        if (market || tech === 'All') {
                                            setSelectedTechnology(tech);
                                        } else {
                                            console.log('Error: technology not found');
                                        }
                                    }}
                                    IconComponent={() => (
                                        <ExpandMore className={classes.expandIcons} />
                                    )}
                                    MenuProps={selectMenuProp}
                                >
                                    <MenuItem key="All" value="All">
                                        All
                                    </MenuItem>
                                    {technologyList.map((tech, index) => {
                                        return (
                                            <MenuItem key={index} value={tech}>
                                                {tech}
                                            </MenuItem>
                                        );
                                    })}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={5}>
                            Site
                        </Grid>
                        <Grid item xs={7}>
                            <FormControl fullWidth={true}>
                                <Select data-testid="site"
                                    value={updatedData.siteKey}
                                    disabled={false}
                                    onChange={(e) => {
                                        var key = e.target.value as string;
                                        var site = marketSiteList.find((e) => e.key === key);
                                        if (site) {
                                            setUpdatedData({
                                                ...updatedData,
                                                siteName: site.name,
                                                siteKey: key,
                                                assetName: '',
                                                assetKey: '',
                                            });
                                        } else {
                                            console.log('Error: site not found');
                                        }
                                    }}
                                    IconComponent={() => (
                                        <ExpandMore className={classes.expandIcons} />
                                    )}
                                    MenuProps={selectMenuProp}
                                >
                                    {updatedData.siteKey !== '' &&
                                        !marketSiteList.find(
                                            (site) => site.key === updatedData.siteKey,
                                        ) && (
                                            <MenuItem
                                                key={updatedData.siteKey}
                                                value={updatedData.siteKey}
                                            >
                                                {updatedData.siteName}
                                            </MenuItem>
                                        )}
                                    {marketSiteList.map((site) => {
                                        return (
                                            <MenuItem key={site.key} value={site.key}>
                                                {site.name}
                                            </MenuItem>
                                        );
                                    })}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={5}>
                            Asset
                        </Grid>
                        <Grid item xs={7}>
                            <AssetSelection data={updatedData} setData={setUpdatedData} />
                        </Grid>
                        <Grid item xs={5}>
                            Expected Start
                        </Grid>
                        <Grid item xs={5}>
                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                <KeyboardDateTimePicker
                                    ampm={false}
                                    value={convertToUTC(updatedData.startTime)}
                                    onChange={(date) => {
                                        if (date) {
                                            setUpdatedData({
                                                ...updatedData,
                                                startTime: convertFromUTC(date),
                                            });
                                        }
                                    }}
                                    format={typedFormat}
                                />
                            </MuiPickersUtilsProvider>
                        </Grid>
                        <Grid item xs={2}>
                            <span className={classes.timezoneText}>UTC</span>
                        </Grid>
                        <Grid item xs={5}>
                            Expected End
                        </Grid>
                        <Grid item xs={5}>
                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                <KeyboardDateTimePicker
                                    ampm={false}
                                    value={convertToUTC(updatedData.endTime)}
                                    onChange={(date) => {
                                        if (date) {
                                            setUpdatedData({
                                                ...updatedData,
                                                endTime: convertFromUTC(date),
                                            });
                                        }
                                    }}
                                    format={typedFormat}
                                />
                            </MuiPickersUtilsProvider>
                        </Grid>
                        <Grid item xs={2}>
                            <span className={classes.timezoneText}>UTC</span>
                        </Grid>
                        <Grid item xs={12}>
                            Message
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                className={classes.commentSpace}
                                fullWidth={true}
                                multiline={true}
                                rowsMax={Infinity}
                                value={updatedData.message || ''}
                                onChange={(e) => {
                                    setUpdatedData({
                                        ...updatedData,
                                        message: e.target.value,
                                    });
                                }}
                            />
                        </Grid>
                    </Grid>
                );
            }}
        />
    );
};
