import { createStyles, Link, makeStyles, TableCell, TableRow, TextField } from '@material-ui/core';
import React, { useCallback, useEffect, useState } from 'react';
import { CustomTheme } from '../../../styles/theme';
import { AssetDto, GridDto, SiteDto } from '../../../api/siteApi';
import { LiveStatusRow } from './liveStatusRow';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import { EventDto } from '../../../api/eventApi';
import { SiteWindAverageDto } from '../../../api/siteApi';

const useStyles = makeStyles((theme: CustomTheme) =>
    createStyles({
        siteContainer: {
            borderBottom: `2px solid ${theme.palette.extraColors.border3}`,
        },
        siteRow: {
            display: 'flex',
            flexDirection: 'row',
            width: '100%',
            alignItems: 'center',
            justifyContent: 'space-between',
        },
        pageInfo: {
            marginRight: 20,
            // currently #E6E6E6, want to be #EEEEEE, think it's set by darkPalette.text.secondary
        },
        cycleInfo: {
            display: 'flex',
            alignItems: 'center',
        },
        cycleInput: {
            width: 70,
            marginLeft: 5,
        },
        cycleInputText: {
            fontSize: 13,
            paddingTop: 3,
            paddingBottom: 3,
        },
        backIcon: {
            marginRight: 10, 
            color: theme.palette.extraColors.iconColor2
        },
        forwardIcon: {
            marginLeft: 10,
            color: theme.palette.extraColors.iconColor2
        },
    }),
);

interface LiveStatusUnpinnedProps {
    siteList: SiteDto[];
    siteWindData: SiteWindAverageDto[];
    pinSite: (siteKey: string) => void;
    getSiteAssets: (grids: GridDto[]) => AssetDto[][];
    isSitePinned: (siteKey: string) => boolean;
    eventList: EventDto[];
    pageSlots: number;
    setIsNewAnimation: React.Dispatch<React.SetStateAction<boolean>>;
}

export const LiveStatusUnpinned = (props: LiveStatusUnpinnedProps) => {
    const classes = useStyles();
    var {
        siteList,
        siteWindData,
        pinSite,
        getSiteAssets,
        isSitePinned,
        eventList,
        pageSlots,
        setIsNewAnimation,
    } = props;

    var pages = Math.ceil(siteList.filter((s) => isSitePinned(s.key) !== true).length / pageSlots);
    var [currentPage, setCurrentPage] = useState(1);
    var [secondsPerCycle, setSecondsPerCycle] = useState(60);
    var [firstSiteIndex, setFirstSiteIndex] = useState(0);
    var [lastSiteIndex, setLastSiteIndex] = useState(pageSlots);

    useEffect(() => {
        if (currentPage > pages) {
            setCurrentPage(1);
            setFirstSiteIndex(0);
            setLastSiteIndex(pageSlots);
        }
    }, [siteList, currentPage, pages, pageSlots]);

    const verifyPin = (siteKey: string) => {
        if (currentPage === pages) {
            var sitesOnLastPage =
                siteList.filter((s) => isSitePinned(siteKey) !== true).length % pages;
            if (sitesOnLastPage === 1) {
                changePage(false);
            }
        }
        pinSite(siteKey);
    };

    const changePage = useCallback(
        (isPageUp: boolean) => {
            var nextPage = currentPage;
            if (isPageUp) {
                nextPage++;
                if (nextPage > pages) {
                    nextPage = 1;
                }
            } else {
                nextPage--;
                if (nextPage < 1) {
                    nextPage = pages;
                }
            }
            setCurrentPage(nextPage);
            var firstIndex = (nextPage - 1) * pageSlots;
            setFirstSiteIndex(firstIndex);
            setIsNewAnimation(true);
        },
        //eslint-disable-next-line
        [currentPage, pageSlots, pages],
    );

    useEffect(() => {
        setLastSiteIndex(firstSiteIndex + pageSlots);
    }, [pageSlots, firstSiteIndex]);

    useEffect(() => {
        var intervalID = setInterval(() => {
            changePage(true);
        }, 1000 * secondsPerCycle);
        return () => {
            clearInterval(intervalID);
        };
    }, [currentPage, secondsPerCycle, changePage]);

    return (
        <>
            <TableRow className={classes.siteContainer}>
                <TableCell
                    className={classes.siteRow}
                    style={{ padding: 0, paddingLeft: 23, height: 40 }}
                >
                    <div className={classes.pageInfo} style={{ width: 180 }}>
                        {pages > 0 && (
                            <span>
                                <Link onClick={() => changePage(false)} component="button">
                                    <ArrowBackIcon className={classes.backIcon} />
                                </Link>
                            </span>
                        )}
                        <span>
                            Page {currentPage} of {pages}
                        </span>
                        {pages > 0 && (
                            <span>
                                <Link onClick={() => changePage(true)} component="button">
                                    <ArrowForwardIcon className={classes.forwardIcon} />
                                </Link>
                            </span>
                        )}
                    </div>
                    {pages > 1 && (
                        <div className={classes.cycleInfo} style={{ width: 235 }}>
                            <span>Seconds per page cycle</span>
                            <TextField
                                className={classes.cycleInput}
                                type="number"
                                value={secondsPerCycle.toString()}
                                onChange={(e) => {
                                    setSecondsPerCycle(+e.target.value);
                                }}
                                inputProps={{ className: classes.cycleInputText }}
                            />
                        </div>
                    )}
                </TableCell>
            </TableRow>
            {siteList
                .filter((s) => isSitePinned(s.key) !== true)
                .slice(firstSiteIndex, lastSiteIndex)
                .map((site, index) => {
                    var siteAssets = getSiteAssets(site.grids);
                    var windData = siteWindData.find((data) => data.siteKey === site.key);

                    return (
                        <LiveStatusRow
                            site={site}
                            windData={windData}
                            isBottomRow={siteList.length === index + 1}
                            isPinned={false}
                            pinSite={verifyPin}
                            key={index}
                            assets={siteAssets}
                            siteEvents={eventList.filter((e) => e.siteKey === site.key)}
                        />
                    );
                })}
        </>
    );
};
