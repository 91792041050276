import {
    Button,
    createStyles,
    FormControlLabel,
    LinearProgress,
    makeStyles,
    Radio,
    RadioGroup,
} from '@material-ui/core';
import React, { useCallback, useEffect, useState } from 'react';
import { CustomTheme } from '../../../../styles/theme';
import {
    useAcknowledgeManyEventsMutation,
    useUnacknowledgeManyEventsMutation,
} from '../../../../api/apiSlice';
import { TableEvent } from '../eventTable';
import { AcknowledgementButton } from '../acknowledgementButton';
import { acknowledgeStoreEvent, unacknowledgeStoreEvent } from '../../../../state/eventsSlice';
import { useDispatch } from 'react-redux';

type EventSelectionPopoutStatusChangeProps = {
    events: TableEvent[];
    viewAcknowledged: boolean;
    statusChangeMessage: string;
    setStatusChangeMessage: (message: string) => void;
};

const useStyles = makeStyles((theme: CustomTheme) =>
    createStyles({
        eventLabel: {
            color: theme.palette.extraColors.text6,
            marginRight: theme.spacing(2),
        },
        labelContent: {
            paddingTop: '0.5rem',
            paddingBottom: '0.5rem',
        },
        radioContent: {
            paddingBottom: '0.5rem',
        },
        acknowledgedLabel: {
            backgroundColor: theme.palette.extraColors.button2,
            padding: '0px 5px',
            display: 'flex',
            alignItems: 'center',
        },
        unacknowledgedLabel: {
            backgroundColor: theme.palette.extraColors.button1,
            padding: 5,
        },
        checkmark: {
            color: theme.palette.extraColors.iconColor3,
            pointerEvents: 'none',
        },
        messageContent: {
            color: theme.palette.extraColors.iconColor3,
            paddingTop: '0.2rem',
            paddingBottom: '0.5rem',
        },
        apiButton: {
            backgroundColor: theme.palette.primary.main,
            color: theme.palette.extraColors.opposite,
            borderRadius: 0,
            height: '100%',
            width: '30%',
            padding: 1,
        },
        spinner: {
            paddingTop: '3px',
        },
    }),
);

const EventSelectionPopoutStatusChange = ({
    events,
    viewAcknowledged,
}: EventSelectionPopoutStatusChangeProps) => {
    const classes = useStyles();
    const [isAcknowledged, setIsAcknowledged] = useState(!viewAcknowledged);
    const [statusChangeMessage, setStatusChangeMessage] = useState('');
    const dispatch = useDispatch();

    const [acknowledgeManyEvents, { isLoading: acknowledgeLoading }] =
        useAcknowledgeManyEventsMutation();
    const [unacknowledgeManyEvents, { isLoading: unacknowledgeLoading }] =
        useUnacknowledgeManyEventsMutation();

    const loading = acknowledgeLoading || unacknowledgeLoading;

    useEffect(() => {
        setIsAcknowledged(!viewAcknowledged);
    }, [viewAcknowledged]);

    const changeStatus = useCallback(async () => {
        if (isAcknowledged) {
            try {
                const eventsToUpdate = events.filter((e) => !e.isAcknowledged);
                if (eventsToUpdate.length > 0) {
                    const payload = await acknowledgeManyEvents(eventsToUpdate).unwrap();
                    setStatusChangeMessage(`${payload.length} Events Acknowledged`);
                    eventsToUpdate.forEach((e) => dispatch(acknowledgeStoreEvent(e)));
                    return;
                }
                setStatusChangeMessage(`0 Events Acknowledged`);
            } catch (error) {
                setStatusChangeMessage('Error setting status');
            }
        } else {
            try {
                const eventsToUpdate = events.filter((e) => e.isAcknowledged);
                if (eventsToUpdate.length > 0) {
                    const payload = await unacknowledgeManyEvents(eventsToUpdate).unwrap();
                    setStatusChangeMessage(`${payload.length} Events Unacknowledged`);
                    eventsToUpdate.forEach((e) => dispatch(unacknowledgeStoreEvent(e)));

                    return;
                }
                setStatusChangeMessage(`0 Events Unacknowledged`);
            } catch (error) {
                setStatusChangeMessage('Error setting status');
            }
        }
    }, [isAcknowledged, events, acknowledgeManyEvents, dispatch, unacknowledgeManyEvents]);

    return (
        <>
            <div className={classes.labelContent}>Change Status For All</div>
            <RadioGroup className={classes.labelContent}>
                <FormControlLabel
                    className={classes.radioContent}
                    checked={!isAcknowledged}
                    onClick={() => setIsAcknowledged(false)}
                    control={<Radio />}
                    label={<AcknowledgementButton type="unacknowledge" isHovered={false} />}
                />
                <FormControlLabel
                    className={classes.radioContent}
                    checked={isAcknowledged}
                    onClick={() => setIsAcknowledged(true)}
                    control={<Radio />}
                    label={<AcknowledgementButton type="acknowledge" isHovered={false} />}
                />
            </RadioGroup>
            <Button
                className={classes.apiButton}
                onClick={changeStatus}
                disabled={loading || events.length <= 0}
            >
                Change Status
            </Button>

            {loading ? (
                <LinearProgress data-testid="loading" className={classes.spinner} />
            ) : (
                <div className={classes.messageContent}>{statusChangeMessage}</div>
            )}
            <hr />
        </>
    );
};

export { EventSelectionPopoutStatusChange };
