import { SetStateAction } from 'react';
import { LegendPayload } from 'recharts';
import { LiveDataGeneric } from '../../api/liveDataApi';
import { formatTimeForChart } from './formatTime';

interface FilterLiveDataProps {
    dataSet: LiveDataGeneric[];
    dataFieldName: string;
    setData: React.Dispatch<SetStateAction<LiveDataGeneric[]>>;
}

export function FilterLiveData(props: FilterLiveDataProps) {
    const { dataSet, dataFieldName, setData } = props;

    var filteredDataset: LiveDataGeneric[] = [];

    var startTime = dataSet[0].time;
    var endTime = startTime;
    var currentDatapoint = dataSet[0][dataFieldName];

    for (var i = 1; i < dataSet.length; i++) {
        if (dataSet[i][dataFieldName] !== currentDatapoint) {
            //We add the start and the end for chart hovering selection purposes
            //start of new status
            filteredDataset.push({
                time: startTime,
                [currentDatapoint]: 1,
            });
            //end of new status
            if (startTime !== endTime) {
                filteredDataset.push({
                    time: endTime,
                    [currentDatapoint]: 1,
                });
            }

            startTime = dataSet[i].time;
            currentDatapoint = dataSet[i][dataFieldName];
        }
        endTime = dataSet[i].time;
    }
    //start of last status
    filteredDataset.push({
        time: startTime,
        [currentDatapoint]: 1,
    });
    //end of last status
    if (startTime !== endTime) {
        filteredDataset.push({
            time: endTime,
            [currentDatapoint]: 1,
        });
    }

    setData(filteredDataset);
}

export const renderColorfulLegendText = (value?: LegendPayload['value'], entry?: LegendPayload) => {
    if (entry) {
        const { color } = entry;
        return <span style={{ color }}>{value}</span>;
    }
};

export const formatXAxis = (tickItem: any) => {
    var date = new Date(tickItem);
    return formatTimeForChart(date);
};

export const sortLiveData = (a: LiveDataGeneric, b: LiveDataGeneric) => {
    if (a.time < b.time) {
        return -1;
    } else if (a.time > b.time) {
        return 1;
    }
    return 0;
};

interface CalculateTickTimesProps {
    startTime: number;
    endTime: number;
    setTickTimes: React.Dispatch<React.SetStateAction<number[]>>;
}

export const calculateTickTimes = (props: CalculateTickTimesProps) => {
    const { startTime, endTime, setTickTimes } = props;

    const msPerHour = 3600000;
    var hourArr = [];

    var currentHourCap = Math.ceil(startTime / msPerHour);
    var currentHourCapMs = currentHourCap * msPerHour;

    while (currentHourCapMs < endTime) {
        hourArr.push(currentHourCapMs);
        currentHourCapMs += msPerHour;
    }

    setTickTimes(hourArr);
};

export const verifySourceTime = (sourceTime: Date, startTime: Date) => {
    var verifiedTime = new Date(sourceTime).getTime();
    if (verifiedTime < startTime.getTime()) {
        verifiedTime = startTime.getTime();
    }
    return verifiedTime;
};
