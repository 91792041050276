import React from 'react';

import { makeStyles, createStyles } from '@material-ui/core/styles';
import Popover from '@material-ui/core/Popover';
import Typography from '@material-ui/core/Typography';
import { IconButton } from '@material-ui/core';
import UserIcon from '../../images/user-solid.svg';

import { authProvider } from '../../auth/authProvider';
import { CustomTheme } from '../../styles/theme';
import { formatAccessLevelForDisplay, getAccessLevel } from '../../auth/authChecker';

const useStyles = makeStyles((theme: CustomTheme) =>
    createStyles({
        userInfoIcon: {
            height: '19px',
            width: '16px',
        },
        userInfoText: {
            display: 'flex',
            '& p': {
                margin: theme.spacing(1),
            },
        },
    }),
);

export default function UserInfoButton() {
    const classes = useStyles();
    const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const id = open ? 'view user info' : undefined;

    const name = authProvider.getAccount().name;

    const email = authProvider.getAccount().userName;

    const accessLevel = formatAccessLevelForDisplay(getAccessLevel());

    return (
        <div>
            <IconButton aria-describedby={id} onClick={handleClick} disableRipple>
                <img src={UserIcon} className={classes.userInfoIcon} alt="User" />
            </IconButton>
            <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
            >
                <div className={classes.userInfoText}>
                    <Typography>Name:</Typography>
                    <Typography>{name}</Typography>
                </div>
                <div className={classes.userInfoText}>
                    <Typography>Email:</Typography>
                    <Typography>{email}</Typography>
                </div>
                <div className={classes.userInfoText}>
                    <Typography>Permission Level:</Typography>
                    <Typography>{accessLevel}</Typography>
                </div>
            </Popover>
        </div>
    );
}
