import { createStyles, makeStyles } from '@material-ui/core';
import React from 'react';
import { CustomTheme } from '../../../styles/theme';
import CheckIcon from '@material-ui/icons/Check';

type AcknowledgementButtonProps = {
    type: 'acknowledge' | 'unacknowledge';
    isHovered: boolean;
};

const useStyles = makeStyles((theme: CustomTheme) =>
    createStyles({
        eventButton: {
            borderRadius: 20,
            lineHeight: 1,
            height: 24,
            minWidth: 125,
            color: theme.palette.text.secondary,
            pointerEvents: 'none',
        },
        acknowledgedButton: {
            backgroundColor: theme.palette.extraColors.button2,
            padding: '0px 5px',
            display: 'flex',
            alignItems: 'center',
        },
        unacknowledgedButton: {
            backgroundColor: theme.palette.extraColors.button1,
            padding: 5,
        },
        checkmark: {
            color: theme.palette.extraColors.iconColor3,
            pointerEvents: 'none',
        },
    }),
);

const AcknowledgementButton = ({ type, isHovered }: AcknowledgementButtonProps) => {
    const classes = useStyles();

    if (type === 'acknowledge') {
        if (isHovered) {
            return (
                <div className={classes.unacknowledgedButton + ' ' + classes.eventButton}>
                    <span>Unacknowledge</span>
                </div>
            );
        } else {
            return (
                <div className={classes.acknowledgedButton + ' ' + classes.eventButton}>
                    <CheckIcon className={classes.checkmark} />
                    <span>Acknowledged</span>
                </div>
            );
        }
    }

    if (isHovered) {
        return (
            <div className={classes.acknowledgedButton + ' ' + classes.eventButton}>
                <CheckIcon className={classes.checkmark} />
                <span>Acknowledge</span>
            </div>
        );
    }

    return (
        <div className={classes.unacknowledgedButton + ' ' + classes.eventButton}>
            <span>Unacknowledged</span>
        </div>
    );
};

export { AcknowledgementButton };
