import { AccessLevels } from '../auth/authAccessLevels';
import { BASE_API_URL, HttpMethod, httpRequest, ApiResponse } from './sharedApi';

const USER_URL = `${BASE_API_URL}/Users`;

export interface UserSettingDto {
    email: string;
    permissionLevel: AccessLevels;
    displayName: string;
}

export const getAllUserSettings = (): Promise<ApiResponse<UserSettingDto[]>> => {
    return httpRequest<UserSettingDto[]>(USER_URL, HttpMethod.GET);
};

export const createUserSetting = (user: UserSettingDto): Promise<ApiResponse<UserSettingDto>> => {
    return httpRequest<UserSettingDto>(USER_URL, HttpMethod.POST, user);
};

export const deleteUserSetting = (user: UserSettingDto): Promise<ApiResponse<void>> => {
    return httpRequest(`${USER_URL}/${user.email}`, HttpMethod.DELETE);
};
