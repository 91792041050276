import { useCallback, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { useGetMonitoringGroupsQuery } from '../api/apiSlice';
import { EventCommentDto, EventDto } from '../api/eventApi';
import { ConnectionStatus } from '../components/pages/liveStatus/connectionStatus';
import {
    acknowledgeStoreEvent,
    addComment,
    addEvent,
    resolveEvent,
    unacknowledgeStoreEvent,
} from '../state/eventsSlice';
import { useSignalRConnection, useSignalREvent } from './useSignalR';

type UseCometSignalRConnectionsProps = {
    marketKey: string;
    monitoringGroupId: number;
};

type ConnectionResponse = {
    status: ConnectionStatus;
    lastReconnectionTime?: Date | undefined;
};

const useCometSignalRConnections = ({
    marketKey,
    monitoringGroupId,
}: UseCometSignalRConnectionsProps): ConnectionResponse => {
    const monitoringGroups = useGetMonitoringGroupsQuery();
    const relevantMonitoringGroup = useMemo(
        () => monitoringGroups.data?.find((m) => m.id === monitoringGroupId),
        [monitoringGroupId, monitoringGroups.data],
    );

    const dispatch = useDispatch();
    const { connection, status, lastReconnectionTime } = useSignalRConnection();
    const addEventCallback = useCallback(
        (payload: EventDto) => {
            if (marketKey !== 'global' && payload.marketKey !== marketKey) {
                return;
            }

            if (
                monitoringGroupId !== 0 &&
                !relevantMonitoringGroup?.siteKeys.includes(payload.siteKey)
            ) {
                return;
            }

            dispatch(addEvent(payload));
        },
        [dispatch, marketKey, monitoringGroupId, relevantMonitoringGroup?.siteKeys],
    );
    useSignalREvent({
        eventType: 'eventCreated',
        action: addEventCallback,
        connection,
    });
    const resolveEventCallback = useCallback(
        (payload: EventDto) => dispatch(resolveEvent(payload)),
        [dispatch],
    );
    useSignalREvent({
        eventType: 'eventResolved',
        action: resolveEventCallback,
        connection,
    });
    useSignalREvent({
        eventType: 'customActionEventDeleted',
        action: resolveEventCallback,
        connection,
    });
    const acknowledgeEventCallback = useCallback(
        (payload: EventDto) => dispatch(acknowledgeStoreEvent(payload)),
        [dispatch],
    );
    useSignalREvent({
        eventType: 'eventAcknowledged',
        action: acknowledgeEventCallback,
        connection,
    });
    const unacknowledgeEventCallback = useCallback(
        (payload: EventDto) => dispatch(unacknowledgeStoreEvent(payload)),
        [dispatch],
    );
    useSignalREvent({
        eventType: 'eventUnacknowledged',
        action: unacknowledgeEventCallback,
        connection,
    });
    const acknowledgeManyEventsCallback = useCallback(
        (payload: EventDto[]) => payload.forEach((e) => dispatch(acknowledgeStoreEvent(e))),
        [dispatch],
    );
    useSignalREvent({
        eventType: 'eventsAcknowledged',
        action: acknowledgeManyEventsCallback,
        connection,
    });
    const unacknowledgeManyEventsCallback = useCallback(
        (payload: EventDto[]) => payload.forEach((e) => dispatch(unacknowledgeStoreEvent(e))),
        [dispatch],
    );
    useSignalREvent({
        eventType: 'eventsUnacknowledged',
        action: unacknowledgeManyEventsCallback,
        connection,
    });
    const addCommentCallback = useCallback(
        (payload: EventCommentDto) => dispatch(addComment(payload)),
        [dispatch],
    );
    useSignalREvent({
        eventType: 'commentAdded',
        action: addCommentCallback,
        connection,
    });
    const addManyCommentsCallback = useCallback(
        (payload: EventCommentDto[]) => payload.forEach((c) => dispatch(addComment(c))),
        [dispatch],
    );
    useSignalREvent({
        eventType: 'commentsAdded',
        action: addManyCommentsCallback,
        connection,
    });

    return { status, lastReconnectionTime };
};

export { useCometSignalRConnections };
