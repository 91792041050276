import { createStyles, Link, makeStyles, Table, TableBody } from '@material-ui/core';
import React, { useEffect, useRef, useState } from 'react';
import { SiteDto, getSites, GridDto, AssetDto, getAssets } from '../../../api/siteApi';
import { CustomTheme } from '../../../styles/theme';
import InfoIcon from '@material-ui/icons/Info';
import { LiveStatusUnpinned } from './liveStatusUnpinned';
import { LiveStatusRow, useRowStyles } from './liveStatusRow';
import { LiveStatusLegend } from './liveStatusLegend';
import { ConnectionStatus, ConnectionStatusIndicator } from '../liveStatus/connectionStatus';
import { useSelector } from 'react-redux';
import { EventDto, getEvents } from '../../../api/eventApi';
import { BASE_API_URL } from '../../../api/sharedApi';
import { authProvider } from '../../../auth/authProvider';
import * as signalR from '@microsoft/signalr';
import { getAverageWindSpeed, SiteWindAverageDto } from '../../../api/siteApi';
import { Tooltip } from '@material-ui/core';
import { getMarketKeySelector, getMonitoringGroupSelector } from '../../../state/rootReducerSlice';

const useStyles = makeStyles((theme: CustomTheme) =>
    createStyles({
        liveStatusRoot: {
            boxShadow: '0px 2px 4px #FFFFFF29',
        },
        topBar: {
            backgroundColor: theme.palette.extraColors.background1,
            height: 55,
            padding: '1rem 1.5rem',
        },
        topBarSection: {
            float: 'left',
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            '& div': {
                marginRight: theme.spacing(1),
            },
        },
        rightLink: {
            color: theme.palette.text.secondary,
            float: 'right',
            marginTop: 4,
        },
        openLegendText: {
            verticalAlign: 'top',
            marginRight: 5,
        },
        openLegendIcon: {
            fontSize: 18,
            marginTop: -1,
        },
        pinnedSiteArea: {
            overflowY: 'auto',
            margin: 0,
            padding: 0,
            listStyle: 'none',
            height: '100%',
            '&::-webkit-scrollbar': {
                width: '0.6em',
            },
            '&::-webkit-scrollbar-track': {
                backgroundColor: '#2E2E2E',
            },
            '&::-webkit-scrollbar-thumb': {
                backgroundColor: '#C7C7C7',
            },
        },
    }),
);

export const LiveStatusPage = () => {
    const classes = useStyles();
    const rowStyles = useRowStyles();

    const currentMarketKey = useSelector(getMarketKeySelector);
    const monitoringGroupId = useSelector(getMonitoringGroupSelector);

    const [siteList, setSiteList] = useState<SiteDto[]>([]);
    const [assetList, setAssetList] = useState<AssetDto[]>([]);
    const [eventList, setEventList] = useState<EventDto[]>([]);
    const [siteWindData, setSiteWindData] = useState<SiteWindAverageDto[]>([]);

    const [isViewingLegend, setIsViewingLegend] = useState(false);

    const storedPins = localStorage.getItem('pinnedSites');
    const [pinnedSites, setPinnedSites] = useState<string[]>(
        storedPins !== null ? JSON.parse(storedPins) : [],
    );
    const [maxTableSlots, setMaxTableSlots] = useState(0);
    const [pinnedSlots, setPinnedSlots] = useState(0);
    const [unpinnedSlots, setUnpinnedSlots] = useState(0);
    const averageHeight = 75;

    const [isNewAnimation, setIsNewAnimation] = useState(false);

    const [lastReconnectionTime, setLastReconnectionTime] = useState<Date>(); // update the time whenever we reconnect to signalr so we know to re-fetch events

    useEffect(() => {
        setIsNewAnimation(true);
    }, [eventList]);

    useEffect(() => {
        const reset_animation = (el: Element) => {
            el.classList.remove(rowStyles.flicker);
            setTimeout(() => {
                el.classList.add(rowStyles.flicker);
            }, 1);
        };
        if (isNewAnimation) {
            var flickeringElements = Array.from(document.getElementsByClassName(rowStyles.flicker));
            flickeringElements.forEach((element) => {
                reset_animation(element);
            });
        }
        setIsNewAnimation(false);
        //eslint-disable-next-line
    }, [isNewAnimation]);

    useEffect(() => {
        if (maxTableSlots > 3) {
            var maxPinnedSlots = maxTableSlots - 1;

            var pinnedAndVisible = siteList.filter((site) => pinnedSites.includes(site.key));

            var numPinned = pinnedAndVisible.length;
            if (numPinned >= maxPinnedSlots) {
                setUnpinnedSlots(1);
                setPinnedSlots(maxPinnedSlots);
            } else {
                setUnpinnedSlots(maxTableSlots - numPinned);
                setPinnedSlots(numPinned);
            }
        }
    }, [maxTableSlots, pinnedSites, siteList]);

    useEffect(() => {
        const handleResize = () => {
            var pinnedSitesArea = document.getElementById('pinnedSitesArea');
            if (pinnedSitesArea) {
                var maxTableHeight =
                    window.innerHeight - pinnedSitesArea.getBoundingClientRect().top;
                var slots = Math.floor(maxTableHeight / averageHeight);
                setMaxTableSlots(slots);
            }
        };

        handleResize();
        window.addEventListener('resize', handleResize);
    }, []);

    useEffect(() => {
        if (pinnedSlots > 0) {
            var pinnedArea = document.getElementById('pinnedSitesArea');
            if (pinnedArea) {
                pinnedArea.style.maxHeight = `${pinnedSlots * averageHeight}px`;
            }
        }
    }, [pinnedSlots]);

    useEffect(() => {
        localStorage.setItem('pinnedSites', JSON.stringify(pinnedSites));
    }, [pinnedSites]);

    const eventsRef = useRef<EventDto[]>([]);

    useEffect(() => {
        eventsRef.current = [...eventList];
    }, [eventList]);

    // TODO: This code is essentially duplicated on the events page. Consider moving signalr connection logic into a shared context
    useEffect(() => {
        let mounted = true;
        let retryFailedConnectionTimeout: NodeJS.Timeout;

        const connection = new signalR.HubConnectionBuilder()
            .withUrl(`${BASE_API_URL}/signalr`, {
                accessTokenFactory: async () => {
                    const token = await authProvider.getAccessToken();
                    return token.accessToken;
                },
            })
            .withAutomaticReconnect({
                nextRetryDelayInMilliseconds: (retryContext) => {
                    return Math.random() * 8000 + 2000; // attempt to reconnect after 2-8 seconds
                },
            })
            .build();
        if (mounted) {
            setConnectionStatus('Connecting');
        }

        const startConnection = () => {
            if (mounted) {
                setConnectionStatus('Connecting');
                connection
                    .start()
                    .then(() => setConnectionStatus('Online'))
                    .catch((err) => {
                        if (mounted) {
                            setConnectionStatus('Offline');
                        }
                        console.log(err);
                        retryFailedConnectionTimeout = setTimeout(() => startConnection(), 10000);
                    });
            }
        };
        startConnection();

        connection.on('eventCreated', (event: EventDto) => {
            if (mounted) {
                var copy = [...eventsRef.current];
                copy.push(event);
                setEventList(copy);
            }
        });

        connection.on('eventResolved', (event: EventDto) => {
            var index = 0;
            var eventsCopy: EventDto[];
            index = eventsRef.current.findIndex((e) => e.id === event.id);
            if (index >= 0) {
                if (mounted) {
                    eventsCopy = [...eventsRef.current];
                    eventsCopy.splice(index, 1);
                    setEventList(eventsCopy);
                }
            } else {
                console.log(`could not find event with id ${event.id}`);
            }
        });

        connection.on('eventAcknowledged', (event: EventDto) => {
            if (mounted) {
                var exists = eventsRef.current.find((e) => e.id === event.id);
                if (exists) {
                    var eventIndex = eventsRef.current.indexOf(exists);
                    eventsRef.current.splice(eventIndex, 1);
                    eventsRef.current.push(event);
                    setEventList(eventsRef.current);
                } else {
                    console.log(`Error: could not find event with id ${event.id}`);
                }
            }
        });

        connection.on('eventUnacknowledged', (event: EventDto) => {
            if (mounted) {
                var exists = eventsRef.current.find((e) => e.id === event.id);
                if (exists) {
                    var eventIndex = eventsRef.current.indexOf(exists);
                    eventsRef.current.splice(eventIndex, 1);
                    eventsRef.current.push(event);
                    setEventList(eventsRef.current);
                } else {
                    console.log(`Error: could not find event with id ${event.id}`);
                }
            }
        });

        connection.onreconnecting(() => {
            if (mounted) setConnectionStatus('Connecting');
        });
        connection.onreconnected(() => {
            if (mounted) {
                console.log('Reconnected to SignalR, re-fetching event list');
                setLastReconnectionTime(new Date());
                setConnectionStatus('Online');
            }
        });
        connection.onclose(() => {
            if (mounted) setConnectionStatus('Offline');
        });

        //Unmount
        return () => {
            mounted = false;
            clearTimeout(retryFailedConnectionTimeout);
            connection.stop();
        };
    }, []);

    const [connectionStatus, setConnectionStatus] = useState<ConnectionStatus>('Offline');

    useEffect(() => {
        const fetchSites = async () => {
            if (currentMarketKey !== undefined && currentMarketKey !== '') {
                var marketKey: string | undefined;
                var groupId: number | undefined;
                if (currentMarketKey !== 'global') {
                    marketKey = currentMarketKey;
                }
                if (monitoringGroupId !== 0) {
                    groupId = monitoringGroupId;
                }

                const result = await getSites(marketKey, groupId);
                if (result.data) {
                    setSiteList(result.data.sort(sortSites));
                }
            }
        };
        const fetchAssets = async () => {
            const result = await getAssets();
            if (result.data) {
                setAssetList(result.data);
            }
        };

        const fetchEvents = async () => {
            if (currentMarketKey !== undefined && currentMarketKey !== '') {
                var marketKey: string | undefined;
                var groupId: number | undefined;
                var isOpen = true;
                if (currentMarketKey !== 'global') {
                    marketKey = currentMarketKey;
                }
                if (monitoringGroupId !== 0) {
                    groupId = monitoringGroupId;
                }
                const result = await getEvents(marketKey, groupId, isOpen);
                if (result.data) {
                    setEventList(result.data);
                }
            }
        };

        fetchSites();
        fetchAssets();
        fetchEvents();
    }, [lastReconnectionTime, currentMarketKey, monitoringGroupId]);

    useEffect(() => {
        const fetchAverageWindSpeed = async () => {
            var siteKeys = '';
            siteList.forEach((s) => {
                siteKeys += s.key + ',';
            });
            const result = await getAverageWindSpeed(siteKeys.slice(0, -1));
            if (result.data) {
                setSiteWindData(result.data);
            }
        };

        if (siteList.length > 0) {
            fetchAverageWindSpeed();
            var intervalID = setInterval(() => {
                fetchAverageWindSpeed();
            }, 1000 * 60);
            return () => {
                clearInterval(intervalID);
            };
        }
    }, [siteList]);

    const sortSites = (a: SiteDto, b: SiteDto) => {
        if (a.name.toLowerCase() < b.name.toLowerCase()) return -1;
        if (a.name.toLowerCase() > b.name.toLowerCase()) return 1;
        else return 0;
    };

    const pinSite = (siteKey: string) => {
        var sites = [...siteList];
        var found = sites.find((s) => s.key === siteKey);
        if (found) {
            var site = sites[sites.indexOf(found)];
            var pinExists = pinnedSites.find((p) => p === site.key);
            if (pinExists) {
                var pinIndex = pinnedSites.indexOf(pinExists);
                var copy = [...pinnedSites];
                copy.splice(pinIndex, 1);
                setPinnedSites(copy);
            } else {
                setPinnedSites((c) => [...c, site.key]);
            }
        }
        setSiteList(sites);
    };

    const openLegend = () => {
        setIsViewingLegend(true);
    };

    const handleClose = () => {
        setIsViewingLegend(false);
    };

    const getSiteAssets = (grids: GridDto[]) => {
        var assets: AssetDto[][] = [];
        grids.forEach((grid) => {
            assets.push(
                assetList
                    .filter((a) => a.gridKey === grid.key)
                    .filter((a) => a.isThirdParty === false),
            );
        });
        return assets;
    };

    const isSitePinned = (siteKey: string) => {
        var pinExists = pinnedSites.find((p) => p === siteKey);
        return pinExists !== undefined;
    };

    return (
        <>
            <div className={classes.liveStatusRoot}>
                <div className={classes.topBar}>
                    <div className={classes.topBarSection}>
                        <div>Live Status</div>
                        <ConnectionStatusIndicator status={connectionStatus} />
                    </div>
                    <Link onClick={openLegend} className={classes.rightLink} component="button">
                        <Tooltip title={'Legend'}>
                            <InfoIcon className={classes.openLegendIcon} />
                        </Tooltip>
                    </Link>
                </div>
                <div id="pinnedSitesArea" className={classes.pinnedSiteArea}>
                    <Table aria-label="simple table">
                        <TableBody>
                            {siteList
                                .filter((s) => isSitePinned(s.key) === true)
                                .map((site, index) => {
                                    var siteAssets = getSiteAssets(site.grids);
                                    var windData = siteWindData.find(
                                        (data) => data.siteKey === site.key,
                                    );

                                    return (
                                        <LiveStatusRow
                                            site={site}
                                            windData={windData}
                                            isBottomRow={false}
                                            isPinned={true}
                                            pinSite={pinSite}
                                            key={index}
                                            assets={siteAssets}
                                            siteEvents={eventList.filter(
                                                (e) => e.siteKey === site.key,
                                            )}
                                        />
                                    );
                                })}
                        </TableBody>
                    </Table>
                </div>
                <div id="unpinnedSitesArea">
                    <Table aria-label="simple table">
                        <TableBody>
                            <LiveStatusUnpinned
                                siteList={siteList}
                                siteWindData={siteWindData}
                                pinSite={pinSite}
                                getSiteAssets={getSiteAssets}
                                isSitePinned={isSitePinned}
                                eventList={eventList}
                                pageSlots={unpinnedSlots}
                                setIsNewAnimation={setIsNewAnimation}
                            />
                        </TableBody>
                    </Table>
                </div>
            </div>
            {isViewingLegend ? <LiveStatusLegend handleClose={handleClose} /> : null}
        </>
    );
};
