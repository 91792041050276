import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { checkUserPermission } from '../../../auth/authChecker';
import { AccessLevels } from '../../../auth/authAccessLevels';
import { getMarketKeySelector, getMonitoringGroupSelector } from '../../../state/rootReducerSlice';
import { ScheduledEventsTable } from './scheduledEventsTable';
import LinearProgress from '@material-ui/core/LinearProgress';
import { RecurringEventsTable } from './recurringEventsTable';
import {
    useGetMarketsQuery,
    useGetSitesQuery,
    useGetTechnologiesQuery,
} from '../../../api/apiSlice';
import { createStyles, makeStyles, Typography } from '@material-ui/core';
import { CustomTheme } from '../../../styles/theme';

const useStyles = makeStyles((theme: CustomTheme) =>
    createStyles({
        background: {
            background: theme.palette.background.default,
        },
    }),
);

export const ScheduledEventsPage = () => {
    const classes = useStyles();

    const currentMarketKey = useSelector(getMarketKeySelector);
    const monitoringGroupId = useSelector(getMonitoringGroupSelector);

    const userIsEditor = useMemo(() => checkUserPermission(AccessLevels.EventEditor), []);

    const {
        isLoading: marketsLoading,
        data: markets,
        isError: marketsError,
    } = useGetMarketsQuery();
    const {
        isLoading: techLoading,
        data: technologies,
        isError: techError,
    } = useGetTechnologiesQuery();
    const {
        isLoading: sitesLoading,
        data: sites,
        isError: sitesError,
    } = useGetSitesQuery({
        marketKey: undefined,
        monitoringGroupId: monitoringGroupId,
    });

    if (
        currentMarketKey === undefined ||
        monitoringGroupId === undefined ||
        sitesLoading ||
        techLoading ||
        marketsLoading
    ) {
        return <LinearProgress data-testid="loading" />;
    }

    if (marketsError || sitesError || techError) {
        return (
            <Typography>
                There was an error loading this page. Please refresh and try again. If there error
                persists, contact support.
            </Typography>
        );
    }

    return (
        <div className={classes.background}>
            <ScheduledEventsTable
                sites={sites ?? []}
                markets={markets ?? []}
                technologies={technologies ?? []}
                userIsEditor={userIsEditor}
                selectedMarketKey={currentMarketKey}
                selectedMonitoringGroupId={monitoringGroupId}
            />
            <RecurringEventsTable
                sites={sites ?? []}
                markets={markets ?? []}
                technologies={technologies ?? []}
                userIsEditor={userIsEditor}
                selectedMarketKey={currentMarketKey}
                selectedMonitoringGroupId={monitoringGroupId}
            />
        </div>
    );
};
