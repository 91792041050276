import React, { useState, useEffect } from 'react';
import {
    Table,
    makeStyles,
    createStyles,
    TableRow,
    TableCell,
    TableContainer,
} from '@material-ui/core';
import { SettingsNavigation } from '../settingsNavigation';
import { SortableTableHeader, HeadCell, Order } from '../../../reusable/sortableTableHeader';
import { SortableTableBody } from '../../../reusable/sortableTableBody';
import { UserSettingDto, getAllUserSettings } from '../../../../api/userApi';
import { CustomTheme } from '../../../../styles/theme';
import {
    checkUserPermission,
    formatAccessLevelForDisplay,
} from '../../../../auth/authChecker';
import { UserSettingPopup } from './userSettingsPopup';
import { AccessLevels } from '../../../../auth/authAccessLevels';

const useStyles = makeStyles((theme: CustomTheme) =>
    createStyles({
        userSettingsRoot: {
            boxShadow: '0px 2px 4px #FFFFFF29',
        },
        userSettingsContent: {
            padding: '1.5rem',
            paddingTop: '0.5rem',
        },
        table: {
            width: 'auto',
        },
        tableBody: {
            maxHeight: 'calc(100vh - 240px)',
            overflowY: 'auto',
        },
    }),
);

export const UserSettingsPage = () => {
    const classes = useStyles();

    const [order, setOrder] = useState<Order>('asc');
    const [orderBy, setOrderBy] = useState<keyof UserSettingDto>('email');
    const headCells: HeadCell<UserSettingDto>[] = [
        { id: 'email', label: 'Username', isSortable: true, dataField: 'email' },
        { id: 'permissionLevel', label: 'Permission Level', isSortable: false },
    ];
    const [userSettings, setUserSettings] = useState<UserSettingDto[]>([]);
    const [isAddingUser, setIsAddingUser] = useState(false);

    useEffect(() => {
        const fetchUserSettings = async () => {
            const result = await getAllUserSettings();
            if (result.data) {
                setUserSettings(result.data);
            }
        };

        fetchUserSettings();
    }, []);

    const openPopup = () => {
        setIsAddingUser(true);
    };

    const handleClose = () => {
        setIsAddingUser(false);
    };

    return (
        <>
            <div className={classes.userSettingsRoot}>
                <SettingsNavigation addText={'Add New User'} addAction={openPopup} />
                <div className={classes.userSettingsContent}>
                    <TableContainer className={classes.tableBody}>
                        <Table className={classes.table} aria-label="simple table">
                            <SortableTableHeader
                                order={order}
                                orderBy={orderBy}
                                headCells={headCells}
                                setOrder={setOrder}
                                setOrderBy={setOrderBy}
                            />
                            <SortableTableBody
                                order={order}
                                orderBy={orderBy}
                                data={userSettings}
                                renderRow={(row: UserSettingDto) => {
                                    return (
                                        <TableRow hover key={row.email}>
                                            <TableCell padding="none">{row.email}</TableCell>
                                            <TableCell>
                                                {formatAccessLevelForDisplay(row.permissionLevel)}
                                            </TableCell>
                                        </TableRow>
                                    );
                                }}
                            />
                        </Table>
                    </TableContainer>
                </div>
            </div>
            {isAddingUser && checkUserPermission(AccessLevels.Administrator) ? (
                <UserSettingPopup handleClose={handleClose} />
            ) : null}
        </>
    );
};
