import { AccessLevels } from './authAccessLevels';
import { authProvider } from './authProvider';

var orderedAccessLevels = [
    AccessLevels.EventReader,
    AccessLevels.EventEditor,
    AccessLevels.Administrator,
];

export function getAccessLevel() {
    const claims = authProvider.getAccount().idTokenClaims;

    let highestClaim = 0;
    if (claims && claims.groups) {
        for (var i = 0; i < claims.groups.length; i++) {
            switch (claims.groups[i]) {
                case process.env.REACT_APP_AZURE_AD_ADMINISTRATOR_GROUP_ID:
                    highestClaim = Math.max(
                        highestClaim,
                        orderedAccessLevels.indexOf(AccessLevels.Administrator),
                    );
                    break;
                case process.env.REACT_APP_AZURE_AD_EVENT_EDITOR_GROUP_ID:
                    highestClaim = Math.max(
                        highestClaim,
                        orderedAccessLevels.indexOf(AccessLevels.EventEditor),
                    );
                    break;
            }
        }
    }

    return orderedAccessLevels[highestClaim];
}

export function checkUserPermission(requestedAccess: AccessLevels) {
    var userAccess = getAccessLevel();
    var userIndex = orderedAccessLevels.indexOf(AccessLevels[userAccess as AccessLevels]);
    var requestedIndex = orderedAccessLevels.indexOf(requestedAccess);
    if (userIndex >= 0 && requestedIndex >= 0 && userIndex >= requestedIndex) {
        return true;
    }
    return false;
}

export const formatAccessLevelForDisplay = (accessString: string) => {
    return accessString.replace(/([A-Z])/g, ' $1').trim();
};
