import React, { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    getMarketKeySelector,
    getMonitoringGroupSelector,
    setMarketKey,
} from '../../state/rootReducerSlice';
import { FilterList } from './filterList';
import { setMonitoringGroup } from '../../state/rootReducerSlice';
import {
    useGetMarketsQuery,
    useGetMonitoringGroupsQuery,
    useGetSitesQuery,
} from '../../api/apiSlice';
import { getFromLocalStorage, setInLocalStorage } from '../../helpers/localStorageAccessor';
import CircularProgress from '@material-ui/core/CircularProgress';

const FilterListContainer = () => {
    const dispatch = useDispatch();

    const selectedMonitoringGroupId = useSelector(getMonitoringGroupSelector);
    const selectedMarketKey = useSelector(getMarketKeySelector);

    const {
        isLoading: marketsLoading,
        data: marketData,
        isError: marketsError,
    } = useGetMarketsQuery();
    const {
        isLoading: groupsLoading,
        data: monitoringGroups,
        isError: groupsError,
    } = useGetMonitoringGroupsQuery();
    const {
        isLoading: sitesLoading,
        data: sites,
        isError: sitesError,
    } = useGetSitesQuery({
        marketKey: selectedMarketKey,
        monitoringGroupId: selectedMonitoringGroupId,
    });

    // Get data from local storage when the component mounts.
    useEffect(() => {
        const idFromStorage = parseInt(getFromLocalStorage('navMonitoringGroupId', '0'));
        if (idFromStorage === 0 || monitoringGroups?.some((g) => g.id === idFromStorage)) {
            dispatch(setMonitoringGroup(idFromStorage));
        }

        const keyFromStorage = getFromLocalStorage('navMarketKey', 'global');
        if (marketData?.some((m) => m.key === keyFromStorage)) {
            dispatch(setMarketKey(keyFromStorage));
        }
    }, [dispatch, monitoringGroups, marketData]);

    const setMonitoringGroupId = useCallback(
        (id: number) => {
            dispatch(setMonitoringGroup(id));
            setInLocalStorage('navMonitoringGroupId', `${id}`);
        },
        [dispatch],
    );

    const setSelectedMarketKey = useCallback(
        (key: string) => {
            dispatch(setMarketKey(key));
            setInLocalStorage('navMarketKey', `${key}`);
        },
        [dispatch],
    );

    if (
        marketsLoading ||
        groupsLoading ||
        sitesLoading ||
        selectedMarketKey === undefined ||
        selectedMonitoringGroupId === undefined
    ) {
        return (
            <span data-testid="loading">
                <CircularProgress />
            </span>
        );
    }

    return (
        <FilterList
            selectedMarketKey={selectedMarketKey}
            setSelectedMarketKey={setSelectedMarketKey}
            markets={marketData ?? []}
            selectedMonitoringGroupId={selectedMonitoringGroupId}
            setMonitoringGroupId={setMonitoringGroupId}
            monitoringGroups={monitoringGroups ?? []}
            siteCount={sites?.length ?? 0}
        />
    );
};

export { FilterListContainer };
