import React, { useCallback, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { useUpdateRecurringEventMutation } from '../../../api/apiSlice';
import { MarketDto } from '../../../api/marketApi';
import { RecurringEventDto } from '../../../api/recurringEventApi';
import { ApiResponse } from '../../../api/sharedApi';
import { SiteDto } from '../../../api/siteApi';
import { createOrUpdateRecurringEvent } from '../../../state/scheduledEventsSlice';
import { RecurringEventPopup } from './recurringEventPopup';

type UpdateRecurringEventPopupProps = {
    handleClose: (newData: boolean) => void;
    siteList: SiteDto[];
    marketList: MarketDto[];
    technologyList: string[];
    filterMarketKey?: string;
    recurringEvent: RecurringEventDto;
};

const UpdateRecurringEventPopup = ({
    handleClose,
    siteList,
    marketList,
    technologyList,
    filterMarketKey,
    recurringEvent,
}: UpdateRecurringEventPopupProps) => {
    const [updateRecurringEvent, { isLoading }] = useUpdateRecurringEventMutation();
    const dispatch = useDispatch();

    const updateRecurringEventCallback = useCallback(
        async (recurringEvent: RecurringEventDto): Promise<ApiResponse<RecurringEventDto>> => {
            try {
                const updatedEvent = await updateRecurringEvent(recurringEvent).unwrap();
                dispatch(createOrUpdateRecurringEvent(updatedEvent));
                return { data: updatedEvent, isSuccess: true };
            } catch {
                return { data: undefined, isSuccess: false };
            }
        },
        [dispatch, updateRecurringEvent],
    );

    return (
        <RecurringEventPopup
            handleClose={handleClose}
            siteList={siteList}
            marketList={marketList}
            technologyList={technologyList}
            initialRecurringEvent={recurringEvent}
            submitButtonText="Update Recurring Event"
            submitAction={updateRecurringEventCallback}
        />
    );
};

export { UpdateRecurringEventPopup };
