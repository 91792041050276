import React from 'react';
import {
    Card,
    CardContent,
    CardHeader,
    createStyles,
    Divider,
    Link,
    makeStyles,
} from '@material-ui/core';
import { CustomTheme } from '../../../styles/theme';
import HelpIcon from '@material-ui/icons/Help';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFilePdf } from '@fortawesome/free-solid-svg-icons';

import { BASE_API_URL } from '../../../api/sharedApi';

const useStyles = makeStyles((theme: CustomTheme) =>
    createStyles({
        helpRoot: {
            display: 'flex',
            width: '100%',
            height: 550,
            alignItems: 'center',
            justifyContent: 'center',
            backgroundColor: theme.palette.background.default,
        },
        helpContainer: {
            display: 'flex',
            flexDirection: 'row',
        },
        helpIcon: {
            fontSize: 45,
            color: theme.palette.extraColors.iconColor5,
            verticalAlign: 'bottom',
        },
        rightTitle: {
            height: 77,
        },
        helpCard: {
            border: '0px',
            backgroundColor: theme.palette.background.default,
            color: theme.palette.text.secondary,
        },
        divider: {
            margin: '0px 75px',
        },
        whiteLink: {
            color: theme.palette.text.secondary,
        },
        linkList: {
            listStyleType: 'none',
            paddingLeft: 0,
            '& > li': {
                margin: `${theme.spacing(2)}px 0`,
            },
        },
        fileIcon: {
            marginRight: theme.spacing(1),
        },
    }),
);

export const HelpPage = () => {
    const classes = useStyles();

    const links = [
        `${BASE_API_URL}/swagger`,
        'https://resowned-smartapi.azurewebsites.net',
        'https://func-cygnus-003.azurewebsites.net/api/swagger/ui',
        'https://func-draco-003.azurewebsites.net/api/swagger/ui',
        'https://func-gemini-003.azurewebsites.net/api/swagger/ui',
    ];

    return (
        <div className={classes.helpRoot}>
            <div className={classes.helpContainer}>
                <Card variant="outlined" className={classes.helpCard}>
                    <CardHeader title="Help" avatar={<HelpIcon className={classes.helpIcon} />} />
                    <CardContent>
                        <div>
                            <Link
                                component="a"
                                href="/COMET.Documentation.pdf"
                                download
                                className={classes.whiteLink}
                                underline="always"
                            >
                                <FontAwesomeIcon icon={faFilePdf} className={classes.fileIcon} />
                                User guide
                            </Link>
                        </div>
                    </CardContent>
                    <CardContent>
                        <div>For urgent issues please email</div>
                        <Link
                            component="a"
                            href="mailto:SMARTSupport@res-group.com"
                            className={classes.whiteLink}
                            underline="always"
                        >
                            SMARTSupport@res-group.com
                        </Link>
                    </CardContent>
                </Card>
                <Divider orientation="vertical" flexItem={true} className={classes.divider} />
                <Card variant="outlined" className={classes.helpCard}>
                    <CardHeader title="API Documentation" className={classes.rightTitle} />
                    <CardContent>
                        <ul className={classes.linkList}>
                            {links.map((link) => (
                                <li>
                                    <Link
                                        component="a"
                                        href={link}
                                        target="_blank"
                                        className={classes.whiteLink}
                                        underline="always"
                                    >
                                        {link}
                                    </Link>
                                </li>
                            ))}
                        </ul>
                    </CardContent>
                </Card>
            </div>
        </div>
    );
};
