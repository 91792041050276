import { createStyles, Link, makeStyles, Tooltip } from '@material-ui/core';
import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { CustomTheme } from '../../../../styles/theme';
import FilterListIcon from '@material-ui/icons/FilterList';
import { FilterPair, TableFilterPopup } from '../../../reusable/tableFilterPopup';
import { getFilters, setFilters } from '../../../../state/eventFiltersSlice';
import { EventDto } from '../../../../api/eventApi';
import { getFromLocalStorage, setInLocalStorage } from '../../../../helpers/localStorageAccessor';
import { HeadCell } from '../../../reusable/sortableTableHeader';

const useStyles = makeStyles((theme: CustomTheme) =>
    createStyles({
        filterIcon: {
            color: theme.palette.primary.light,
            marginRight: 5,
        },
        container: {
            paddingRight: '15px',
        },
    }),
);

const EventTableFiltersContainer = () => {
    const dispatch = useDispatch();
    const classes = useStyles();
    const [isOpen, setIsOpen] = useState(false);

    // Pull any existing filters from local storage on load.
    useEffect(() => {
        const filtersFromLocalStorage = getFromLocalStorage('eventFilters', '[]');

        try {
            const parsedFilters = JSON.parse(filtersFromLocalStorage) as FilterPair<EventDto>[];
            dispatch(setFilters(parsedFilters));
        } catch (err) {
            dispatch(setFilters([]));
        }
    }, [dispatch]);

    const filters = useSelector(getFilters);
    const setFiltersCallback = (filters: FilterPair<EventDto>[]) => {
        dispatch(setFilters(filters));
        setInLocalStorage('eventFilters', `${JSON.stringify(filters)}`);
    };

    const filterableFields: HeadCell<EventDto>[] = useMemo(
        () => [
            {
                id: 'technology',
                label: 'Tech',
                isSortable: true,
                isFilterable: true,
                dataField: 'technology',
            },
            {
                id: 'siteName',
                label: 'Site',
                isSortable: true,
                isFilterable: true,
                dataField: 'siteName',
            },
            {
                id: 'assetName',
                label: 'Asset',
                isSortable: true,
                isFilterable: true,
                dataField: 'assetName',
            },
            {
                id: 'eventType',
                label: 'Event Type',
                isSortable: true,
                isFilterable: true,
                dataField: 'eventType',
            },
        ],
        [],
    );

    const remainingFilterOptions = useMemo(
        () =>
            filterableFields.filter((f) => !filters.some((filter) => filter.field === f.dataField)),
        [filterableFields, filters],
    );

    return (
        <div className={classes.container}>
            <Tooltip title="Open filters">
                <Link onClick={() => setIsOpen(true)} component="button">
                    <FilterListIcon className={classes.filterIcon} />
                </Link>
            </Tooltip>
            {isOpen && (
                <TableFilterPopup
                    handleClose={() => setIsOpen(false)}
                    titleText="Event Filters"
                    setFilters={setFiltersCallback}
                    filters={filters}
                    filterOptions={remainingFilterOptions}
                />
            )}
        </div>
    );
};

export { EventTableFiltersContainer };
