import { format } from 'date-fns';

const minuteMultiplier = 60000;

export const dateTimeFormat = `dd MMM yyyy HH:mm`;

export const typedFormatWithoutHours = 'yyyy-MM-dd';
export const typedFormat = 'yyyy-MM-dd HH:mm';

const csvFormat = 'yyyy-MM-dd-HHmm';
const chartFormat = 'dd MMM yy HH:mm';

export const formatTime = (time: Date, noUtc?: boolean) => {
    var utcCurrent = convertToUTC(time);

    if (noUtc === true) {
        return format(utcCurrent, dateTimeFormat);
    }
    return format(utcCurrent, dateTimeFormat) + ' UTC';
};

export const formatTimeForCSV = (time: Date) => {
    var utcCurrent = convertToUTC(time);
    return format(utcCurrent, csvFormat);
};

export const formatTimeForChart = (time: Date) => {
    var utcCurrent = convertToUTC(time);
    return format(utcCurrent, chartFormat);
};

/* 
Although dates are stored in the database as UTC, they are converted to local time by javscript
To work around this we use convertToUTC to remove the timezone offset for displaying as UTC
and convertFromUTC to reinstate the offset afterwards so the saved times are consistent with what was recieved.
*/
export const convertToUTC = (time: Date) => {
    var dateTime = new Date(time);
    var utcTime = new Date(dateTime.getTime() + dateTime.getTimezoneOffset() * minuteMultiplier);
    return utcTime;
};

export const convertFromUTC = (time: Date) => {
    var utcTime = new Date(time);
    var localTime = new Date(utcTime.getTime() - utcTime.getTimezoneOffset() * minuteMultiplier);
    return localTime;
};
