import { makeStyles, createStyles } from '@material-ui/core';
import { CustomTheme } from './theme';

export const useScheduledItemPageStyles = makeStyles((theme: CustomTheme) =>
    createStyles({
        scheduledItemsRoot: {
            boxShadow: '0px 2px 4px #FFFFFF29',
            backgroundColor: theme.palette.background.paper,
        },
        scheduledItemsContent: {
            padding: '1.5rem',
            paddingTop: '0.5rem',
        },
        table: {
            width: 'auto',
        },
        editLink: {
            color: theme.palette.primary.light,
        },
        tableBody: {
            maxHeight: 'calc(100vh - 240px)',
            overflowY: 'auto',
        },
        tableWrapper: {
            marginBottom: '40px',
        },
    }),
);
