import { BASE_API_URL, HttpMethod, httpRequest, ApiResponse } from './sharedApi';

const LIVE_DATA_URL = `${BASE_API_URL}/livedata`;

export interface LiveDataDto {
    apiKey: string;
    measurement: string;
    quality: string;
    quantity: string;
    edgeTimestamp: Date;
    hubTimestamp: Date;
    sourceTimestamp: Date;
    unit: string;
    value: string | number;
}

export interface LiveDataScada {
    power: number;
    irradiance: number;
    windSpeed: number;
    time: number;
}

export interface LiveDataGeneric {
    time: number;
    [key: string]: any;
}

export const getLiveData = (
    key: string,
    measurement: string,
    startTime: Date,
    endTime: Date,
): Promise<ApiResponse<LiveDataDto[]>> => {
    return httpRequest<LiveDataDto[]>(
        `${LIVE_DATA_URL}/${key}/${measurement}/${startTime.toISOString()}/${endTime.toISOString()}`,
        HttpMethod.GET,
    );
};
