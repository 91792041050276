import { EventCategory } from '../enums/eventCategory';
import { EventType } from '../enums/eventType';
import { ReturnToServiceOption } from '../enums/returnToServiceOption';
import {
    BASE_API_URL,
    HttpMethod,
    httpRequest,
    ApiResponse,
    httpRequestForStream,
} from './sharedApi';

const EVENTS_URL = `${BASE_API_URL}/events`;

function generateIdsQueryString(eventIds: number[]) {
    return eventIds.length > 0 ? '?ids='.concat(eventIds.join('&ids=')) : '';
}

export interface LiveEventData {
    time: number;
    power: number;
    irradiance: number;
    windSpeed: number;
}

export interface EventCommentDto {
    id: number;
    timestamp: Date;
    comment: string;
    authorId: string;
    authorName: string;
    eventId: number;
}

export interface EventDto {
    id: number;

    startTime: Date;
    endTime: Date;
    previousOccurrence?: Date | null;
    aggregated: boolean;

    eventType: EventType;
    code: string;
    message: string;
    lastComment: string;
    eventComments: EventCommentDto[];

    acknowledgedTime: Date | null;

    category: EventCategory;

    siteKey: string;
    siteName: string;
    technology: string;

    assetKey: string;
    assetName: string;

    gridKey: string;
    gridName: string;

    marketKey: string;
    marketName: string;

    isDim?: boolean;
    isAssetThirdParty: boolean;
}

export interface EventsExportOptionsDto {
    selectedSiteKeys: string[];
    exportStart: Date;
    exportEnd: Date;
    eventTypes: EventType[];
    returnToServiceFilter: ReturnToServiceOption;
}

export const getEvents = (
    marketKey?: string,
    monitoringGroupId?: number,
    isOpen?: boolean,
    isActive?: boolean,
    eventType?: string,
    excludeUnassigned: boolean = true,
): Promise<ApiResponse<EventDto[]>> => {
    var queryStrings = [];
    if (isOpen && isOpen !== undefined) {
        queryStrings.push(`isOpen=${isOpen}`);
    }
    if (isActive && isActive !== undefined) {
        queryStrings.push(`isActive=${isActive}`);
    }
    if (marketKey && marketKey !== undefined) {
        queryStrings.push(`marketKey=${marketKey}`);
    }
    if (monitoringGroupId && monitoringGroupId !== undefined) {
        queryStrings.push(`monitoringGroupId=${monitoringGroupId}`);
    }
    if (excludeUnassigned && excludeUnassigned !== undefined) {
        queryStrings.push(`excludeUnassigned=${excludeUnassigned}`);
    }
    if (eventType && eventType !== undefined) {
        queryStrings.push(`eventType=${eventType}`);
    }
    var extraUrl = queryStrings.length > 0 ? `?${queryStrings.join('&')}` : '';
    return httpRequest<EventDto[]>(`${EVENTS_URL}${extraUrl}`, HttpMethod.GET);
};

export const createCustomActionEvent = (event: EventDto): Promise<ApiResponse<EventDto>> => {
    return httpRequest<EventDto>(`${EVENTS_URL}/customAction`, HttpMethod.POST, event);
};

export const deleteCustomActionEvent = (eventId: number): Promise<ApiResponse<void>> => {
    return httpRequest<void>(`${EVENTS_URL}/customAction/${eventId}`, HttpMethod.DELETE);
};

export const acknowledgeEvent = (eventId: number): Promise<ApiResponse<EventDto>> => {
    return httpRequest<EventDto>(`${EVENTS_URL}/${eventId}/acknowledge`, HttpMethod.POST);
};

export const acknowledgeEvents = (eventIds: number[]): Promise<ApiResponse<EventDto[]>> => {
    const queryString = generateIdsQueryString(eventIds);
    return httpRequest<EventDto[]>(
        `${EVENTS_URL}/acknowledge-events${queryString}`,
        HttpMethod.POST,
    );
};

export const unacknowledgeEvent = (eventId: number): Promise<ApiResponse<EventDto>> => {
    return httpRequest<EventDto>(`${EVENTS_URL}/${eventId}/unacknowledge`, HttpMethod.POST);
};

export const unacknowledgeEvents = (eventIds: number[]): Promise<ApiResponse<EventDto[]>> => {
    const queryString = generateIdsQueryString(eventIds);
    return httpRequest<EventDto[]>(
        `${EVENTS_URL}/unacknowledge-events${queryString}`,
        HttpMethod.POST,
    );
};

export const getEventById = (eventId: number): Promise<ApiResponse<EventDto>> => {
    return httpRequest<EventDto>(`${EVENTS_URL}/${eventId}`, HttpMethod.GET);
};

export const previewExportEvents = (
    exportOptions: EventsExportOptionsDto,
): Promise<ApiResponse<number>> => {
    return httpRequest<number>(`${EVENTS_URL}/export/preview`, HttpMethod.POST, exportOptions);
};

export const exportEvents = (
    exportOptions: EventsExportOptionsDto,
): Promise<ApiResponse<string>> => {
    return httpRequestForStream(`${EVENTS_URL}/export`, HttpMethod.POST, exportOptions);
};

export const addEventComment = (eventId: number, comment: string) => {
    return httpRequest<EventCommentDto>(
        `${EVENTS_URL}/${eventId}/comment`,
        HttpMethod.POST,
        comment,
    );
};

export const addEventComments = (eventIds: number[], comment: string) => {
    const queryString = generateIdsQueryString(eventIds);
    return httpRequest<EventDto[]>(
        `${EVENTS_URL}/comments${queryString}`,
        HttpMethod.POST,
        comment,
    );
};
