import {
    Dialog,
    DialogTitle,
    DialogContent,
    Button,
    makeStyles,
    createStyles,
    Grid,
    Link,
} from '@material-ui/core';
import React, { useState } from 'react';
import { ApiResponse } from '../../api/sharedApi';
import { CustomTheme } from '../../styles/theme';
import CloseIcon from '@material-ui/icons/Close';

const useStyles = makeStyles((theme: CustomTheme) =>
    createStyles({
        popupHeader: {
            backgroundColor: theme.palette.extraColors.background1,
            padding: '1rem',
            paddingLeft: '1.5rem',
            color: theme.palette.text.secondary,
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
        },
        deletePopupHeader: {
            backgroundColor: theme.palette.extraColors.background1,
            padding: '1rem',
            paddingLeft: '1.5rem',
            color: theme.palette.text.secondary,
        },
        popupContent: {
            marginTop: 5,
            color: theme.palette.text.secondary,
        },
        popupButton: {
            borderRadius: 0,
            width: 120,
            lineHeight: 1.2,
        },
        cancelButton: {
            backgroundColor: theme.palette.background.default,
            float: 'left',
            color: theme.palette.extraColors.text1,
        },
        acceptButton: {
            backgroundColor: theme.palette.primary.main,
            color: theme.palette.getContrastText(theme.palette.text.primary),
        },
        deleteButton: {
            backgroundColor: theme.palette.error.main,
            color: theme.palette.getContrastText(theme.palette.text.primary),
            marginRight: 10,
        },
        rightButtonGroup: {
            float: 'right',
            marginBottom: '0.5rem',
        },
        deleteConfirmButtons: {
            marginTop: '1rem',
            flex: 1,
            justifyContent: 'space-evenly',
        },
        warningMessage: {
            color: theme.palette.primary.main,
            marginTop: -5,
            marginBottom: 10,
        },
        errorMessage: {
            marginTop: 5,
            color: theme.palette.error.main,
        },
        closeLink: {
            color: theme.palette.text.secondary,
        },
        closeText: {
            lineHeight: '24px',
            fontSize: 15,
            marginRight: 5,
        },
        closeIcon: {
            verticalAlign: 'bottom',
        },
        buttonArea: {
            display: 'flex',
            flexDirection: 'column',
        },
    }),
);

interface PopupContainerProps<T> {
    titleText: string;
    isEditing: boolean;
    getContent: () => JSX.Element;
    handleClose: (isNewData: boolean) => void;
    updateFunction?: (data: T) => Promise<ApiResponse<T>>;
    addFunction?: (data: T) => Promise<ApiResponse<T>>;
    deleteFunction?: (dataId: T) => Promise<ApiResponse<void>>;
    dataCopy: T;
    validateInput?: (data: T) => string;
    popupSize?: string;
    hideButtons?: boolean;
    warningText?: string;
}

export function PopupContainer<T>(props: PopupContainerProps<T>) {
    const classes = useStyles();
    var {
        titleText,
        isEditing,
        getContent,
        handleClose,
        updateFunction,
        addFunction,
        deleteFunction,
        dataCopy,
        validateInput,
        popupSize,
        hideButtons,
        warningText,
    } = props;
    const [isDeleting, setIsDeleting] = useState(false);
    const [errorText, setErrorText] = useState('');

    const deleteData = () => {
        const deleteDataHelper = async () => {
            if (deleteFunction) {
                const result = await deleteFunction(dataCopy);
                if (result.isSuccess) {
                    handleClose(true);
                } else {
                    setIsDeleting(false); // close the deletion dialog so the user can see the error message
                    setErrorText(`Error: ${result.message}`);
                }
            }
        };

        deleteDataHelper();
    };

    const updateData = () => {
        const updateDataHelper = async () => {
            if (updateFunction) {
                var isValidInput = true;
                if (validateInput) {
                    var errorString = validateInput(dataCopy);
                    setErrorText(errorString);
                    isValidInput = errorString === '';
                }
                if (isValidInput) {
                    const result = await updateFunction(dataCopy);
                    if (result.isSuccess) {
                        handleClose(true);
                    } else {
                        setErrorText(`Error: ${result.message}`);
                    }
                }
            }
        };

        updateDataHelper();
    };

    const addData = () => {
        const addDataHelper = async () => {
            if (addFunction) {
                var isValidInput = true;
                if (validateInput) {
                    var errorString = validateInput(dataCopy);
                    setErrorText(errorString);
                    isValidInput = errorString === '';
                }
                if (isValidInput) {
                    const result = await addFunction(dataCopy);
                    if (result.isSuccess) {
                        handleClose(true);
                    } else {
                        setErrorText(`Error: ${result.message}`);
                    }
                }
            }
        };

        addDataHelper();
    };

    return (
        <>
            <Dialog
                open={!isDeleting}
                maxWidth={popupSize === 'md' ? 'md' : popupSize === 'sm' ? 'sm' : 'xs'}
                fullWidth={true}
            >
                <DialogTitle>
                    <div className={classes.popupHeader}>
                        <span>{titleText}</span>
                        {hideButtons && (
                            <Link
                                onClick={() => handleClose(true)}
                                className={classes.closeLink}
                                component="button"
                            >
                                <span className={classes.closeText}>Close</span>
                                <CloseIcon className={classes.closeIcon} />
                            </Link>
                        )}
                    </div>
                </DialogTitle>
                <DialogContent>
                    {getContent()}

                    {!hideButtons && (
                        <div className={classes.buttonArea}>
                            {warningText && (
                                <div className={classes.warningMessage}>{warningText}</div>
                            )}
                            <div>
                                <Button
                                    className={classes.cancelButton + ' ' + classes.popupButton}
                                    onClick={() => handleClose(false)}
                                >
                                    Cancel
                                </Button>
                                <div className={classes.rightButtonGroup}>
                                    {isEditing ? (
                                        <>
                                            <Button
                                                className={
                                                    classes.deleteButton + ' ' + classes.popupButton
                                                }
                                                onClick={() => setIsDeleting(true)}
                                            >
                                                Delete
                                            </Button>
                                            <Button
                                                className={
                                                    classes.acceptButton + ' ' + classes.popupButton
                                                }
                                                onClick={() => updateData()}
                                            >
                                                Update
                                            </Button>
                                        </>
                                    ) : (
                                        <Button
                                            className={
                                                classes.acceptButton + ' ' + classes.popupButton
                                            }
                                            onClick={() => addData()}
                                        >
                                            {titleText}
                                        </Button>
                                    )}
                                </div>
                            </div>
                            {errorText.length > 0 && (
                                <div className={classes.errorMessage}>{errorText}</div>
                            )}
                        </div>
                    )}
                </DialogContent>
            </Dialog>
            {isDeleting ? (
                <Dialog open={isDeleting} maxWidth={'xs'} fullWidth={true}>
                    <DialogTitle>
                        <div className={classes.deletePopupHeader}>Are you sure?</div>
                    </DialogTitle>
                    <DialogContent>
                        <div className={classes.deleteConfirmButtons}>
                            <Grid container className={classes.popupContent} alignItems="center">
                                <Grid item xs={4}>
                                    <Button
                                        className={classes.acceptButton + ' ' + classes.popupButton}
                                        onClick={deleteData}
                                    >
                                        Delete
                                    </Button>
                                </Grid>
                                <Grid item xs={4}></Grid>
                                <Grid item xs={4}>
                                    <Button
                                        className={classes.cancelButton + ' ' + classes.popupButton}
                                        onClick={() => setIsDeleting(false)}
                                    >
                                        No
                                    </Button>
                                </Grid>
                            </Grid>
                        </div>
                    </DialogContent>
                </Dialog>
            ) : null}
        </>
    );
}
