import { createStyles, Grid, makeStyles } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { XAxis, CartesianGrid, Tooltip, Label, AreaChart, Area, Legend, YAxis } from 'recharts';
import { CustomTheme, border4, opposite } from '../../../styles/theme';
import CircularProgress from '@material-ui/core/CircularProgress';
import { getLiveData, LiveDataGeneric } from '../../../api/liveDataApi';
import { formatTime } from '../../reusable/formatTime';
import { subHours } from 'date-fns';
import {
    calculateTickTimes,
    FilterLiveData,
    formatXAxis,
    renderColorfulLegendText,
    sortLiveData,
    verifySourceTime,
} from '../../reusable/LiveChartHelpers';

const useStyles = makeStyles((theme: CustomTheme) =>
    createStyles({
        chart: {
            fontSize: 14,
        },
        labelColor: {
            fill: theme.palette.text.secondary,
        },
        loadingSpace: {
            width: '100%',
            height: 100,
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'center',
            paddingBottom: 25,
        },
        loadingText: {
            marginRight: 15,
        },
        loadingError: {
            color: theme.palette.primary.main,
            fontSize: 18,
        },
        leftColumn: {
            fontWeight: 'bold',
        },
        separator: {
            borderTop: `1px solid ${theme.palette.extraColors.border3}`,
        },
    }),
);

interface EventStatusChartProps {
    assetKey: string;
    startTime: Date;
}

export const EventStatusChart = (props: EventStatusChartProps) => {
    const classes = useStyles();
    const { assetKey, startTime } = props;

    const [statusData, setStatusData] = useState<LiveDataGeneric[]>([]);
    const [statusOptions, setStatusOptions] = useState<string[]>([]);
    const [statusTickTimes, setStatusTickTimes] = useState<number[]>([]);

    const [hasPulledData, setHasPulledData] = useState(false);

    const [firstDataTime, setFirstDataTime] = useState<number>();
    const [lastDataTime, setLastDataTime] = useState<number>();

    const colorReady = border4;
    const colorService = '#CCBB44';
    const colorFault = '#EE6677';
    const colorArray = ['#4477AA', '#228833', '#BBBBBB', '#66CCEE', '#AA3377'];

    useEffect(() => {
        const startTimeConst = subHours(new Date(startTime), 6);
        const endTimeConst = new Date();

        const fetchDataset = async () => {
            var formattedData: LiveDataGeneric[] = [];
            const result = await getLiveData(assetKey, 'Status', startTimeConst, endTimeConst);

            setFirstDataTime(startTimeConst.getTime());
            setLastDataTime(endTimeConst.getTime());

            if (result.data && result.data.length > 0) {
                var lastTime = 0;

                result.data.forEach((dataPoint) => {
                    var time = verifySourceTime(dataPoint.sourceTimestamp, startTimeConst);
                    var status = 'N/A';
                    if (time !== lastTime) {
                        if (dataPoint.value) {
                            status = dataPoint.value.toString();
                        }

                        formattedData.push({
                            time: time,
                            status: status,
                        });
                        lastTime = time;
                    }
                });
                formattedData.sort(sortLiveData);

                gatherStatusOptions(formattedData);
            } else {
                setHasPulledData(true);
            }
        };

        const gatherStatusOptions = (dataSet: LiveDataGeneric[]) => {
            var newStatusOptions: string[] = [];

            for (var i = 0; i < dataSet.length; i++) {
                var status = dataSet[i].status;

                if (newStatusOptions.indexOf(status) === -1) {
                    newStatusOptions.push(status);
                }
            }

            setStatusOptions(newStatusOptions);
            FilterLiveData({
                dataSet: dataSet,
                dataFieldName: 'status',
                setData: setStatusData,
            });
            setHasPulledData(true);
        };

        fetchDataset();
        //eslint-disable-next-line
    }, [assetKey, startTime]);

    useEffect(() => {
        if (firstDataTime && lastDataTime) {
            calculateTickTimes({
                startTime: firstDataTime,
                endTime: lastDataTime,
                setTickTimes: setStatusTickTimes,
            });
        }
    }, [firstDataTime, lastDataTime]);

    const renderTooltipLabel = (label: string | number) => {
        var dateStr = formatTime(new Date(label));
        return dateStr;
    };

    const renderTooltipContent = (
        value: string | number | Array<string | number>,
        name: string,
    ) => {
        return [name, 'Status'];
    };

    return (
        <>
            <Grid item xs={12} className={classes.separator}></Grid>
            <Grid item xs={12} className={classes.leftColumn}>
                Status History
            </Grid>
            <Grid item xs={12}>
                {hasPulledData && statusData.length > 0 ? (
                    <AreaChart
                        width={910}
                        height={165}
                        data={statusData}
                        margin={{
                            top: 0,
                            right: 85,
                            left: 85,
                            bottom: 0,
                        }}
                        className={classes.chart}
                    >
                        <CartesianGrid stroke={border4} />
                        <XAxis
                            dataKey="time"
                            tickLine={false}
                            ticks={statusTickTimes}
                            type="number"
                            axisLine={false}
                            domain={['dataMin', 'dataMax']}
                            tickFormatter={formatXAxis}
                            minTickGap={15}
                        >
                            <Label
                                value="Time(UTC)"
                                position="bottom"
                                offset={-3}
                                className={classes.labelColor}
                            ></Label>
                        </XAxis>
                        <YAxis ticks={[0, 1]} hide={true}></YAxis>
                        <Legend
                            wrapperStyle={{ paddingTop: 15 }}
                            formatter={renderColorfulLegendText}
                        />

                        <Tooltip
                            labelFormatter={renderTooltipLabel}
                            formatter={renderTooltipContent}
                            contentStyle={{ backgroundColor: opposite }}
                        />
                        {statusOptions.map((option, index) => {
                            var color = '';
                            var opacity = 1;
                            switch (option) {
                                case 'Ready':
                                    color = colorReady;
                                    opacity = 0;
                                    break;
                                case 'Service':
                                    color = colorService;
                                    break;
                                case 'Fault':
                                    color = colorFault;
                                    break;
                                default:
                                    color = colorArray[index % colorArray.length];
                            }

                            return (
                                <Area
                                    key={index}
                                    type="stepAfter"
                                    dataKey={option}
                                    stackId={index}
                                    stroke={color}
                                    fill={color}
                                    opacity={opacity}
                                />
                            );
                        })}
                    </AreaChart>
                ) : (
                    <div className={classes.loadingSpace}>
                        {hasPulledData ? (
                            <div className={classes.loadingError}>
                                Error: No status data available
                            </div>
                        ) : (
                            <>
                                <span className={classes.loadingText}>Loading Live Data</span>
                                <div>
                                    <CircularProgress />
                                </div>
                            </>
                        )}
                    </div>
                )}
            </Grid>
        </>
    );
};
