import { BASE_API_URL, HttpMethod, httpRequest, ApiResponse } from './sharedApi';

const SITE_URL = `${BASE_API_URL}/Sites`;

export interface SiteDto {
    key: string;
    name: string;
    marketKey: string;
    technology: string;
    grids: GridDto[];
}

export interface AssetDto {
    key: string;
    name: string;
    shortName: string;
    gridKey: string;
    siteKey: string;
    isThirdParty: boolean;
}

export interface GridDto {
    key: string;
    name: string;
    siteKey: string;
}
export interface SiteWindAverageDto {
    siteKey: string;
    windAverage: number;
}

export const getSites = (
    marketKey?: string,
    monitoringGroupId?: number,
    excludeUnassigned: boolean = true,
): Promise<ApiResponse<SiteDto[]>> => {
    var queryStrings = [];
    if (marketKey && marketKey !== undefined) {
        queryStrings.push(`marketKey=${marketKey}`);
    }
    if (monitoringGroupId && monitoringGroupId !== undefined) {
        queryStrings.push(`monitoringGroupId=${monitoringGroupId}`);
    }
    if (excludeUnassigned && excludeUnassigned !== undefined) {
        queryStrings.push(`excludeUnassigned=${excludeUnassigned}`);
    }
    var extraUrl = queryStrings.length > 0 ? `?${queryStrings.join('&')}` : '';
    return httpRequest<SiteDto[]>(`${SITE_URL}${extraUrl}`, HttpMethod.GET);
};

export const getAssets = (): Promise<ApiResponse<AssetDto[]>> => {
    return httpRequest<AssetDto[]>(`${SITE_URL}/assets`, HttpMethod.GET);
};

export const getAssetsForSite = (id: string): Promise<ApiResponse<AssetDto[]>> => {
    return httpRequest<AssetDto[]>(`${SITE_URL}/${id}/assets`, HttpMethod.GET);
};

export const getAverageWindSpeed = (
    siteKeys: string,
): Promise<ApiResponse<SiteWindAverageDto[]>> => {
    return httpRequest<SiteWindAverageDto[]>(
        `${SITE_URL}/averageWindSpeed/${siteKeys}`,
        HttpMethod.GET,
    );
};
