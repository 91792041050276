import React, { useState, useEffect } from 'react';
import { Grid, Select, MenuItem, FormControl, TextField } from '@material-ui/core';
import { useScheduledItemPopupStyles } from '../../../styles/scheduledItemPopupStyle';
import { EventDto, createCustomActionEvent } from '../../../api/eventApi';
import { EventType } from '../../../enums/eventType';
import ExpandMore from '@material-ui/icons/ExpandMore';
import { PopupContainer } from '../../reusable/popupContainer';
import { AssetDto, getAssetsForSite, SiteDto } from '../../../api/siteApi';
import { MarketDto } from '../../../api/marketApi';
import DateFnsUtils from '@date-io/date-fns';
import { KeyboardDateTimePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import { convertFromUTC, convertToUTC, typedFormat } from '../../reusable/formatTime';
import { selectMenuProp } from '../exportEvents/exportEventsPage';
import { EventCategory } from '../../../enums/eventCategory';
import { durationInDays } from '../../../helpers/dateAndTime';

interface ScheduledEventPopupProps {
    handleClose: (newData: boolean) => void;
    siteList: SiteDto[];
    marketList: MarketDto[];
    technologyList: string[];
    filterMarketKey?: string;
}

export const ScheduledEventPopup = (props: ScheduledEventPopupProps) => {
    const classes = useScheduledItemPopupStyles();
    const { handleClose, siteList, marketList, technologyList, filterMarketKey } = props;

    var now = new Date();
    const minuteMultiplier = 60000;
    var nowPlus2 = new Date(now.getTime() + 120 * minuteMultiplier);
    const scheduledEvent: EventDto = {
        // this is initialized as a site level event
        id: -1,
        startTime: now,
        endTime: nowPlus2,
        aggregated: false,

        eventType: EventType.CustomAction,
        code: '',
        message: '',
        lastComment: '',
        eventComments: [],

        acknowledgedTime: null,

        category: EventCategory.Site,

        siteKey: siteList.length > 0 ? siteList[0].key : '',
        siteName: siteList.length > 0 ? siteList[0].name : '',
        technology: technologyList.length > 0 ? technologyList[0] : '',

        assetKey: '',
        assetName: '',

        gridKey: '',
        gridName: '',

        marketKey: `${filterMarketKey ? filterMarketKey : ''}`,
        marketName: `${
            filterMarketKey ? marketList.find((m) => m.key === filterMarketKey)?.name : ''
        }`,

        isAssetThirdParty: false,
    };
    const [marketSiteList, setMarketSiteList] = useState<SiteDto[]>([]);
    const [assetList, setAssetList] = useState<AssetDto[]>([]);
    const [updatedData, setUpdatedData] = useState(scheduledEvent);
    const [warningText, setWarningText] = useState<string | undefined>(undefined);

    const [selectedMarketKey, setSelectedMarketKey] = useState(
        marketList.find((m) => m.key === scheduledEvent.marketKey)?.key || 'global',
    );
    const [selectedTechnology, setSelectedTechnology] = useState('All');

    useEffect(() => {
        const checkCurrentSite = (filteredList: SiteDto[]) => {
            var found = filteredList.find((site) => site.key === updatedData.siteKey);
            if (!found) {
                var siteKey = '';
                var siteName = '';
                if (filteredList.length > 0) {
                    siteKey = filteredList[0].key;
                    siteName = filteredList[0].name;
                }
                if (updatedData.siteKey !== '') {
                    console.log('Error: site no longer matches filters');
                }

                setUpdatedData({
                    ...updatedData,
                    siteKey: siteKey,
                    siteName: siteName,
                    assetName: '',
                    assetKey: '',
                });
            }
        };

        if (siteList.length > 0) {
            var filteredList = siteList.filter(
                (site) =>
                    [site.marketKey, 'global'].includes(selectedMarketKey) &&
                    [site.technology, 'All'].includes(selectedTechnology),
            );
            setMarketSiteList(filteredList);
            checkCurrentSite(filteredList);
        }
        // eslint-disable-next-line
    }, [siteList, selectedMarketKey, selectedTechnology]);

    useEffect(() => {
        if (updatedData.siteKey !== '') {
            const fetchAssets = async () => {
                const result = await getAssetsForSite(updatedData.siteKey);
                if (result.data) {
                    setAssetList(result.data);
                }
            };

            fetchAssets();
        } else {
            setUpdatedData({
                ...updatedData,
                assetKey: '',
                assetName: '',
            });
        }
        // eslint-disable-next-line
    }, [updatedData.siteKey]);

    const validateInputs = (data: EventDto) => {
        if (data.siteKey === '') {
            return 'Error: No site selected';
        } else if (new Date(data.startTime) >= new Date(data.endTime)) {
            return 'Error: End time must be after start time';
        } else {
            return '';
        }
    };

    useEffect(() => {
        const checkEventDuration = () => {
            var duration = durationInDays(updatedData.startTime, updatedData.endTime);

            if (duration > 0) {
                setWarningText('Warning: Scheduled event would equal or exceed 1 day in length');
            } else {
                setWarningText(undefined);
            }
        };

        checkEventDuration();
    }, [updatedData.startTime, updatedData.endTime]);

    return (
        <PopupContainer
            titleText={'Add Scheduled Event'}
            isEditing={false}
            handleClose={handleClose}
            addFunction={createCustomActionEvent}
            dataCopy={updatedData}
            validateInput={validateInputs}
            warningText={warningText}
            popupSize="sm"
            getContent={() => {
                return (
                    <Grid container className={classes.popupContent} alignItems="center">
                        <Grid item xs={5}>
                            Market
                        </Grid>
                        <Grid item xs={7}>
                            <FormControl fullWidth={true}>
                                <Select
                                    value={selectedMarketKey}
                                    disabled={false}
                                    onChange={(e) => {
                                        var key = e.target.value as string;
                                        var market = marketList.find((e) => e.key === key);
                                        if (market) {
                                            setSelectedMarketKey(market.key);
                                        } else {
                                            console.log('Error: market not found');
                                        }
                                    }}
                                    IconComponent={() => (
                                        <ExpandMore className={classes.expandIcons} />
                                    )}
                                    MenuProps={selectMenuProp}
                                >
                                    <MenuItem key="global" value="global">
                                        Global
                                    </MenuItem>
                                    {marketList.map((market) => {
                                        if (market.key !== 'global') {
                                            return (
                                                <MenuItem key={market.key} value={market.key}>
                                                    {market.name}
                                                </MenuItem>
                                            );
                                        }
                                        return '';
                                    })}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={5}>
                            Technology
                        </Grid>
                        <Grid item xs={7}>
                            <FormControl fullWidth={true}>
                                <Select
                                    value={selectedTechnology}
                                    disabled={false}
                                    onChange={(e) => {
                                        var tech = e.target.value as string;
                                        var market = technologyList.find((t) => t === tech);
                                        if (market || tech === 'All') {
                                            setSelectedTechnology(tech);
                                        } else {
                                            console.log('Error: technology not found');
                                        }
                                    }}
                                    IconComponent={() => (
                                        <ExpandMore className={classes.expandIcons} />
                                    )}
                                    MenuProps={selectMenuProp}
                                >
                                    <MenuItem key="All" value="All">
                                        All
                                    </MenuItem>
                                    {technologyList.map((tech, index) => {
                                        return (
                                            <MenuItem key={index} value={tech}>
                                                {tech}
                                            </MenuItem>
                                        );
                                    })}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={5}>
                            Site
                        </Grid>
                        <Grid item xs={7}>
                            <FormControl fullWidth={true}>
                                <Select
                                    value={updatedData.siteKey}
                                    disabled={false}
                                    onChange={(e) => {
                                        var key = e.target.value as string;
                                        var site = marketSiteList.find((e) => e.key === key);
                                        if (site) {
                                            setUpdatedData({
                                                ...updatedData,
                                                siteName: site.name,
                                                siteKey: key,
                                                assetName: '',
                                                assetKey: '',
                                            });
                                        } else {
                                            console.log('Error: site not found');
                                        }
                                    }}
                                    IconComponent={() => (
                                        <ExpandMore className={classes.expandIcons} />
                                    )}
                                    MenuProps={selectMenuProp}
                                >
                                    {updatedData.siteKey !== '' &&
                                        !marketSiteList.find(
                                            (site) => site.key === updatedData.siteKey,
                                        ) && (
                                            <MenuItem
                                                key={updatedData.siteKey}
                                                value={updatedData.siteKey}
                                            >
                                                {updatedData.siteName}
                                            </MenuItem>
                                        )}
                                    {marketSiteList.map((site) => {
                                        return (
                                            <MenuItem key={site.key} value={site.key}>
                                                {site.name}
                                            </MenuItem>
                                        );
                                    })}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={5}>
                            Asset
                        </Grid>
                        <Grid item xs={7}>
                            <FormControl fullWidth={true}>
                                <Select
                                    value={updatedData.assetName}
                                    disabled={false}
                                    onChange={(e) => {
                                        var key = e.target.value as string;
                                        if (key === '') {
                                            setUpdatedData({
                                                ...updatedData,
                                                assetName: '',
                                                assetKey: '',
                                            });
                                        } else {
                                            var asset = assetList.find((e) => e.name === key);
                                            if (asset) {
                                                setUpdatedData({
                                                    ...updatedData,
                                                    assetName: asset.name,
                                                    assetKey: asset.key,
                                                });
                                            } else {
                                                console.log('Error: asset not found');
                                            }
                                        }
                                    }}
                                    IconComponent={() => (
                                        <ExpandMore className={classes.expandIcons} />
                                    )}
                                    MenuProps={selectMenuProp}
                                >
                                    <MenuItem key="" value="">
                                        N/A
                                    </MenuItem>
                                    {assetList.map((asset) => {
                                        return (
                                            <MenuItem key={asset.key} value={asset.name}>
                                                {asset.name}
                                            </MenuItem>
                                        );
                                    })}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={5}>
                            Expected Start
                        </Grid>
                        <Grid item xs={5}>
                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                <KeyboardDateTimePicker
                                    ampm={false}
                                    value={convertToUTC(updatedData.startTime)}
                                    onChange={(date) => {
                                        if (date) {
                                            setUpdatedData({
                                                ...updatedData,
                                                startTime: convertFromUTC(date),
                                            });
                                        }
                                    }}
                                    format={typedFormat}
                                />
                            </MuiPickersUtilsProvider>
                        </Grid>
                        <Grid item xs={2}>
                            <span className={classes.timezoneText}>UTC</span>
                        </Grid>
                        <Grid item xs={5}>
                            Expected End
                        </Grid>
                        <Grid item xs={5}>
                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                <KeyboardDateTimePicker
                                    ampm={false}
                                    value={convertToUTC(updatedData.endTime)}
                                    onChange={(date) => {
                                        if (date) {
                                            setUpdatedData({
                                                ...updatedData,
                                                endTime: convertFromUTC(date),
                                            });
                                        }
                                    }}
                                    format={typedFormat}
                                />
                            </MuiPickersUtilsProvider>
                        </Grid>
                        <Grid item xs={2}>
                            <span className={classes.timezoneText}>UTC</span>
                        </Grid>
                        <Grid item xs={12}>
                            Message
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                className={classes.commentSpace}
                                fullWidth={true}
                                multiline={true}
                                rowsMax={Infinity}
                                value={updatedData.message || ''}
                                onChange={(e) => {
                                    setUpdatedData({
                                        ...updatedData,
                                        message: e.target.value,
                                    });
                                }}
                            />
                        </Grid>
                    </Grid>
                );
            }}
        />
    );
};
