import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';

type EventPopupState = {
    isOpen: boolean;
    eventId: number | undefined;
};

const initialState = { isOpen: false, eventId: undefined } as EventPopupState;

export const eventPopupSlice = createSlice({
    name: 'eventPopout',
    initialState,
    reducers: {
        setIsOpen: (state: EventPopupState, action: PayloadAction<boolean>) => {
            state.isOpen = action.payload;
        },
        setEventId: (state: EventPopupState, action: PayloadAction<number>) => {
            state.eventId = action.payload;
        },
    },
});

export const { setIsOpen, setEventId } = eventPopupSlice.actions;

export const getIsOpenSelector = (state: { eventPopout: EventPopupState }): boolean =>
    state.eventPopout.isOpen;
export const getMarketKeySelector = (state: { eventPopout: EventPopupState }): number | undefined =>
    state.eventPopout.eventId;
