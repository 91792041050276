import {
    createStyles,
    makeStyles,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TableSortLabel,
    Typography,
} from '@material-ui/core';
import React, { useMemo, useState } from 'react';
import { EventDto } from '../../../api/eventApi';
import { AccessLevels } from '../../../auth/authAccessLevels';
import { checkUserPermission } from '../../../auth/authChecker';
import { CustomTheme } from '../../../styles/theme';
import { sort } from '../../../utils/array-utils';
import { ConnectionStatus, ConnectionStatusIndicator } from '../liveStatus/connectionStatus';
import { ButtonWithCount } from './buttonWithCount';
import { EventSelectionPopout } from './bulkUpdatePopout/eventSelectionPopout';
import { EventTableRow } from './eventTableRow';
import { EventDetailsPopoutContainer } from './eventDetailsPopout/eventDetailsPopoutContainer';
import { EventSelectionButtons } from './eventSelectionButtons';
import { useDispatch, useSelector } from 'react-redux';
import { uncheckAll } from '../../../state/eventsSlice';
import { EventTableFiltersContainer } from './filterPopout/eventTableFiltersContainer';
import { getFilters } from '../../../state/eventFiltersSlice';
import { FilterOperator } from '../../../enums/filterOperator';
import { FilterPair } from '../../reusable/tableFilterPopup';

const useStyles = makeStyles((theme: CustomTheme) =>
    createStyles({
        container: {
            overflow: 'auto',
        },
        tableContainer: {
            maxHeight: 'calc(100vh - 240px)',
            overflow: 'auto',
        },
        table: {
            '& .MuiTableCell-head': {
                fontWeight: 800,
                backgroundColor: theme.palette.extraColors.background1,
            },
        },
        headerContainer: {
            padding: '5px',
            display: 'flex',
            flexDirection: 'row',
            backgroundColor: theme.palette.extraColors.background2,
            alignItems: 'center',
        },
        headerRightSide: {
            marginLeft: 'auto',
            marginRight: '10px',
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'end',
        },
        editEventsButton: {
            backgroundColor: theme.palette.primary.main,
            color: theme.palette.extraColors.opposite,
            fontWeight: 'bold',
            padding: '12px',
            marginRight: theme.spacing(2),
            borderRadius: '2px',
        },
        button: {
            margin: '2px',
        },
        filterEnabledHeader: {
            color: theme.palette.primary.light,
        },
        warning: {
            color: theme.palette.warning.main,
            marginLeft: '5px',
        },
    }),
);

const RENDER_LIMIT = 1000;

type SortBy = {
    field?: keyof TableEvent;
    direction?: 'asc' | 'desc';
};

export type TableEvent = {
    isChecked: boolean;
    isAcknowledged: boolean;
    duration?: number;
} & EventDto;

type HeaderCellProps = {
    displayText: string;
    fieldId: keyof TableEvent;
    sortBy: SortBy;
    setSortBy: (sortBy: SortBy) => void;
    filters: FilterPair<EventDto>[];
};

const HeaderCell = ({ displayText, sortBy, fieldId, setSortBy, filters }: HeaderCellProps) => {
    const classes = useStyles();
    const columnIsSorted = sortBy.field === fieldId;
    const isFiltered = filters.some((f) => f.field === fieldId);

    const onClick = () => {
        if (sortBy.direction === undefined) {
            setSortBy({ field: fieldId, direction: 'asc' });
            return;
        }
        if (sortBy.direction === 'asc') {
            setSortBy({ field: fieldId, direction: 'desc' });
            return;
        }

        setSortBy({ field: undefined, direction: undefined });
    };

    return (
        <TableCell className={isFiltered ? classes.filterEnabledHeader : ''}>
            <TableSortLabel active={columnIsSorted} direction={sortBy.direction} onClick={onClick}>
                {displayText}
            </TableSortLabel>
        </TableCell>
    );
};

type EventTableProps = {
    events: TableEvent[];
    connectionStatus: ConnectionStatus;
};

const EventTable = ({ events, connectionStatus }: EventTableProps) => {
    const classes = useStyles();
    const [viewAcknowledged, setViewAcknowledged] = useState(false);
    const [sortBy, setSortBy] = useState<SortBy>({});
    const dispatch = useDispatch();

    const userCanEdit = useMemo(() => checkUserPermission(AccessLevels.EventEditor), []);

    const filters = useSelector(getFilters);

    const changeTabTo = (viewAcknowledged: boolean) => {
        setViewAcknowledged(viewAcknowledged);
        dispatch(uncheckAll());
    };

    const unacknowledgedCount = useMemo(
        () => events.filter((e) => !e.isAcknowledged).length,
        [events],
    );
    const acknowledgedCount = useMemo(
        () => events.filter((e) => e.isAcknowledged).length,
        [events],
    );

    const filteredEventsByAcknowledgement = useMemo(
        () =>
            viewAcknowledged
                ? events.filter((e) => e.isAcknowledged)
                : events.filter((e) => !e.isAcknowledged),
        [viewAcknowledged, events],
    );

    const filteredEvents = useMemo(
        () =>
            filteredEventsByAcknowledgement.filter((event) => {
                if (filters.length === 0) {
                    return true;
                }

                return filters.every((filter) => {
                    if (filter.operator === FilterOperator.EqualTo) {
                        return (
                            event[filter.field]?.toString().toLowerCase() ===
                            filter.value?.toString().toLowerCase()
                        );
                    }

                    return event[filter.field]
                        ?.toString()
                        .toLowerCase()
                        .includes(filter.value?.toString().toLowerCase());
                });
            }),
        [filteredEventsByAcknowledgement, filters],
    );

    const sortedFilteredEvents = useMemo(
        () =>
            !sortBy.field
                ? filteredEvents
                : sort(filteredEvents, sortBy.direction ?? 'asc', sortBy.field),
        [filteredEvents, sortBy],
    );

    const slicedEvents = useMemo(
        () => sortedFilteredEvents.slice(0, RENDER_LIMIT),
        [sortedFilteredEvents],
    );

    return (
        <div className={classes.container}>
            <EventDetailsPopoutContainer />
            <div className={classes.headerContainer}>
                <ButtonWithCount
                    onClick={() => changeTabTo(false)}
                    text="Unacknowledged"
                    count={unacknowledgedCount}
                    isActive={!viewAcknowledged}
                />
                <ButtonWithCount
                    onClick={() => changeTabTo(true)}
                    text="Acknowledged"
                    count={acknowledgedCount}
                    isActive={viewAcknowledged}
                />
                {filteredEvents.length > RENDER_LIMIT ? (
                    <Typography className={classes.warning}>
                        Warning: there are too many events to display, so only the first 1000 are
                        shown.
                    </Typography>
                ) : null}
                <div className={classes.headerRightSide}>
                    <EventTableFiltersContainer />
                    <EventSelectionPopout
                        viewAcknowledged={viewAcknowledged}
                        userCanEdit={userCanEdit}
                    />
                    <ConnectionStatusIndicator status={connectionStatus} />
                </div>
            </div>
            <div>
                <TableContainer component={Paper} className={classes.tableContainer}>
                    <Table stickyHeader className={classes.table}>
                        <TableHead>
                            <TableRow>
                                <HeaderCell
                                    displayText="ID"
                                    fieldId="id"
                                    sortBy={sortBy}
                                    setSortBy={setSortBy}
                                    filters={filters}
                                />
                                <HeaderCell
                                    displayText="Tech"
                                    fieldId="technology"
                                    sortBy={sortBy}
                                    setSortBy={setSortBy}
                                    filters={filters}
                                />
                                <HeaderCell
                                    displayText="Site"
                                    fieldId="siteName"
                                    sortBy={sortBy}
                                    setSortBy={setSortBy}
                                    filters={filters}
                                />
                                <HeaderCell
                                    displayText="Grid"
                                    fieldId="gridName"
                                    sortBy={sortBy}
                                    setSortBy={setSortBy}
                                    filters={filters}
                                />
                                <HeaderCell
                                    displayText="Asset"
                                    fieldId="assetName"
                                    sortBy={sortBy}
                                    setSortBy={setSortBy}
                                    filters={filters}
                                />
                                <HeaderCell
                                    displayText="Start"
                                    fieldId="startTime"
                                    sortBy={sortBy}
                                    setSortBy={setSortBy}
                                    filters={filters}
                                />
                                <HeaderCell
                                    displayText="Duration"
                                    fieldId="duration"
                                    sortBy={sortBy}
                                    setSortBy={setSortBy}
                                    filters={filters}
                                />
                                <HeaderCell
                                    displayText="Type"
                                    fieldId="eventType"
                                    sortBy={sortBy}
                                    setSortBy={setSortBy}
                                    filters={filters}
                                />
                                <TableCell>Last Comment</TableCell>
                                {userCanEdit && <TableCell align="center">Status</TableCell>}
                                <TableCell></TableCell>
                                <TableCell align="center">
                                    <EventSelectionButtons viewAcknowledged={viewAcknowledged} />
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {slicedEvents.map((e) => (
                                <EventTableRow event={e} userCanEdit={userCanEdit} key={e.id} />
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </div>
        </div>
    );
};

export { EventTable };
