import React from 'react';
import { createStyles, makeStyles, Tooltip } from '@material-ui/core';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faCheckCircle,
    faExclamationCircle,
    faEllipsisH,
    IconDefinition,
} from '@fortawesome/free-solid-svg-icons';
import { CustomTheme } from '../../../styles/theme';

export type ConnectionStatus = 'Offline' | 'Connecting' | 'Online';

const useStyles = makeStyles((theme: CustomTheme) =>
    createStyles({
        iconColor: {
            color: theme.palette.extraColors.iconColor2,
        },
    }),
);

export const ConnectionStatusIndicator = ({ status }: { status: ConnectionStatus }) => {
    const classes = useStyles();

    let icon: IconDefinition = faEllipsisH;
    switch (status) {
        case 'Offline': {
            icon = faExclamationCircle;
            break;
        }
        case 'Connecting': {
            icon = faEllipsisH;
            break;
        }
        case 'Online': {
            icon = faCheckCircle;
            break;
        }
    }

    return (
        <Tooltip title={`Server Connection Status: ${status}`}>
            <div>{<FontAwesomeIcon className={classes.iconColor} icon={icon} size="lg" />}</div>
        </Tooltip>
    );
};
