import { createStyles, Link, makeStyles } from '@material-ui/core';
import React from 'react';
import { useDispatch } from 'react-redux';
import { checkAll, uncheckAll } from '../../../state/eventsSlice';
import { CustomTheme } from '../../../styles/theme';

type EventSelectionButtonsProps = {
    viewAcknowledged: boolean;
};

const useStyles = makeStyles((theme: CustomTheme) =>
    createStyles({
        selectButton: {
            color: theme.palette.primary.dark,
            padding: '10px 10px 0px',
        },
        container: {
            display: 'flex',
            flexDirection: 'column',
        },
    }),
);

const EventSelectionButtons = ({ viewAcknowledged }: EventSelectionButtonsProps) => {
    const dispatch = useDispatch();
    const classes = useStyles();

    const selectAll = () =>
        dispatch(checkAll(viewAcknowledged ? 'Acknowledged' : 'Unacknowledged'));
    const unselectAll = () => dispatch(uncheckAll());

    return (
        <div className={classes.container}>
            <Link component="button" onClick={selectAll} className={classes.selectButton}>
                Select All
            </Link>
            <Link component="button" onClick={unselectAll} className={classes.selectButton}>
                Clear All
            </Link>
        </div>
    );
};

export { EventSelectionButtons };
