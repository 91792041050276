import { Table, TableCell, TableContainer, TableRow } from '@material-ui/core';
import React, { useEffect, useMemo, useState } from 'react';
import { deleteCustomActionEvent, EventDto, getEvents } from '../../../api/eventApi';
import { EventType } from '../../../enums/eventType';
import { SortableTableBody } from '../../reusable/sortableTableBody';
import { HeadCell, Order, SortableTableHeader } from '../../reusable/sortableTableHeader';
import { ScheduledEventPopup } from './scheduledEventPopup';
import { formatTime } from '../../reusable/formatTime';
import { SiteDto } from '../../../api/siteApi';
import { MarketDto } from '../../../api/marketApi';
import { useScheduledItemPageStyles } from '../../../styles/scheduledItemPageStyle';
import { TableTopBar } from './tableTopBar';
import { ScheduledEventButtonActions } from './scheduledEventButtonActions';

type ScheduledEventsTableProps = {
    selectedMarketKey: string;
    selectedMonitoringGroupId: number;
    userIsEditor: boolean;
    sites: SiteDto[];
    technologies: string[];
    markets: MarketDto[];
};

const ScheduledEventsTable = ({
    selectedMarketKey,
    selectedMonitoringGroupId,
    userIsEditor,
    sites,
    technologies,
    markets,
}: ScheduledEventsTableProps) => {
    const classes = useScheduledItemPageStyles();
    const [order, setOrder] = useState<Order>('asc');
    const [scheduledEventOrderBy, setScheduledEventOrderBy] = useState<keyof EventDto>('id');
    const scheduledEventHeadCells: HeadCell<EventDto>[] = useMemo(
        () => [
            { id: 'id', label: 'Event Id', isSortable: true, dataField: 'id' },
            { id: 'technology', label: 'Tech', isSortable: true, dataField: 'technology' },
            { id: 'code', label: 'Code', isSortable: true, dataField: 'code' },
            { id: 'siteName', label: 'Site', isSortable: true, dataField: 'siteName' },
            { id: 'gridName', label: 'Grid', isSortable: true, dataField: 'gridName' },
            { id: 'assetName', label: 'Asset', isSortable: true, dataField: 'assetName' },
            { id: 'startTime', label: 'Start Time', isSortable: true, dataField: 'startTime' },
            { id: 'endTime', label: 'End Time', isSortable: true, dataField: 'endTime' },
            { id: 'message', label: 'Message', isSortable: true, dataField: 'message' },
            { id: 'deleteButton', label: '', isSortable: false, dataField: undefined },
        ],
        [],
    );

    const [scheduledEvents, setScheduledEvents] = useState<EventDto[]>([]);
    const [isEditing, setIsEditing] = useState(false);
    const [isNewData, setIsNewData] = useState(true);

    useEffect(() => {
        const fetchScheduledEvents = async () => {
            var marketKey: string | undefined;
            var groupId: number | undefined;
            if (selectedMarketKey !== 'global') {
                marketKey = selectedMarketKey;
            }
            if (selectedMonitoringGroupId !== 0) {
                groupId = selectedMonitoringGroupId;
            }
            // Get scheduled events based on market key, group id
            const scheduledEventResult = await getEvents(
                marketKey,
                groupId,
                true,
                undefined,
                EventType.CustomAction,
            );
            if (scheduledEventResult.data) {
                setScheduledEvents(scheduledEventResult.data);
            }
            setIsNewData(false);
        };

        fetchScheduledEvents();
    }, [isNewData, selectedMarketKey, selectedMonitoringGroupId]);

    const handleDeleteEvent = async (eventId: number) => {
        if (window.confirm(`Delete Scheduled Event with ID ${eventId} ?`)) { 
            await deleteCustomActionEvent(eventId);
            setIsNewData(true);
        }
    };

    const handleEventClose = (newData: boolean) => {
        setIsEditing(false);
        setIsNewData(newData);
    };

    return (
        <div className={classes.tableWrapper}>
            <div className={classes.scheduledItemsRoot}>
                <TableTopBar
                    onButtonClick={() => setIsEditing(true)}
                    titleText="Scheduled Events"
                    buttonText="Add New Scheduled Event"
                    userIsEditor={userIsEditor}
                />
                <div className={classes.scheduledItemsContent}>
                    <TableContainer className={classes.tableBody}>
                        <Table className={classes.table} stickyHeader>
                            <SortableTableHeader
                                order={order}
                                orderBy={scheduledEventOrderBy}
                                headCells={scheduledEventHeadCells}
                                setOrder={setOrder}
                                setOrderBy={setScheduledEventOrderBy}
                            />
                            <SortableTableBody
                                order={order}
                                orderBy={scheduledEventOrderBy}
                                data={scheduledEvents}
                                renderRow={(row: EventDto) => {
                                    return (
                                        <TableRow hover key={row.id}>
                                            <TableCell padding="none">{row.id}</TableCell>
                                            <TableCell>{row.technology}</TableCell>
                                            <TableCell>{row.code}</TableCell>
                                            <TableCell>{row.siteName}</TableCell>
                                            <TableCell>{row.gridName ?? ''}</TableCell>
                                            <TableCell>{row.assetName ?? ''}</TableCell>
                                            <TableCell>{formatTime(row.startTime)}</TableCell>
                                            <TableCell>
                                                {row.endTime ? formatTime(row.endTime) : ''}
                                            </TableCell>
                                            <TableCell>{row.message ?? ''}</TableCell>
                                            <TableCell>
                                                <ScheduledEventButtonActions
                                                    deleteAction={() => 
                                                        handleDeleteEvent(row.id)
                                                    }                                           
                                                />
                                            </TableCell>
                                        </TableRow>
                                    );
                                }}
                            />
                        </Table>
                    </TableContainer>
                </div>
            </div>

            {isEditing && userIsEditor && (
                <ScheduledEventPopup
                    handleClose={(newData: boolean) => handleEventClose(newData)}
                    siteList={sites}
                    marketList={markets}
                    technologyList={technologies}
                    filterMarketKey={selectedMarketKey}
                />
            )}
        </div>
    );
};

export { ScheduledEventsTable };
