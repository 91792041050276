import React, { useState } from 'react';
import { makeStyles, createStyles, Grid, TextField } from '@material-ui/core';
import { CustomTheme } from '../../../../styles/theme';
import {
    updateMonitoringGroup,
    deleteMonitoringGroup,
    createMonitoringGroup,
} from '../../../../api/monitoringGroupApi';
import { SiteDto } from '../../../../api/siteApi';
import { PopupContainer } from '../../../reusable/popupContainer';
import { MonitoringGroupDto } from '../../../../api/monitoringGroupApi';
import { CheckboxMultiSelect } from '../../../reusable/checkboxMultiSelect';

const useStyles = makeStyles((theme: CustomTheme) =>
    createStyles({
        popupContent: {
            marginTop: 5,
            color: theme.palette.text.secondary,
        },
        emailSpace: {
            height: 120,
            maxHeight: 120,
            overflowX: 'hidden',
        },
        infoLine: {
            lineHeight: 1,
            color: theme.palette.primary.main,
            fontSize: '11px',
            marginTop: -11,
            paddingTop: 0,
            marginBottom: 5,
        },
        leftColumn: {
            marginTop: 8,
        },
    }),
);

interface MonitoringGroupPopupProps {
    handleClose: (newData: boolean) => void;
    currentData: MonitoringGroupDto | null;
    siteList: SiteDto[];
}

export const MonitoringGroupPopup = (props: MonitoringGroupPopupProps) => {
    const classes = useStyles();
    const { handleClose, currentData } = props;
    var dataCopy: MonitoringGroupDto;
    if (currentData === null) {
        dataCopy = {
            id: -1,
            name: '',
            siteKeys: [],
            notificationEmails: '',
        };
    } else {
        dataCopy = currentData;
    }

    const [updatedData, setUpdatedData] = useState(dataCopy);

    const handleChange = (site: SiteDto) => {
        var isIncluded = isSiteIncluded(site.key);
        var groupSiteKeys: string[] = updatedData.siteKeys.slice();

        if (isIncluded) {
            var index = groupSiteKeys.indexOf(site.key);
            if (index < 0) {
                console.log('Error: key could not be removed');
            } else {
                groupSiteKeys.splice(index, 1);
            }
        } else {
            groupSiteKeys.push(site.key);
        }
        setUpdatedData({ ...updatedData, siteKeys: groupSiteKeys });
    };

    const isSiteIncluded = (siteKey: string) => {
        var found = updatedData.siteKeys.find((key) => key === siteKey);
        if (found) {
            return true;
        }
        return false;
    };

    const validateEmailList = (group: MonitoringGroupDto) => {
        var notificationList = group.notificationEmails;

        // empty list is allowed
        if (!notificationList) {
            return '';
        }

        const re = /^([\s]*[a-zA-Z0-9!#$%&'*+=?^_`{|}~-]+(?:\.[a-zA-Z0-9!#$%&'*+=?^_`{|}~-]+)*@(?:[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?\.)+[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?[\s]*)(,[\s]*[a-zA-Z0-9!#$%&'*+=?^_`{|}~-]+(?:\.[a-zA-Z0-9!#$%&'*+=?^_`{|}~-]+)*@(?:[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?\.)+[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?[\s]*)*$/;
        return re.test(notificationList) ? '' : 'Error: Notification list formatted incorrectly';
    };

    return (
        <PopupContainer
            titleText={currentData ? 'Edit Monitoring Group' : 'Add Group'}
            isEditing={currentData ? true : false}
            handleClose={handleClose}
            addFunction={createMonitoringGroup}
            updateFunction={updateMonitoringGroup}
            deleteFunction={deleteMonitoringGroup}
            dataCopy={updatedData}
            validateInput={validateEmailList}
            getContent={() => {
                return (
                    <Grid container className={classes.popupContent} alignItems="flex-start">
                        <Grid item xs={5} className={classes.leftColumn}>
                            Name
                        </Grid>
                        <Grid item xs={7}>
                            <TextField
                                value={updatedData.name}
                                fullWidth={true}
                                onChange={(e) => {
                                    setUpdatedData({
                                        ...updatedData,
                                        name: e.target.value,
                                    });
                                }}
                            />
                        </Grid>
                        <Grid item xs={5} className={classes.leftColumn}>
                            Sites
                        </Grid>
                        <Grid item xs={7}>
                            <CheckboxMultiSelect
                                options={props.siteList}
                                selectedOptions={updatedData.siteKeys}
                                displayAccessor={(site: SiteDto) => site.name}
                                valueAccessor={(site: SiteDto) => site.key}
                                onOptionSelect={(site: SiteDto) => handleChange(site)}
                            />
                        </Grid>
                        <Grid item xs={5} className={classes.leftColumn}>
                            Notification List
                        </Grid>
                        <Grid item xs={7}>
                            <TextField
                                className={classes.emailSpace}
                                fullWidth={true}
                                multiline={true}
                                rowsMax={Infinity}
                                value={updatedData.notificationEmails}
                                onChange={(e) => {
                                    setUpdatedData({
                                        ...updatedData,
                                        notificationEmails: e.target.value,
                                    });
                                }}
                            />
                        </Grid>
                        <Grid item xs={5} className={classes.infoLine}></Grid>
                        <Grid item xs={7} className={classes.infoLine}>
                            Comma-separated list of emails that are notified if an event occurs
                        </Grid>
                    </Grid>
                );
            }}
        />
    );
};
