import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';

type RootState = {
    monitoringGroupId?: number;
    marketKeyId?: string;
};

const initialState = { monitoringGroupId: undefined, marketKeyId: undefined } as RootState;

export const rootSlice = createSlice({
    name: 'root',
    initialState,
    reducers: {
        setMonitoringGroup: (state: RootState, action: PayloadAction<number | undefined>) => {
            state.monitoringGroupId = action.payload;
        },
        setMarketKey: (state: RootState, action: PayloadAction<string | undefined>) => {
            state.marketKeyId = action.payload;
        },
    },
});

export const { setMonitoringGroup, setMarketKey } = rootSlice.actions;
export const getMonitoringGroupSelector = (state: { root: RootState }): number | undefined =>
    state.root.monitoringGroupId;
export const getMarketKeySelector = (state: { root: RootState }): string | undefined =>
    state.root.marketKeyId;
