import { differenceInDays } from 'date-fns';

export const durationInDays = (startDate: Date, endDate: Date) => {
    const start = new Date(startDate);
    start.setSeconds(0);
    start.setMilliseconds(0);

    const end = new Date(endDate);
    end.setSeconds(0);
    end.setMilliseconds(0);

    const difference = differenceInDays(end, start);

    return difference;
};
