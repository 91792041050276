import React, { useState, useEffect } from 'react';
import {
    Table,
    Link,
    makeStyles,
    createStyles,
    TableRow,
    TableCell,
    TableContainer,
} from '@material-ui/core';
import { SettingsNavigation } from '../settingsNavigation';
import { SortableTableHeader, HeadCell, Order } from '../../../reusable/sortableTableHeader';
import { SortableTableBody } from '../../../reusable/sortableTableBody';

import { PpaConditionDto, getAllPpaConditions } from '../../../../api/ppaConditionApi';
import { SiteDto, getSites } from '../../../../api/siteApi';
import { CustomTheme } from '../../../../styles/theme';
import { PpaEventPopup } from './editPpaEventPopup';
import {
    formatConditionForDisplay,
    formatFieldForDisplay,
    getUnitForField,
} from './editPpaEventPopup';
import { checkUserPermission } from '../../../../auth/authChecker';
import { AccessLevels } from '../../../../auth/authAccessLevels';

const useStyles = makeStyles((theme: CustomTheme) =>
    createStyles({
        ppaEventsRoot: {
            boxShadow: '0px 2px 4px #FFFFFF29',
        },
        ppaEventsContent: {
            padding: '1.5rem',
            paddingTop: '0.5rem',
        },
        table: {
            width: 'auto',
        },
        editLink: {
            color: theme.palette.primary.light,
        },
        tableBody: {
            maxHeight: 'calc(100vh - 240px)',
            overflowY: 'auto',
        },
    }),
);

export const PPAEventsPage = () => {
    const classes = useStyles();

    const [order, setOrder] = useState<Order>('asc');
    const [orderBy, setOrderBy] = useState<keyof PpaConditionDto>('siteName');
    const [siteList, setSiteList] = useState<SiteDto[]>([]);
    const [ppaConditions, setPpaConditions] = useState<PpaConditionDto[]>([]);
    const headCells: HeadCell<PpaConditionDto>[] = [
        { id: 'siteName', label: 'Site', isSortable: true, dataField: 'siteName' },
        { id: 'field', label: 'Field', isSortable: false },
        { id: 'eventCondition', label: 'Condition', isSortable: false },
        { id: 'minimumDuration', label: 'Minimum Duration', isSortable: false },
        { id: 'edit', label: '', isSortable: false },
    ];
    const [isEditing, setIsEditing] = useState(false);
    const [isNewData, setIsNewData] = useState(false);
    const [infoForEdit, setInfoForEdit] = useState(
        ppaConditions.length > 0 ? ppaConditions[0] : null,
    );

    useEffect(() => {
        setIsNewData(true);
    }, []);

    useEffect(() => {
        if (isNewData) {
            const fetchPpaConditions = async () => {
                const result = await getAllPpaConditions();
                if (result.data) {
                    setPpaConditions(result.data);
                }
                setIsNewData(false);
            };

            fetchPpaConditions();
        }
    }, [isNewData]);

    useEffect(() => {
        const fetchSites = async () => {
            const result = await getSites();
            if (result.data) {
                setSiteList(result.data);
            }
        };
        fetchSites();
    }, []);

    const openEditEvent = (rowID: number) => {
        var site = ppaConditions.find((e) => e.id === rowID);
        if (site) {
            setInfoForEdit(site);
            setIsEditing(true);
        } else {
            console.log('Error: site not found');
        }
    };

    const openNewEvent = () => {
        setInfoForEdit(null);
        setIsEditing(true);
    };

    const handleClose = (newData: boolean) => {
        setIsEditing(false);
        setIsNewData(newData);
    };

    return (
        <>
            <div className={classes.ppaEventsRoot}>
                <SettingsNavigation
                    addText={'Add New Condition'}
                    addAction={() => openNewEvent()}
                />
                <div className={classes.ppaEventsContent}>
                    <TableContainer className={classes.tableBody}>
                        <Table className={classes.table} aria-label="simple table">
                            <SortableTableHeader
                                order={order}
                                orderBy={orderBy}
                                headCells={headCells}
                                setOrder={setOrder}
                                setOrderBy={setOrderBy}
                            />
                            <SortableTableBody
                                order={order}
                                orderBy={orderBy}
                                data={ppaConditions}
                                renderRow={(row: PpaConditionDto) => {
                                    return (
                                        <TableRow hover key={row.id}>
                                            <TableCell padding="none">{row.siteName}</TableCell>
                                            <TableCell>
                                                {formatFieldForDisplay(row.field, true)}
                                            </TableCell>
                                            <TableCell>
                                                {formatConditionForDisplay(
                                                    row.eventCondition,
                                                    row.value,
                                                ) + getUnitForField(row.field)}
                                            </TableCell>
                                            <TableCell>{row.minimumDuration} hours</TableCell>
                                            {checkUserPermission(AccessLevels.Administrator) && (
                                                <TableCell>
                                                    <Link
                                                        component="button"
                                                        className={classes.editLink}
                                                        onClick={() => openEditEvent(row.id)}
                                                    >
                                                        Edit
                                                    </Link>
                                                </TableCell>
                                            )}
                                        </TableRow>
                                    );
                                }}
                            />
                        </Table>
                    </TableContainer>
                </div>
            </div>
            {isEditing && checkUserPermission(AccessLevels.Administrator) ? (
                <PpaEventPopup
                    currentData={infoForEdit}
                    handleClose={(newData: boolean) => handleClose(newData)}
                    siteList={siteList}
                />
            ) : null}
        </>
    );
};
