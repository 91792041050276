import { BASE_API_URL, HttpMethod, httpRequest, ApiResponse } from './sharedApi';

const PPA_URL = `${BASE_API_URL}/PpaConditions`;

export enum FieldOptions {
    //Power = 'Power',
    //ReactivePower = 'ReactivePower',
    Capacity = 'Capacity',
}

export enum ConditionOptions {
    LessThanOrEqual = 'LessThanOrEqual',
    LessThan = 'LessThan',
    Equal = 'Equal',
    GreaterThan = 'GreaterThan',
    GreaterThanOrEqual = 'GreaterThanOrEqual',
}

export interface PpaConditionDto {
    siteName: string;
    field: FieldOptions;
    eventCondition: ConditionOptions;
    minimumDuration: number;
    value: number;
    siteKey: string;
    id: number;
}

export const getAllPpaConditions = (): Promise<ApiResponse<PpaConditionDto[]>> => {
    return httpRequest<PpaConditionDto[]>(PPA_URL, HttpMethod.GET);
};

export const getPpaConditionById = (id: number): Promise<ApiResponse<PpaConditionDto>> => {
    return httpRequest<PpaConditionDto>(`${PPA_URL}/${id}`, HttpMethod.GET);
};

export const createPPACondition = (
    condition: PpaConditionDto,
): Promise<ApiResponse<PpaConditionDto>> => {
    return httpRequest<PpaConditionDto>(PPA_URL, HttpMethod.POST, condition);
};

export const updatePPACondition = (
    condition: PpaConditionDto,
): Promise<ApiResponse<PpaConditionDto>> => {
    return httpRequest<PpaConditionDto>(`${PPA_URL}/${condition.id}`, HttpMethod.PUT, condition);
};

export const deletePPACondition = (condition: PpaConditionDto): Promise<ApiResponse<void>> => {
    return httpRequest(`${PPA_URL}/${condition.id}`, HttpMethod.DELETE);
};
