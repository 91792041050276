import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { authProvider } from '../auth/authProvider';
import { sortAlphabetically } from '../utils/array-utils';
import { EventCommentDto, EventDto } from './eventApi';
import { MarketDto } from './marketApi';
import { MonitoringGroupDto } from './monitoringGroupApi';
import { RecurringEventDto } from './recurringEventApi';
import { AssetDto, SiteDto } from './siteApi';

export const baseUrl: string = process.env.REACT_APP_COMET_API_BASE_URL!;

type GetSitesProps = {
    marketKey?: string;
    monitoringGroupId?: number;
    excludeUnassigned?: boolean;
};

const getSitesQuery = ({
    marketKey,
    monitoringGroupId,
    excludeUnassigned = true,
}: GetSitesProps): string => {
    const searchParameters: { [key: string]: string } = {};
    if (marketKey && marketKey !== undefined && marketKey !== 'global') {
        searchParameters['marketKey'] = marketKey;
    }
    if (monitoringGroupId && monitoringGroupId !== undefined) {
        searchParameters['monitoringGroupId'] = monitoringGroupId.toString();
    }
    if (excludeUnassigned && excludeUnassigned !== undefined) {
        searchParameters['excludeUnassigned'] = excludeUnassigned.toString();
    }
    const urlSearchParameters = new URLSearchParams(searchParameters);
    const queryString = urlSearchParameters.toString();
    return queryString.length > 0 ? `Sites?${queryString}` : 'Sites';
};

type GetEventsProps = {
    marketKey?: string;
    monitoringGroupId?: number;
    isOpen?: boolean;
    isActive?: boolean;
    eventType?: string;
    excludeUnassigned?: boolean;
    lastReconnectionTime?: string;
};

const getEventsQuery = ({
    marketKey,
    monitoringGroupId,
    isOpen = true,
    isActive = true,
    eventType,
    excludeUnassigned = true,
}: GetEventsProps): string => {
    const searchParameters: { [key: string]: string } = {};
    if (isOpen && isOpen !== undefined) {
        searchParameters['isOpen'] = isOpen.toString();
    }
    if (isActive && isActive !== undefined) {
        searchParameters['isActive'] = isActive.toString();
    }
    if (marketKey && marketKey !== 'global' && marketKey !== undefined) {
        searchParameters['marketKey'] = marketKey;
    }
    if (monitoringGroupId && monitoringGroupId !== 0 && monitoringGroupId !== undefined) {
        searchParameters['monitoringGroupId'] = monitoringGroupId.toString();
    }
    if (excludeUnassigned && excludeUnassigned !== undefined) {
        searchParameters['excludeUnassigned'] = excludeUnassigned.toString();
    }
    if (eventType && eventType !== undefined) {
        searchParameters['eventType'] = eventType;
    }
    const urlSearchParameters = new URLSearchParams(searchParameters);
    const queryString = urlSearchParameters.toString();
    return queryString.length > 0 ? `Events?${queryString}` : 'Events';
};

export const apiSlice = createApi({
    reducerPath: 'api',
    baseQuery: fetchBaseQuery({
        baseUrl,
        prepareHeaders: async (headers: Headers, _) => {
            const token = await authProvider.getAccessToken();
            headers.append('Authorization', 'Bearer ' + token.accessToken);
        },
    }),
    endpoints: (builder) => ({
        getMarkets: builder.query<MarketDto[], void>({
            query: () => 'Markets',
            transformResponse: (response: { data: MarketDto[] }) =>
                sortAlphabetically(response.data, 'asc', 'Global'),
        }),
        getMonitoringGroups: builder.query<MonitoringGroupDto[], void>({
            query: () => 'MonitoringGroups',
            transformResponse: (response: { data: MonitoringGroupDto[] }) =>
                sortAlphabetically(response.data),
        }),
        getSites: builder.query<SiteDto[], GetSitesProps>({
            query: getSitesQuery,
            transformResponse: (response: { data: SiteDto[] }) => response.data,
        }),
        getAssetsForSite: builder.query<AssetDto[], string>({
            query: (id) => `Sites/${id}/assets`,
            transformResponse: (response: { data: AssetDto[] }) => response.data,
        }),
        getTechnologies: builder.query<string[], void>({
            query: () => 'Technologies',
            transformResponse: (response: { data: string[] }) => response.data,
        }),
        getEvents: builder.query<EventDto[], GetEventsProps>({
            query: getEventsQuery,
            transformResponse: (response: { data: EventDto[] }) => response.data,
        }),
        acknowledgeEvent: builder.mutation<void, number>({
            query: (id) => ({
                url: `Events/${id}/acknowledge`,
                method: 'post',
            }),
        }),
        unacknowledgeEvent: builder.mutation<void, number>({
            query: (id) => ({
                url: `Events/${id}/unacknowledge`,
                method: 'post',
            }),
        }),
        acknowledgeManyEvents: builder.mutation<EventDto[], EventDto[]>({
            query: (events) => {
                const queryParams = new URLSearchParams();
                events.forEach((e) => queryParams.append('ids', e.id.toString()));
                return {
                    url: `Events/acknowledge-events?${queryParams.toString()}`,
                    method: 'post',
                };
            },
            transformResponse: (response: { data: EventDto[] }) => response.data,
        }),
        unacknowledgeManyEvents: builder.mutation<EventDto[], EventDto[]>({
            query: (events) => {
                const queryParams = new URLSearchParams();
                events.forEach((e) => queryParams.append('ids', e.id.toString()));
                return {
                    url: `Events/unacknowledge-events?${queryParams.toString()}`,
                    method: 'post',
                };
            },
            transformResponse: (response: { data: EventDto[] }) => response.data,
        }),
        commentOnManyEvents: builder.mutation<
            EventCommentDto[],
            { events: EventDto[]; comment: string }
        >({
            query: ({ events, comment }) => {
                const queryParams = new URLSearchParams();
                events.forEach((e) => queryParams.append('ids', e.id.toString()));
                return {
                    url: `Events/comments?${queryParams.toString()}`,
                    method: 'post',
                    body: JSON.stringify(comment),
                    headers: { 'Content-Type': 'application/json' },
                };
            },
            transformResponse: (response: { data: EventCommentDto[] }) => response.data,
        }),
        commentOnEvent: builder.mutation<EventCommentDto, { event: EventDto; comment: string }>({
            query: ({ event, comment }) => ({
                url: `Events/${event.id}/comment`,
                method: 'post',
                body: JSON.stringify(comment),
                headers: { 'Content-Type': 'application/json' },
            }),
            transformResponse: (response: { data: EventCommentDto }) => response.data,
        }),
        updateRecurringEvent: builder.mutation<RecurringEventDto, RecurringEventDto>({
            query: (event) => ({
                url: `RecurringEvents/${event.id}`,
                method: 'put',
                body: JSON.stringify(event),
                headers: { 'Content-Type': 'application/json' },
            }),
            transformResponse: (response: { data: RecurringEventDto }) => response.data,
        }),
        createRecurringEvent: builder.mutation<RecurringEventDto, RecurringEventDto>({
            query: (event) => ({
                url: `RecurringEvents`,
                method: 'post',
                body: JSON.stringify(event),
                headers: { 'Content-Type': 'application/json' },
            }),
            transformResponse: (response: { data: RecurringEventDto }) => response.data,
        }),
    }),
});

export const {
    useGetMarketsQuery,
    useGetMonitoringGroupsQuery,
    useGetSitesQuery,
    useGetAssetsForSiteQuery,
    useGetTechnologiesQuery,
    useGetEventsQuery,
    useAcknowledgeEventMutation,
    useUnacknowledgeEventMutation,
    useAcknowledgeManyEventsMutation,
    useUnacknowledgeManyEventsMutation,
    useCommentOnManyEventsMutation,
    useCommentOnEventMutation,
    useCreateRecurringEventMutation,
    useUpdateRecurringEventMutation,
} = apiSlice;
