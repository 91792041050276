import React from 'react';
import { makeStyles, createStyles, Link, IconButton } from '@material-ui/core';

import { matchPath, NavLink, useLocation } from 'react-router-dom';

import ResLogo from '../../images/res-logo.svg';
import CometLogo from '../../images/comet-logo.svg';
import HelpIcon from '../../images/question-circle-solid.svg';

import UserInfoButton from './userInfo';
import { FilterListContainer } from './filterListContainer';
import { CustomTheme } from '../../styles/theme';

const useStyles = makeStyles((theme: CustomTheme) =>
    createStyles({
        navBarRoot: {
            backgroundColor: theme.palette.extraColors.background2,
            borderBottom: '2px solid ' + theme.palette.extraColors.border1,
            height: '65px',
            paddingLeft: '2rem',
            paddingRight: '2rem',
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
        },
        filterList: {
            display: 'flex',
            alignItems: 'center',
            '& > div': {
                display: 'flex',
                alignItems: 'center',
                margin: theme.spacing(2),
            },
        },
        filterDropdown: {
            margin: theme.spacing(1),
        },
        linkList: {
            marginLeft: 'auto',
            listStyleType: 'none',
            '& li': {
                textAlign: 'center',
                float: 'left',
            },
        },
        link: {
            color: theme.palette.text.secondary,
            margin: theme.spacing(2),
            [theme.breakpoints.down('md')]: {
                margin: theme.spacing(0.75),
            },
        },
        activeLink: {
            color: theme.palette.text.secondary,
            fontWeight: 'bold',
        },
        logoContainer: {
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
        },
        resLogoImage: {
            height: '1.25rem',
        },
        cometLogoImage: {
            height: '4rem',
            marginLeft: '-1.75rem',
            marginRight: '-1.75rem',
            marginTop: '0.25rem',
        },
        helpIcon: {
            height: '19px',
            width: '16px',
        },
    }),
);

export const NavigationBar = () => {
    const classes = useStyles();

    const location = useLocation();
    const match =
        matchPath(location.pathname, { path: '/settings' }) ||
        matchPath(location.pathname, { path: '/help' });

    return (
        <div className={classes.navBarRoot}>
            <div className={classes.logoContainer}>
                <img src={ResLogo} className={classes.resLogoImage} alt="RES Logo" />
                <img src={CometLogo} className={classes.cometLogoImage} alt="COMET Logo" />
            </div>

            {!match && <FilterListContainer />}

            <ul className={classes.linkList}>
                <li>
                    <Link
                        component={NavLink}
                        to="/liveStatus"
                        className={classes.link}
                        activeClassName={classes.activeLink}
                    >
                        Live Status
                    </Link>
                </li>
                <li>
                    <Link
                        component={NavLink}
                        to="/events"
                        className={classes.link}
                        activeClassName={classes.activeLink}
                    >
                        Events
                    </Link>
                </li>
                <li>
                    <Link
                        component={NavLink}
                        to="/scheduleEvents"
                        className={classes.link}
                        activeClassName={classes.activeLink}
                    >
                        Schedule Events
                    </Link>
                </li>
                <li>
                    <Link
                        component={NavLink}
                        to="/exportEvents"
                        className={classes.link}
                        activeClassName={classes.activeLink}
                    >
                        Export Events
                    </Link>
                </li>
                <li>
                    <Link
                        component={NavLink}
                        to="/settings"
                        className={classes.link}
                        activeClassName={classes.activeLink}
                    >
                        Settings
                    </Link>
                </li>
            </ul>

            <IconButton component={NavLink} to="/help" disableRipple>
                <img src={HelpIcon} className={classes.helpIcon} alt="Help" />
            </IconButton>
            <div>
                <UserInfoButton />
            </div>
        </div>
    );
};
