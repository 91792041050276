import { createMuiTheme, Theme } from '@material-ui/core';
import createPalette, { Palette } from '@material-ui/core/styles/createPalette';

interface IPalette extends Palette {
    extraColors: {
        background1: string;
        background2: string;
        background3: string;
        background4: string;
        background5: string;
        background7: string;

        text1: string;
        text2: string;
        text3: string;
        text4: string;
        text5: string;
        text6: string;

        iconColor1: string;
        iconColor2: string;
        iconColor3: string;
        iconColor4: string;
        iconColor5: string;
        iconColor6: string;

        border1: string;
        border2: string;
        border3: string;
        border4: string;

        button1: string;
        button2: string;
        button3: string;

        opposite: string;

        status1: string;
        status2: string;
        status3: string;

        line1: string;
        line2: string;
    };
}

export interface CustomTheme extends Theme {
    palette: IPalette;
}

// eslint-disable-next-line
const lightPalette = createPalette({
    background: {
        default: '#EEEEEE',
    },
    primary: {
        main: '#E07421',
    },
    text: {
        primary: '#475560',
    },
});

const darkPalette = createPalette({
    type: 'dark',
    background: {
        default: '#0F1215', 
        paper: '#242424',
    },
    //primary - orange text
    primary: {
        main: '#E07421',
        dark: '#E56B1D',
        light: '#E87511',
    },
    //text - white/grey text colors
    text: {
        primary: '#FFFFFF',
        secondary: '#EEEEEE',
        disabled: '#717171',
    },
});

const usedPalette = darkPalette;
/* colors for light palette are fillers, feel free to change */
var background1: string = usedPalette === darkPalette ? '#262A2C' : '#D8D8D8'; 
var background2: string = usedPalette === darkPalette ? '#373A3D' : '#FFFFFF';
var background3: string = usedPalette === darkPalette ? '#141414' : '#FFFFFF';
var background4: string = usedPalette === darkPalette ? '#C7C7C7' : '#FFFFFF';
var background5: string = usedPalette === darkPalette ? 'rgba(219, 66, 66, 0.3)' : '#FFFFFF';
export var background6: string = usedPalette === darkPalette ? '#1A1A1A' : '#D8D8D8';
var background7: string = usedPalette === darkPalette ? '#EEEEEE' : '#EEEEEE';

var text1: string = usedPalette === darkPalette ? '#C8C8C8' : '#FFFFFF';
var text2: string = usedPalette === darkPalette ? '#F55151' : '#FFFFFF';
var text3: string = usedPalette === darkPalette ? '#0F1215' : '#0F1215';
var text4: string = usedPalette === darkPalette ? '#373A3D' : '#373A3D';
var text5: string = usedPalette === darkPalette ? '#FFFFFF' : '#FFFFFF';
var text6: string = usedPalette === darkPalette ? '#C7C7C7' : '#C7C7C7';

var iconColor1: string = usedPalette === darkPalette ? '#707070' : '#D8D8D8';
var iconColor2: string = usedPalette === darkPalette ? '#EEEEEE' : '#D8D8D8';
var iconColor3: string = usedPalette === darkPalette ? '#59B037' : '#59B037';
var iconColor4: string = usedPalette === darkPalette ? '#0F1215' : '#0F1215';
var iconColor5: string = usedPalette === darkPalette ? '#E17420' : '#E17420';
var iconColor6: string = usedPalette === darkPalette ? '#6A737B' : '#6A737B';

var border1: string = usedPalette === darkPalette ? '#131313' : '#D8D8D8';
var border2: string = usedPalette === darkPalette ? '#393939' : '#FFFFFF';
var border3: string = usedPalette === darkPalette ? '#2E2E2E' : '#FFFFFF';
export var border4: string = usedPalette === darkPalette ? '#424547' : '#D8D8D8';

var button1: string = usedPalette === darkPalette ? '#C62E2E' : '#D8D8D8';
var button2: string = usedPalette === darkPalette ? '#545454' : '#FFFFFF';
var button3: string = usedPalette === darkPalette ? '#606060' : '#FFFFFF';

export var line1: string = usedPalette === darkPalette ? '#FF9E12' : '#FFFFFF';
export var line2: string = usedPalette === darkPalette ? '#738BE6' : '#D8D8D8';

export var opposite: string = usedPalette === darkPalette ? '#000000' : '#FFFFFF';

var status1: string = usedPalette === darkPalette ? '#78A22F' : '#78A22F';
var status2: string = usedPalette === darkPalette ? '#FDB813' : '#FDB813';
var status3: string = usedPalette === darkPalette ? '#141414' : '#141414';

const newPalette: IPalette = {
    extraColors: {
        background1: background1,
        background2: background2,
        background3: background3,
        background4: background4,
        background5: background5,
        background7: background7,

        text1: text1,
        text2: text2,
        text3: text3,
        text4: text4,
        text5: text5,
        text6: text6,

        iconColor1: iconColor1,
        iconColor2: iconColor2,
        iconColor3: iconColor3,
        iconColor4: iconColor4,
        iconColor5: iconColor5,
        iconColor6: iconColor6,

        border1: border1,
        border2: border2,
        border3: border3,
        border4: border4,

        button1: button1,
        button2: button2,
        button3: button3,

        opposite: opposite,

        status1: status1,
        status2: status2,
        status3: status3,

        line1: line1,
        line2: line2,
    },
    ...usedPalette,
};

export const theme = createMuiTheme({
    palette: newPalette,
    typography: {
        fontFamily: ['Bliss\\ 2', 'sans-serif'].join(','),
    },
    props: {},
    overrides: {},
    breakpoints: {
        values: {
            xs: 0,
            sm: 600,
            md: 960,
            lg: 1400,
            xl: 1920,
        },
    },
} as CustomTheme);

const cellTextColor: string = theme.palette.text.secondary;

theme.overrides = {
    MuiButton: {
        root: {
            textTransform: 'none',
        },
        contained: {
            borderRadius: '0px',

            fontSize: '1rem',
            boxShadow: '',
            color: theme.palette.getContrastText(theme.palette.background.default),
            backgroundColor: theme.palette.background.default,
        },
    },
    MuiTableCell: {
        root: {
            borderBottom: 'none',
            lineHeight: 1.3,
            color: cellTextColor,
            [theme.breakpoints.down('md')]: {
                padding: `${theme.spacing(1)}px ${theme.spacing(0.5)}px`,
            },
        },
        head: {
            color: cellTextColor,
        },
        body: {
            color: cellTextColor,
        },
        paddingNone: {
            paddingRight: theme.spacing(2),
        },
        stickyHeader: {
            backgroundColor: theme.palette.background.paper,
        },
    },
    MuiDialogTitle: {
        root: {
            padding: 'none',
        },
    },
    MuiDialogContent: {
        root: {
            paddingBottom: '1rem',
        },
    },
    MuiFormControl: {
        root: {
            backgroundColor: background3,
            border: '0.1px solid #393939',
            paddingLeft: '0.5rem',
        },
    },
    MuiFormControlLabel: {
        root: {
            paddingTop: '0.1rem',
        },
    },
    MuiGrid: {
        item: {
            paddingBottom: '.75rem',
        },
    },
    MuiCardHeader: {
        title: {
            fontSize: '2rem',
            fontWeight: 'bold',
        },
    },
    MuiCardContent: {
        root: {
            fontSize: '1.2rem',
        },
    },
    MuiInput: {
        underline: {
            '&&&:before': {
                borderBottom: 'none',
            },
            '&&&:after': {
                borderBottom: 'none',
            },
        },
        root: {
            color: theme.palette.text.secondary,
        },
    },
    MuiIconButton: {
        root: {
            '&&&:hover': {
                backgroundColor: 'transparent',
            },
            '&&&:active': {
                backgroundColor: 'transparent',
            }
        },
        label: {
            color: iconColor1,
        },
    },
    MuiRadio: {
        root: {
            padding: 0,
            margin: '0px 9px 0px 12px',
            backgroundColor: border1,
        },
    },
    MuiLink: {
        button: {
            fontFamily: theme.typography.fontFamily,
        },
    },
    MuiSelect: {
        root: {
            fontSize: '.875rem',
        },
    },
    MuiMenuItem: {
        root: {
            fontSize: '.875rem',
        },
    },
    MuiTypography: {
        root: {
            color: theme.palette.text.secondary
        }
    },
    MuiPaper: {
        root: {
            color: theme.palette.text.secondary
        }
    }
};
