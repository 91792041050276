import { EventCommentDto, EventDto } from "../../../../api/eventApi";
import { commentSectionGeneration, startTimeStringFormat } from "./commentGeneration";

let emailBody =
`Hello,

Please see the information below regarding COMET Event ID: **EVENT_ID**

Technology: **TECHNOLOGY**
Site: **SITE**
Asset Name: **ASSET_NAME**
Event Type: **EVENT_TYPE**
Start Time: **START_TIME**
Comment Section:
**COMMENT_SECTION**
Kind regards,
**CURRENT_USER**
This email was generated through COMET.`;

const changeFieldWithValue = (body: string, fields: Object) => {
    var result = body;
    Object
    .entries(fields)
    .forEach(([key, value]) => {
        result = result.replace(key, value);
    });
    return result;
};

const bodyGeneration = (event: EventDto, commentList: EventCommentDto[], currentUserName: string) => {
    const commentSection = commentSectionGeneration(commentList);
    const startTimeUTC = startTimeStringFormat(event.startTime);

    let fields = {
        '**EVENT_ID**': event.id.toString(),
        '**TECHNOLOGY**': event.technology,
        '**SITE**': event.siteName,
        '**ASSET_NAME**': event.assetName ?? 'No Asset Name',
        '**EVENT_TYPE**': event.eventType.toString(),
        '**START_TIME**': startTimeUTC,
        '**COMMENT_SECTION**': commentSection,
        '**CURRENT_USER**': currentUserName,
    };

    return changeFieldWithValue(emailBody, fields);
}

export { bodyGeneration };