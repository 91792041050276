import React, { useEffect } from 'react';
import { Checkbox, createStyles, Link, makeStyles, TableCell, TableRow } from '@material-ui/core';
import { getAssetForDisplay } from './helpers/event-helpers';
import { formatTypeForDisplay } from '../../../enums/eventType';
import { formatTime } from '../../reusable/formatTime';
import { EventTableAcknowledgeButton } from './eventTableAcknowledgeButton';
import { TableEvent } from './eventTable';
import { useDispatch } from 'react-redux';
import { setCheckedForId, setDurationForEvent } from '../../../state/eventsSlice';
import { CustomTheme } from '../../../styles/theme';
import { setEventId, setIsOpen } from '../../../state/eventPopupSlice';

type EventTableRowProps = {
    event: TableEvent;
    userCanEdit: boolean;
};

const useStyles = makeStyles((theme: CustomTheme) =>
    createStyles({
        detailsLink: {
            color: theme.palette.primary.light,
        },
        highlightedRow: {
            backgroundColor: theme.palette.extraColors.background2,
        },
        wordWrapTableCell: {
            whiteSpace: 'pre-wrap',
        },
    }),
);

export const formatDurationForDisplay = (durationSeconds: number): string => {
    var diffMinutes = durationSeconds % 60;
    var diffHours = Math.floor(durationSeconds / 60);

    let duration = '';
    if (diffHours > 0 && diffMinutes > 0) {
        duration = `${diffHours} hr ${diffMinutes} min`;
    } else if (diffHours > 0 && diffMinutes === 0) {
        duration = `${diffHours} hr`;
    } else {
        duration = `${diffMinutes} min`;
    }

    return duration;
};

const EventTableRow = React.memo(({ event, userCanEdit }: EventTableRowProps) => {
    const classes = useStyles();
    const dispatch = useDispatch();

    const openViewDetails = () => {
        dispatch(setIsOpen(true));
        dispatch(setEventId(event.id));
    };

    useEffect(() => {
        const updateDuration = () => {
            var startDate = new Date(event.startTime);
            var currentDate = new Date();

            var duration = Math.round(
                Math.abs((currentDate.getTime() - startDate.getTime()) / 60000),
            );

            dispatch(setDurationForEvent({ event, duration }));
        };

        updateDuration();
        const interval = setInterval(updateDuration, 10000);
        return () => clearInterval(interval);
    }, [dispatch, event, event.startTime]);

    return (
        <TableRow className={event.isChecked ? classes.highlightedRow : ''} hover>
            <TableCell>{event.id}</TableCell>
            <TableCell>{event.technology}</TableCell>
            <TableCell>{event.siteName}</TableCell>
            <TableCell>{event.gridName}</TableCell>
            <TableCell>{getAssetForDisplay(event)}</TableCell>
            <TableCell>{formatTime(event.startTime)}</TableCell>
            <TableCell>{formatDurationForDisplay(event.duration ?? 0)}</TableCell>
            <TableCell>{formatTypeForDisplay(event.eventType)}</TableCell>
            <TableCell className={classes.wordWrapTableCell}>
                {event.lastComment ?? 'N/A'}
            </TableCell>
            {userCanEdit && (
                <TableCell align="center">
                    <EventTableAcknowledgeButton event={event} />
                </TableCell>
            )}
            <TableCell align="center">
                <Link className={classes.detailsLink} onClick={openViewDetails} component="button">
                    View details
                </Link>
            </TableCell>
            <TableCell align="center">
                <Checkbox
                    onChange={() => dispatch(setCheckedForId(event.id))}
                    checked={event.isChecked}
                />
            </TableCell>
        </TableRow>
    );
});

export { EventTableRow };
