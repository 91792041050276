import React, { useCallback, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { useCreateRecurringEventMutation } from '../../../api/apiSlice';
import { MarketDto } from '../../../api/marketApi';
import { RecurringEventDto } from '../../../api/recurringEventApi';
import { ApiResponse } from '../../../api/sharedApi';
import { SiteDto } from '../../../api/siteApi';
import { EventType } from '../../../enums/eventType';
import { RecurrenceDay } from '../../../enums/recurrenceDay';
import { RecurrenceType } from '../../../enums/recurrenceType';
import { createOrUpdateRecurringEvent } from '../../../state/scheduledEventsSlice';
import { now } from '../../../utils/date-utils';
import { RecurringEventPopup } from './recurringEventPopup';

type CreateRecurringEventPopupProps = {
    handleClose: (newData: boolean) => void;
    siteList: SiteDto[];
    marketList: MarketDto[];
    technologyList: string[];
    filterMarketKey?: string;
};

const CreateRecurringEventPopup = ({
    handleClose,
    siteList,
    marketList,
    technologyList,
    filterMarketKey,
}: CreateRecurringEventPopupProps) => {
    const dispatch = useDispatch();

    const initialStartTime = useMemo(() => now(), []);
    const minuteMultiplier = 60000;
    const initialEndTime = useMemo(
        () => new Date(initialStartTime.getTime() + 120 * minuteMultiplier),
        [initialStartTime],
    );

    const [createRecurringEvent, { isLoading }] = useCreateRecurringEventMutation();

    const initialRecurringEvent: RecurringEventDto = useMemo(
        () => ({
            id: -1,
            startTime: initialStartTime,
            endTime: initialEndTime,

            eventType: EventType.CustomAction,
            code: '',
            message: '',

            siteKey: '',
            siteName: '',
            technology: '',

            assetKey: '',
            assetName: '',

            marketKey: `${filterMarketKey ? filterMarketKey : ''}`,
            marketName: `${
                filterMarketKey ? marketList.find((m) => m.key === filterMarketKey)?.name : ''
            }`,

            recurrence: RecurrenceType.Daily,
            dailyRecurrence: true,
            weeklyRecurrence: RecurrenceDay.None,
            monthlyRecurrence: 1,
        }),
        [filterMarketKey, initialEndTime, initialStartTime, marketList],
    );

    const createRecurringEventCallback = useCallback(
        async (recurringEvent: RecurringEventDto): Promise<ApiResponse<RecurringEventDto>> => {
            try {
                const createdEvent = await createRecurringEvent(recurringEvent).unwrap();
                dispatch(createOrUpdateRecurringEvent(createdEvent));
                return { data: createdEvent, isSuccess: true };
            } catch {
                return { data: undefined, isSuccess: false };
            }
        },
        [createRecurringEvent, dispatch],
    );

    return (
        <RecurringEventPopup
            handleClose={handleClose}
            siteList={siteList}
            marketList={marketList}
            technologyList={technologyList}
            initialRecurringEvent={initialRecurringEvent}
            submitButtonText="Add Recurring Event"
            submitAction={createRecurringEventCallback}
        />
    );
};

export { CreateRecurringEventPopup };
