import React, { useState } from 'react';
import { makeStyles, createStyles, TextField, Button, CircularProgress } from '@material-ui/core';
import { CustomTheme } from '../../../styles/theme';
import { commentPlaceholder } from './helpers/event-helpers';

const useStyles = makeStyles((theme: CustomTheme) =>
    createStyles({
        labelContent: {
            paddingTop: '0.5rem',
            paddingBottom: '0.5rem',
        },
        messageContent: {
            color: theme.palette.extraColors.iconColor3,
            paddingTop: '0.2rem',
            paddingBottom: '0.5rem',
        },
        apiButton: {
            backgroundColor: theme.palette.primary.main,
            color: theme.palette.extraColors.opposite,
            borderRadius: 0,
            height: '100%',
            width: '30%',
            padding: 1,
        },
        commentButton: {
            width: '100%',
        },
        floatRight: {
            float: 'right',
        },
        commentInputBox: {
            height: '100%',
            overflowX: 'hidden',
            overflowY: 'auto',
        },
        inputCap: {
            paddingLeft: 8,
            color: theme.palette.primary.main,
        },
        hidden: {
            display: 'none',
        },
    }),
);

const MAX_CHARACTERS = 1000;

type Props = {
    addComment: (input: string) => void;
    commentsMessage?: string;
    loading?: boolean;
};

const EventNewComment = ({ addComment, commentsMessage, loading = false }: Props) => {
    const [newComment, setNewComment] = useState(commentPlaceholder);
    const [isAtCharacterLimit, setIsAtCharacterLimit] = useState(false);

    const classes = useStyles();
    return (
        <div>
            <div className={classes.labelContent}>
                <TextField
                    className={classes.commentInputBox}
                    fullWidth
                    multiline
                    rowsMax={7}
                    rows={4}
                    value={newComment}
                    onChange={(e) => {
                        var input = e.target.value;
                        setIsAtCharacterLimit(input.length >= MAX_CHARACTERS);
                        setNewComment(input);
                    }}
                    inputProps={{ maxLength: MAX_CHARACTERS }}
                    disabled={loading}
                />
            </div>
            <span
                id="inputCapMessage"
                className={`${classes.inputCap} ${isAtCharacterLimit ? '' : classes.hidden}`}
            >
                {MAX_CHARACTERS} character limit reached
            </span>
            <div className={classes.floatRight}>
                <div>
                    {loading ? (
                        <CircularProgress data-testid="loading" />
                    ) : (
                        <Button
                            className={classes.apiButton + ' ' + classes.commentButton}
                            onClick={() => {
                                setNewComment('');
                                addComment(newComment);
                            }}
                            disabled={!newComment}
                        >
                            Post
                        </Button>
                    )}
                </div>
                <div className={classes.messageContent}>{commentsMessage}</div>
            </div>
        </div>
    );
};

export { EventNewComment };
