import * as signalR from '@microsoft/signalr';
import { useEffect, useState } from 'react';
import { BASE_API_URL } from '../api/sharedApi';
import { authProvider } from '../auth/authProvider';
import { now } from '../utils/date-utils';

type ConnectionStatus = 'Online' | 'Offline' | 'Connecting';

type ConnectionResponse = {
    status: ConnectionStatus;
    connection?: signalR.HubConnection;
    lastReconnectionTime?: Date | undefined;
};

const useSignalRConnection = (): ConnectionResponse => {
    const [status, setStatus] = useState<ConnectionStatus>('Offline');
    const [lastReconnectionTime, setLastReconnectionTime] = useState<Date | undefined>(undefined);
    const [connection, setConnection] = useState<signalR.HubConnection | undefined>(undefined);

    useEffect(() => {
        let retryFailedConnectionTimeout: NodeJS.Timeout;
        const connection = new signalR.HubConnectionBuilder()
            .withUrl(`${BASE_API_URL}/signalr`, {
                accessTokenFactory: async () => {
                    const token = await authProvider.getAccessToken();
                    return token.accessToken;
                },
            })
            .withAutomaticReconnect({
                nextRetryDelayInMilliseconds: (retryContext) => Math.random() * 8000 + 2000,
            })
            .build();

        setConnection(connection);

        const startConnection = () => {
            setStatus('Connecting');
            connection
                .start()
                .then(() => {
                    setStatus('Online');
                    setLastReconnectionTime(now());
                })
                .catch((err) => {
                    setStatus('Offline');

                    retryFailedConnectionTimeout = setTimeout(() => startConnection(), 10000);
                });
        };
        startConnection();

        connection.onreconnecting(() => {
            setStatus('Connecting');
        });
        connection.onreconnected(() => {
            setLastReconnectionTime(new Date());
            setStatus('Online');
        });
        connection.onclose(() => {
            setStatus('Offline');
        });
        return () => {
            clearTimeout(retryFailedConnectionTimeout);
            connection.stop();
        };
    }, []);

    return { status, connection, lastReconnectionTime };
};

type UseSignalREventProps<T> = {
    eventType: string;
    action: (payload: T) => void;
    connection?: signalR.HubConnection;
};

const useSignalREvent = <T>({ eventType, action, connection }: UseSignalREventProps<T>) => {
    useEffect(() => {
        if (!connection) {
            return;
        }
        connection.on(eventType, action);

        return () => connection.off(eventType);
    }, [action, connection, eventType]);
};

export { useSignalRConnection, useSignalREvent };
