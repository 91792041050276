import LinearProgress from '@material-ui/core/LinearProgress';
import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useGetEventsQuery } from '../../../api/apiSlice';
import { useCometSignalRConnections } from '../../../hooks/useCometSignalRConnections';
import { getTableEvents, setEvents } from '../../../state/eventsSlice';
import { EventTable } from './eventTable';

type EventTableContainerProps = {
    marketKey: string;
    monitoringGroupId: number;
};

const EventTableContainer = ({ marketKey, monitoringGroupId }: EventTableContainerProps) => {
    const dispatch = useDispatch();
    const [refreshReason, setRefreshReason] = useState<'reconnect' | 'data'>('data');

    const { status, lastReconnectionTime } = useCometSignalRConnections({
        marketKey,
        monitoringGroupId,
    });

    const reconnectionString: string | undefined = useMemo(
        () => lastReconnectionTime?.toString(),
        [lastReconnectionTime],
    );

    const events = useGetEventsQuery(
        { marketKey, monitoringGroupId, lastReconnectionTime: reconnectionString },
        { refetchOnMountOrArgChange: true },
    );

    const mappedEvents = useSelector(getTableEvents);

    useEffect(() => setRefreshReason('data'), [marketKey, monitoringGroupId]);
    useEffect(() => setRefreshReason('reconnect'), [reconnectionString]);

    useEffect(() => {
        dispatch(
            setEvents(
                events?.data?.map((e) => ({
                    ...e,
                    isChecked: false,
                    isAcknowledged: !!e.acknowledgedTime,
                })) ?? [],
            ),
        );
    }, [events?.data, dispatch]);

    if ((events.isLoading || events.isFetching) && refreshReason === 'data') {
        return <LinearProgress />;
    }

    return <EventTable events={mappedEvents} connectionStatus={status} />;
};

export { EventTableContainer };
