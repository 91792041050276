import { makeStyles, createStyles } from '@material-ui/core';
import { CustomTheme } from './theme';

export const useScheduledItemPopupStyles = makeStyles((theme: CustomTheme) =>
    createStyles({
        popupContent: {
            marginTop: 5,
            color: theme.palette.text.secondary,
        },
        itemString: {
            color: theme.palette.text.disabled,
            paddingBottom: '0.5rem',
            overflow: 'wrap',
        },
        numberInput: {
            width: 75,
        },
        valueSymbol: {
            marginLeft: -15,
            color: theme.palette.text.secondary,
        },
        expandIcons: {
            color: theme.palette.extraColors.iconColor1,
        },
        commentSpace: {
            height: 90,
            maxHeight: 90,
            overflowX: 'hidden',
            marginBottom: 5,
        },
        timezoneText: {
            paddingLeft: 10,
        },
        dateInputLabel: {
            paddingLeft: 10,
        },
        radioButtonSelector: {
            background: theme.palette.background.paper,
            borderColor: theme.palette.background.paper,
            borderRightColor: theme.palette.extraColors.button3,
        },
        radioButton: {
            marginBottom: 2,
            height: 30,
        },
    }),
);
