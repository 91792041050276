import React from 'react';
import { createStyles, IconButton, makeStyles, Tooltip } from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import { CustomTheme } from '../../../styles/theme';

const useStyles = makeStyles((theme: CustomTheme) =>
    createStyles({
        container: {
            display: 'flex',
            flexDirection: 'row',
        },
        editButton: {
            Size: 'small',
            color: '#E87511',
        },
        deleteButton: {
            Size:'small',
            color: '#E8112E',
        },
    }),
);

type ScheduledEventButtonActionsProps = {
    editAction?: () => void;
    deleteAction: () => void;
    editEnabled?: boolean;
};

const ScheduledEventButtonActions = ({
    editEnabled = false,
    editAction,
    deleteAction,
}: ScheduledEventButtonActionsProps) => {
    const classes = useStyles();

    return (
        <div className={classes.container}>
            {editEnabled && (
                <Tooltip title="Edit this event">
                    <IconButton onClick={editAction} >
                        <EditIcon className={classes.editButton} />
                    </IconButton>
                </Tooltip>
            )}
            <Tooltip title="Delete this event">
                <IconButton onClick={deleteAction} >
                    <DeleteIcon className={classes.deleteButton} />
                </IconButton>
            </Tooltip>
        </div>
    );
};

export { ScheduledEventButtonActions };
