import { createStyles, Grid, makeStyles } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { XAxis, CartesianGrid, Tooltip, Label, AreaChart, Area, Legend, YAxis } from 'recharts';
import { CustomTheme, border4, opposite } from '../../../styles/theme';
import CircularProgress from '@material-ui/core/CircularProgress';
import { getLiveData, LiveDataGeneric } from '../../../api/liveDataApi';
import { formatTime } from '../../reusable/formatTime';
import { subHours } from 'date-fns';
import {
    calculateTickTimes,
    FilterLiveData,
    formatXAxis,
    renderColorfulLegendText,
    sortLiveData,
    verifySourceTime,
} from '../../reusable/LiveChartHelpers';

const useStyles = makeStyles((theme: CustomTheme) =>
    createStyles({
        chart: {
            fontSize: 14,
        },
        labelColor: {
            fill: theme.palette.text.secondary,
        },
        loadingSpace: {
            width: '100%',
            height: 100,
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'center',
            paddingBottom: 25,
        },
        loadingText: {
            marginRight: 15,
        },
        loadingError: {
            color: theme.palette.primary.main,
            fontSize: 18,
        },
        leftColumn: {
            fontWeight: 'bold',
        },
        separator: {
            borderTop: `1px solid ${theme.palette.extraColors.border3}`,
        },
    }),
);

interface EventAlarmChartProps {
    assetKey: string;
    startTime: Date;
}

export const EventAlarmChart = (props: EventAlarmChartProps) => {
    const classes = useStyles();
    const { assetKey, startTime } = props;

    const [alarmData, setAlarmData] = useState<LiveDataGeneric[]>([]);
    const [alarmOptions, setAlarmOptions] = useState<string[]>([]);
    const [alarmTickTimes, setAlarmTickTimes] = useState<number[]>([]);

    const [hasPulledData, setHasPulledData] = useState(false);

    const [firstDataTime, setFirstDataTime] = useState<number>();
    const [lastDataTime, setLastDataTime] = useState<number>();

    const colorArray = [
        '#4477AA',
        '#CCBB44',
        '#EE6677',
        '#228833',
        '#BBBBBB',
        '#66CCEE',
        '#AA3377',
    ];

    useEffect(() => {
        const startTimeConst = subHours(new Date(startTime), 6);
        const endTimeConst = new Date();

        const fetchDataset = async () => {
            var formattedData: LiveDataGeneric[] = [];
            const result = await getLiveData(assetKey, 'ActiveAlarm', startTimeConst, endTimeConst);

            setFirstDataTime(startTimeConst.getTime());
            setLastDataTime(endTimeConst.getTime());

            if (result.data && result.data.length > 0) {
                var lastTime = 0;

                result.data.forEach((dataPoint, i) => {
                    var time = verifySourceTime(dataPoint.sourceTimestamp, startTimeConst);
                    var alarm = 'N/A';

                    if (time !== lastTime) {
                        if (dataPoint.value) {
                            alarm = dataPoint.value.toString();
                        }

                        formattedData.push({
                            time: time,
                            alarm: alarm,
                        });

                        lastTime = time;
                    }
                });
                formattedData.sort(sortLiveData);

                gatherAlarmOptions(formattedData);
            } else {
                setHasPulledData(true);
            }
        };

        const gatherAlarmOptions = (dataSet: LiveDataGeneric[]) => {
            var newAlarmOptions: string[] = [];

            for (var i = 0; i < dataSet.length; i++) {
                var alarm = dataSet[i].alarm;

                if (newAlarmOptions.indexOf(alarm) === -1) {
                    newAlarmOptions.push(alarm);
                }
            }

            setAlarmOptions(newAlarmOptions);
            FilterLiveData({
                dataSet: dataSet,
                dataFieldName: 'alarm',
                setData: setAlarmData,
            });
            setHasPulledData(true);
        };

        fetchDataset();
        //eslint-disable-next-line
    }, [assetKey, startTime]);

    useEffect(() => {
        if (firstDataTime && lastDataTime) {
            calculateTickTimes({
                startTime: firstDataTime,
                endTime: lastDataTime,
                setTickTimes: setAlarmTickTimes,
            });
        }
    }, [firstDataTime, lastDataTime]);

    const renderTooltipLabel = (label: string | number) => {
        var dateStr = formatTime(new Date(label));
        return dateStr;
    };

    const renderTooltipContent = (
        value: string | number | Array<string | number>,
        name: string,
    ) => {
        return [name, 'Alarm'];
    };

    return (
        <>
            {!hasPulledData || alarmData.length > 0 ? (
                <>
                    <Grid item xs={12} className={classes.separator}></Grid>
                    <Grid item xs={12} className={classes.leftColumn}>
                        Alarm History
                    </Grid>
                </>
            ) : (
                <></>
            )}

            <Grid item xs={12}>
                {hasPulledData && alarmData.length > 0 ? (
                    <AreaChart
                        width={910}
                        height={165}
                        data={alarmData}
                        margin={{
                            top: 0,
                            right: 85,
                            left: 85,
                            bottom: 0,
                        }}
                        className={classes.chart}
                    >
                        <CartesianGrid stroke={border4} />
                        <XAxis
                            dataKey="time"
                            tickLine={false}
                            ticks={alarmTickTimes}
                            type="number"
                            axisLine={false}
                            domain={['dataMin', 'dataMax']}
                            tickFormatter={formatXAxis}
                            minTickGap={15}
                        >
                            <Label
                                value="Time(UTC)"
                                position="bottom"
                                offset={-3}
                                className={classes.labelColor}
                            ></Label>
                        </XAxis>
                        <YAxis ticks={[0, 1]} hide={true}></YAxis>
                        <Legend
                            wrapperStyle={{ paddingTop: 15 }}
                            formatter={renderColorfulLegendText}
                        />

                        <Tooltip
                            labelFormatter={renderTooltipLabel}
                            formatter={renderTooltipContent}
                            contentStyle={{ backgroundColor: opposite }}
                        />
                        {alarmOptions.map((option, index) => (
                            <Area
                                key={index}
                                type="stepAfter"
                                dataKey={option}
                                stackId={index}
                                stroke={colorArray[index % colorArray.length]}
                                fill={colorArray[index % colorArray.length]}
                            />
                        ))}
                    </AreaChart>
                ) : (
                    <>
                        {hasPulledData ? (
                            <></>
                        ) : (
                            <div className={classes.loadingSpace}>
                                <span className={classes.loadingText}>Loading Live Data</span>
                                <div>
                                    <CircularProgress />
                                </div>
                            </div>
                        )}
                    </>
                )}
            </Grid>
        </>
    );
};
