export enum RecurrenceDay {
    // Item keys are not in ascending order so that mapping on the recurrence selector maps
    // with Sunday as the first day
    None = 0,
    Sunday = 1 << 6,
    Monday = 1 << 0,
    Tuesday = 1 << 1,
    Wednesday = 1 << 2,
    Thursday = 1 << 3,
    Friday = 1 << 4,
    Saturday = 1 << 5,
}
