import React, { useEffect, useState } from 'react';
import {
    makeStyles,
    createStyles,
    Grid,
    TextField,
    MenuItem,
    FormControl,
    Select,
    Link,
} from '@material-ui/core';

import CloseIcon from '@material-ui/icons/Close';
import AddIcon from '@material-ui/icons/Add';
import { CustomTheme } from '../../styles/theme';
import { HeadCell } from './sortableTableHeader';
import { FilterOperator, formatOperatorForDisplay } from '../../enums/filterOperator';
import { PopupContainer } from './popupContainer';
import { selectMenuProp } from '../pages/exportEvents/exportEventsPage';

export interface FilterPair<T> {
    field: keyof T;
    operator: FilterOperator;
    value: any;
}

const useStyles = makeStyles((theme: CustomTheme) =>
    createStyles({
        popupContent: {
            marginTop: 5,
            color: theme.palette.text.secondary,
        },
        separator: {
            borderBottom: `1px solid ${theme.palette.extraColors.border3}`,
            marginBottom: 20,
        },
        linkArea: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-end',
        },
        addText: {
            lineHeight: '24px',
            marginRight: 5,
        },
        addIcon: {
            verticalAlign: 'bottom',
        },
        deleteText: {
            lineHeight: '24px',
            marginRight: 5,
            color: theme.palette.extraColors.button1,
        },
        deleteIcon: {
            verticalAlign: 'bottom',
            color: theme.palette.extraColors.button1,
        },
        errorMessage: {
            color: theme.palette.error.main,
        },
    }),
);

export type TableFilterPopupProps<T> = {
    handleClose: (newData: boolean) => void;
    filters: FilterPair<T>[];
    setFilters:
        | React.Dispatch<React.SetStateAction<FilterPair<T>[]>>
        | ((filters: FilterPair<T>[]) => void);
    filterOptions: HeadCell<T>[];
    titleText: string;
};

export function TableFilterPopup<T>(props: TableFilterPopupProps<T>) {
    const classes = useStyles();
    const { handleClose, filters, setFilters, filterOptions, titleText } = props;

    const [selectedField, setSelectedField] = useState<keyof T>(
        (filterOptions.length > 0 ? filterOptions[0].id : '') as keyof T,
    );
    const [selectedOperator, setSelectedOperator] = useState<FilterOperator>(
        FilterOperator.EqualTo,
    );
    const [selectedValue, setSelectedValue] = useState('');
    const [errorText, setErrorText] = useState('');

    useEffect(() => {
        if (filterOptions.length > 0) {
            setSelectedField(filterOptions[0].id as keyof T);
        }
    }, [filters, filterOptions]);

    const addFilter = () => {
        if (selectedValue.length === 0) {
            setErrorText('Error: Value cannot be empty');
            return;
        } else {
            setErrorText('');
        }
        var currentFilters = [...filters];
        currentFilters.push({
            field: selectedField,
            operator: selectedOperator,
            value: selectedValue,
        });
        setFilters(currentFilters);
        setSelectedValue('');
    };

    const removeFilter = (index: number) => {
        var currentFilters = [...filters];
        currentFilters.splice(index, 1);
        setFilters(currentFilters);
    };

    return (
        <PopupContainer
            titleText={titleText}
            isEditing={false}
            handleClose={handleClose}
            dataCopy={[]}
            popupSize={'sm'}
            hideButtons={true}
            getContent={() => {
                return (
                    <Grid
                        container
                        className={classes.popupContent}
                        id="areaToCopy"
                        alignItems="flex-start"
                    >
                        <Grid item xs={4}>
                            Field
                        </Grid>
                        <Grid item xs={3}>
                            Operator
                        </Grid>
                        <Grid item xs={3}>
                            Value
                        </Grid>
                        <Grid item xs={2}></Grid>
                        {filters.length > 0 ? (
                            <Grid item xs={12} className={classes.separator}></Grid>
                        ) : null}
                        {filters.map((filter, index) => {
                            return (
                                <Grid container key={index}>
                                    <Grid item xs={4}>
                                        {filterOptions.find((o) => o.id === filter.field)?.label ||
                                            filter.field}
                                    </Grid>
                                    <Grid item xs={3}>
                                        {formatOperatorForDisplay(
                                            FilterOperator[filter.operator as FilterOperator],
                                        )}
                                    </Grid>
                                    <Grid item xs={3}>
                                        {filter.value}
                                    </Grid>
                                    <Grid item xs={2} className={classes.linkArea}>
                                        <Link
                                            onClick={() => removeFilter(index)}
                                            component="button"
                                        >
                                            <span className={classes.deleteText}>Delete</span>
                                            <CloseIcon className={classes.deleteIcon} />
                                        </Link>
                                    </Grid>
                                </Grid>
                            );
                        })}
                        {filterOptions.length > 0 ? (
                            <Grid item xs={12} className={classes.separator}></Grid>
                        ) : null}
                        {filterOptions.length > 0 ? (
                            <Grid item xs={12}>
                                <Grid container>
                                    <Grid item xs={3}>
                                        <FormControl fullWidth={true}>
                                            <Select
                                                value={
                                                    filters.find((f) => f.field === selectedField)
                                                        ? ''
                                                        : selectedField
                                                }
                                                onChange={(e) => {
                                                    setSelectedField(e.target.value as keyof T);
                                                }}
                                                MenuProps={selectMenuProp}
                                            >
                                                {filterOptions.map((option) => {
                                                    return (
                                                        <MenuItem key={option.id} value={option.id}>
                                                            {option.label}
                                                        </MenuItem>
                                                    );
                                                })}
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={1}></Grid>
                                    <Grid item xs={2}>
                                        <FormControl fullWidth={true}>
                                            <Select
                                                value={selectedOperator}
                                                onChange={(e) => {
                                                    setSelectedOperator(
                                                        FilterOperator[
                                                            e.target.value as FilterOperator
                                                        ],
                                                    );
                                                }}
                                                MenuProps={selectMenuProp}
                                            >
                                                {Object.keys(FilterOperator).map(
                                                    (operator, index) => {
                                                        return (
                                                            <MenuItem key={index} value={operator}>
                                                                {formatOperatorForDisplay(
                                                                    FilterOperator[
                                                                        operator as FilterOperator
                                                                    ],
                                                                )}
                                                            </MenuItem>
                                                        );
                                                    },
                                                )}
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={1}></Grid>
                                    <Grid item xs={3}>
                                        <TextField
                                            value={selectedValue}
                                            onChange={(e) => {
                                                setSelectedValue(e.target.value as string);
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={2} className={classes.linkArea}>
                                        <Link onClick={addFilter} component="button">
                                            <span className={classes.addText}>Add</span>
                                            <AddIcon className={classes.addIcon} />
                                        </Link>
                                    </Grid>
                                </Grid>
                            </Grid>
                        ) : null}
                        {errorText.length > 0 && (
                            <Grid item xs={12}>
                                <div className={classes.errorMessage}>{errorText}</div>
                            </Grid>
                        )}
                    </Grid>
                );
            }}
        />
    );
}
