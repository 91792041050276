import { makeStyles, createStyles, Button } from '@material-ui/core';
import React, { useCallback, useState } from 'react';
import { CustomTheme } from '../../../styles/theme';
import { useDispatch } from 'react-redux';
import { acknowledgeStoreEvent, unacknowledgeStoreEvent } from '../../../state/eventsSlice';
import { useAcknowledgeEventMutation, useUnacknowledgeEventMutation } from '../../../api/apiSlice';
import { TableEvent } from './eventTable';
import LinearProgress from '@material-ui/core/LinearProgress';
import { now } from '../../../utils/date-utils';
import { AcknowledgementButton } from './acknowledgementButton';

const useStyles = makeStyles((theme: CustomTheme) =>
    createStyles({
        statusButton: {
            padding: 1,
            borderRadius: 20,
        },
    }),
);

type EventTableAcknowledgeButtonProps = {
    event: TableEvent;
};

const EventTableAcknowledgeButton = ({ event }: EventTableAcknowledgeButtonProps) => {
    const [isHovered, setIsHovered] = useState(false);
    const classes = useStyles();
    const dispatch = useDispatch();
    const [acknowledgeEvent, { isLoading: acknowledgeLoading }] = useAcknowledgeEventMutation();
    const [unacknowledgeEvent, { isLoading: unacknowledgeLoading }] =
        useUnacknowledgeEventMutation();

    const onClick = useCallback(() => {
        if (event.isAcknowledged) {
            // TODO: any errors here are not handled.
            // Ideally we should user a snackbar or something similar to give the user a specific error.
            unacknowledgeEvent(event.id)
                .unwrap()
                .then(() => {
                    dispatch(unacknowledgeStoreEvent(event));
                })
                .catch((err) => console.log(err));
        } else {
            acknowledgeEvent(event.id)
                .unwrap()
                .then(() => {
                    dispatch(acknowledgeStoreEvent({ ...event, acknowledgedTime: now() }));
                })
                .catch((err) => console.log(err));
        }
    }, [acknowledgeEvent, unacknowledgeEvent, dispatch, event]);

    if (acknowledgeLoading || unacknowledgeLoading) {
        return (
            <Button className={classes.statusButton} data-testid="sending-acknowledgement">
                <LinearProgress />
            </Button>
        );
    }

    return (
        <Button
            className={classes.statusButton}
            onClick={onClick}
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
        >
            <AcknowledgementButton
                type={event.isAcknowledged ? 'acknowledge' : 'unacknowledge'}
                isHovered={isHovered}
            />
        </Button>
    );
};

export { EventTableAcknowledgeButton };
