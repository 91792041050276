import { createStyles, Link, makeStyles, TableCell, TableRow, Tooltip } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { CustomTheme } from '../../../styles/theme';
import { AssetDto, SiteDto } from '../../../api/siteApi';
import CheckIcon from '@material-ui/icons/Check';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faThumbtack } from '@fortawesome/free-solid-svg-icons';
import { EventDto } from '../../../api/eventApi';
import { EventCategory } from '../../../enums/eventCategory';
import { EventType } from '../../../enums/eventType';
import { SiteWindAverageDto } from '../../../api/siteApi';

export const useRowStyles = makeStyles((theme: CustomTheme) =>
    createStyles({
        siteContainer: {
            borderBottom: `2px solid ${theme.palette.extraColors.border3}`,
        },
        siteRow: {
            display: 'flex',
            flexDirection: 'row',
            width: '100%',
            alignItems: 'center',
            fontSize: '12px',
            position: 'relative',

            paddingLeft: 23,
            paddingRight: 23,
            paddingTop: 10,
            paddingBottom: 10,
        },
        warningHighlight: {
            backgroundColor: theme.palette.extraColors.background5,
        },
        siteSection1: {
            minWidth: 150,
            maxWidth: 150,
            paddingRight: 20,
        },
        siteSection2: {
            minWidth: 20,
            maxWidth: 20,
            width: 20,
        },
        siteSection3: {
            display: 'flex',
            alignItems: 'center',
            flexWrap: 'wrap',
            flexGrow: 1,
        },
        windSpeedBox: {
            fontSize: 12,
            display: 'flex',
            justifyContent: 'center',
            marginTop: 3,
            width: 70,
        },
        lowSpeedBox: {
            backgroundColor: theme.palette.extraColors.background4,
            color: theme.palette.extraColors.opposite,
        },
        highSpeedBox: {
            backgroundColor: theme.palette.extraColors.button1,
            color: theme.palette.extraColors.opposite,
        },
        gridArray: {
            display: 'flex',
            flexWrap: 'wrap',
            marginLeft: '-5px',
        },
        gridSection: {
            paddingLeft: 35,
        },
        gridNameBox: {
            marginTop: 6,
            marginBottom: 8,
            marginLeft: 6,
            whiteSpace: 'nowrap',
        },
        gridNameText: {
            backgroundColor: theme.palette.extraColors.background4, 
            color: theme.palette.extraColors.text4,
            fontSize: 12,
            padding: '1px 4px',
        },
        gridAssets: {
            display: 'flex',
            flexWrap: 'wrap',
        },
        assetCircle: {
            borderRadius: '50%',
            width: 24,
            height: 24,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            fontSize: 10,
            marginLeft: 6,
            marginTop: 3,
            marginBottom: 3,
        },
        statusAvailable: {
            backgroundColor: theme.palette.extraColors.status1,
        },
        statusUnavailable: {
            backgroundColor: theme.palette.extraColors.button1,
            color: theme.palette.extraColors.text5,
        },
        statusService: {
            backgroundColor: theme.palette.extraColors.status2,
            color: theme.palette.extraColors.text3, 
        },
        statusNoData: {
            backgroundColor: theme.palette.extraColors.background7,
            color: theme.palette.extraColors.text3, 
        },
        checkmark: {
            color: theme.palette.extraColors.iconColor4,
            pointerEvents: 'none',
            fontSize: 16,
            marginTop: 3,
        },
        pinned: {
            color: theme.palette.extraColors.iconColor2,
            fontSize: 15,
        },
        unpinned: {
            color: theme.palette.extraColors.iconColor6, 
            fontSize: 15,
        },
        '@keyframes flicker': {
            from: {
                opacity: 1,
            },
            to: {
                opacity: 0,
            },
        },
        flicker: {
            animationName: '$flicker',
            animationDuration: '1s',
            animationIterationCount: 'infinite',
            animationDirection: 'alternate',
            animationTimingFunction: 'ease-in-out',
            animationPlayState: 'running',
        },
    }),
);

interface LiveStatusRowProps {
    site: SiteDto;
    windData: SiteWindAverageDto | undefined;
    isBottomRow: boolean;
    isPinned: boolean;
    pinSite: (siteKey: string) => void;
    assets: AssetDto[][];
    siteEvents: EventDto[];
}

interface liveAssetStatus {
    assetKey: string;
    status: number;
    hasUnacknowledgedEvent: boolean;
}

export const LiveStatusRow = (props: LiveStatusRowProps) => {
    const classes = useRowStyles();
    var { site, windData, isBottomRow, isPinned, pinSite, assets, siteEvents } = props;
    const [assetStatusArray, setAssetStatusArray] = useState<liveAssetStatus[]>([]);
    const [isTherePpaBreach, setIsTherePpaBreach] = useState(false);
    var grids = site.grids;

    const statusOptions = [
        classes.statusAvailable,
        classes.statusNoData,
        classes.statusService,
        classes.statusUnavailable,
    ];

    useEffect(() => {
        const checkAssetStatus = (assetKey: string, gridKey: string) => {
            var warningLevel = 0;

            var assetEvents = siteEvents.filter(
                (e) => e.category === EventCategory.Asset && e.assetKey === assetKey,
            );
            var gridEvents = siteEvents.filter(
                (e) => e.category === EventCategory.Grid && e.gridKey === gridKey,
            );

            var noCommsSite = siteEvents.find(
                (e) => e.category === EventCategory.Site && e.eventType === EventType.DataOutage,
            );
            var noCommsGrid = gridEvents.find((e) => e.eventType === EventType.DataOutage);
            var noCommsAsset = assetEvents.find((e) => e.eventType === EventType.DataOutage);
            var inService = assetEvents.find((e) => e.eventType === EventType.InService);
            var fault = assetEvents.find((e) => e.eventType === EventType.Fault);

            if (
                noCommsSite !== undefined ||
                noCommsGrid !== undefined ||
                noCommsAsset !== undefined
            ) {
                warningLevel = 1;
            }
            if (inService) {
                warningLevel = 2;
            }
            if (fault) {
                warningLevel = 3;
            }

            return warningLevel;
        };

        const checkForUnacknowledgedEvents = (
            assetKey: string,
            gridKey: string,
            siteKey: string,
        ) => {
            var unacknowledgedAssetEvents = siteEvents.filter(
                (e) =>
                    e.category === EventCategory.Asset &&
                    e.assetKey === assetKey &&
                    e.acknowledgedTime === null,
            );
            var unacknowledgedGridEvents = siteEvents.filter(
                (e) =>
                    e.category === EventCategory.Grid &&
                    e.gridKey === gridKey &&
                    e.acknowledgedTime === null,
            );
            var unacknowledgedSiteEvents = siteEvents
                .filter(
                    (e) =>
                        e.category === EventCategory.Site &&
                        e.siteKey === siteKey &&
                        e.acknowledgedTime === null,
                )
                .filter((e) => e.eventType !== EventType.PpaBreach);
            if (
                unacknowledgedAssetEvents.length > 0 ||
                unacknowledgedGridEvents.length > 0 ||
                unacknowledgedSiteEvents.length > 0
            ) {
                return true;
            }
            return false;
        };

        setAssetStatusArray([]);
        assets.forEach((grid) => {
            grid.forEach((asset) => {
                var assetStatus = checkAssetStatus(asset.key, asset.gridKey);
                var hasUnacknowledgedEvent = checkForUnacknowledgedEvents(
                    asset.key,
                    asset.gridKey,
                    asset.siteKey,
                );
                var newStatusObject: liveAssetStatus = {
                    assetKey: asset.key,
                    status: assetStatus,
                    hasUnacknowledgedEvent: hasUnacknowledgedEvent,
                };
                setAssetStatusArray((a) => [...a, newStatusObject]);
            });
        });

        const checkForPpaBreach = () => {
            var ppaEvents = siteEvents
                .filter((e) => e.category === EventCategory.Site)
                .filter((e) => e.eventType === EventType.PpaBreach);
            setIsTherePpaBreach(ppaEvents.length > 0);
        };
        checkForPpaBreach();
    }, [assets, siteEvents]);

    const formatWindForDisplay = (windSpeed: number) => {
        windSpeed = parseFloat(windSpeed.toFixed(2));
        var windString = windSpeed.toString();
        if (windSpeed % 1 === 0) {
            windString += '.0';
        }
        return `${windString} m/s`;
    };

    return (
        <TableRow className={isBottomRow ? '' : classes.siteContainer}>
            <TableCell
                className={`${classes.siteRow} ${isTherePpaBreach ? classes.warningHighlight : ''}`}
            >
                <div className={classes.siteSection1}>
                    <div>{site.name}</div>
                    {windData !== undefined && (
                        <div className={classes.windSpeedBox}>
                            <span
                                className={
                                    classes.windSpeedBox +
                                    ' ' +
                                    (windData.windAverage < 12
                                        ? classes.lowSpeedBox
                                        : `${classes.highSpeedBox} ${classes.flicker}`)
                                }
                            >
                                {formatWindForDisplay(windData.windAverage)}
                            </span>
                        </div>
                    )}
                </div>
                <div className={classes.siteSection2}>
                    <Link onClick={() => pinSite(site.key)} component="button">
                        <FontAwesomeIcon
                            icon={faThumbtack}
                            className={isPinned ? classes.pinned : classes.unpinned}
                        />
                    </Link>
                </div>
                <div className={classes.siteSection3}>
                    <div className={classes.gridArray}>
                        {grids.map((grid, index) => {
                            var gridAssets = assets[index];
                            return (
                                <div key={index} className={classes.gridSection}>
                                    {grids.length > 1 && (
                                        <div className={classes.gridNameBox}>
                                            <span className={classes.gridNameText}>
                                                {grid.name}
                                            </span>
                                        </div>
                                    )}
                                    {siteEvents && (
                                        <div className={classes.gridAssets}>
                                            {gridAssets.map((asset, index) => {
                                                var currentAsset = assetStatusArray.find(
                                                    (a) => a.assetKey === asset.key,
                                                );
                                                if (currentAsset !== undefined) {
                                                    return (
                                                        <Tooltip title={asset.name} key={index}>
                                                            <div
                                                                className={
                                                                    classes.assetCircle +
                                                                    ' ' +
                                                                    statusOptions[
                                                                        currentAsset.status
                                                                    ] +
                                                                    (currentAsset.hasUnacknowledgedEvent
                                                                        ? ` ${classes.flicker}`
                                                                        : '')
                                                                }
                                                            >
                                                                <span>
                                                                    {currentAsset.status === 0 ? (
                                                                        <CheckIcon
                                                                            className={
                                                                                classes.checkmark
                                                                            }
                                                                        />
                                                                    ) : (
                                                                        <span>
                                                                            {asset.shortName}
                                                                        </span>
                                                                    )}
                                                                </span>
                                                            </div>
                                                        </Tooltip>
                                                    );
                                                } else {
                                                    return null;
                                                }
                                            })}
                                        </div>
                                    )}
                                </div>
                            );
                        })}
                    </div>
                </div>
            </TableCell>
        </TableRow>
    );
};
