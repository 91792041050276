import { LinearProgress } from '@material-ui/core';
import React from 'react';
import { useSelector } from 'react-redux';
import { getMarketKeySelector, getMonitoringGroupSelector } from '../../../state/rootReducerSlice';
import { EventTableContainer } from './eventTableContainer';

const EventPageContainer = () => {
    const marketKey = useSelector(getMarketKeySelector);
    const monitoringGroupId = useSelector(getMonitoringGroupSelector);

    if (marketKey === undefined || monitoringGroupId === undefined) {
        return (
            <>
                <LinearProgress />
            </>
        );
    }

    return <EventTableContainer marketKey={marketKey} monitoringGroupId={monitoringGroupId} />;
};

export { EventPageContainer };
