import { createStyles, Grid, makeStyles } from '@material-ui/core';
import React, { useEffect, useRef, useState } from 'react';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, Label } from 'recharts';
import {
    CustomTheme,
    line1,
    line2,
    background6,
    border4,
    opposite,
    theme,
} from '../../../styles/theme';
import CircularProgress from '@material-ui/core/CircularProgress';
import { getLiveData, LiveDataScada } from '../../../api/liveDataApi';
import { formatTime } from '../../reusable/formatTime';
import { subHours } from 'date-fns';
import {
    calculateTickTimes,
    formatXAxis,
    renderColorfulLegendText,
    sortLiveData,
    verifySourceTime,
} from '../../reusable/LiveChartHelpers';

const useStyles = makeStyles((theme: CustomTheme) =>
    createStyles({
        chart: {
            fontSize: 14,
        },
        labelColor: {
            fill: theme.palette.text.secondary,
        },
        loadingSpace: {
            width: '100%',
            height: 100,
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'center',
            paddingBottom: 25,
        },
        loadingText: {
            marginRight: 15,
        },
        loadingError: {
            color: theme.palette.primary.main,
            fontSize: 18,
        },
        leftColumn: {
            fontWeight: 'bold',
        },
        separator: {
            borderTop: `1px solid ${theme.palette.extraColors.border3}`,
        },
    }),
);

enum Technologies {
    Wind = 'Wind',
    Solar = 'Photovoltaic',
}

interface EventScadaChartProps {
    technology: string;
    assetKey: string;
    startTime: Date;
}

enum DataGatheringSteps {
    Power = 'Power',
    Alternate = 'Alternate',
}

export const EventScadaChart = (props: EventScadaChartProps) => {
    const classes = useStyles();
    const { technology, assetKey, startTime } = props;

    const horizontalLines = 10;

    const [chartData, setChartData] = useState<LiveDataScada[]>([]);
    const [firstDataTime, setFirstDataTime] = useState<number>();
    const [lastDataTime, setLastDataTime] = useState<number>();
    const [liveTickTimes, setLiveTickTimes] = useState<number[]>([]);

    const [hasPulledData, setHasPulledData] = useState(false);
    const [line1Unit, setLine1Unit] = useState<string | undefined>(undefined);
    const [line2Unit, setLine2Unit] = useState<string | undefined>(undefined);

    var line1Label = useRef<string | undefined>();
    var line1Source = useRef<string | undefined>();
    var line2Label = useRef<string | undefined>();
    var line2Source = useRef<string | undefined>();

    useEffect(() => {
        const maxDatapoints = 50;
        const startTimeConst = subHours(new Date(startTime), 6);
        const endTimeConst = new Date();

        const fetchDataset = async (
            tech: string,
            existingData: LiveDataScada[],
            dataGatheringStep: DataGatheringSteps,
        ) => {
            var dataLabel = '';
            var dataSource = '';
            if (tech === 'Power') {
                dataLabel = 'Power';
                dataSource = 'power';
            } else {
                switch (tech) {
                    case 'Windspeed':
                        dataLabel = 'Wind Speed';
                        dataSource = 'windSpeed';
                        break;
                    case 'Irradiance':
                        dataLabel = 'Irradiance';
                        dataSource = 'irradiance';
                        break;
                }
            }

            const result = await getLiveData(assetKey, tech, startTimeConst, endTimeConst);

            setFirstDataTime(startTimeConst.getTime());
            setLastDataTime(endTimeConst.getTime());

            if (result.data && result.data.length > 0) {
                result.data.forEach((dataPoint, index) => {
                    if (line1Label.current === undefined) {
                        var time = verifySourceTime(dataPoint.sourceTimestamp, startTimeConst);
                        var power = 0;
                        var irradiance = 0;
                        var windSpeed = 0;
                        switch (tech) {
                            case 'Power':
                                power = parseFloat(dataPoint?.value?.toString());
                                break;
                            case 'Windspeed':
                                windSpeed = parseFloat(dataPoint?.value?.toString());
                                break;
                            case 'Irradiance':
                                irradiance = parseFloat(dataPoint?.value?.toString());
                                break;
                        }
                        existingData.push({
                            time: time,
                            power: power,
                            windSpeed: windSpeed,
                            irradiance: irradiance,
                        });
                    } else {
                        if (dataPoint.value) {
                            switch (tech) {
                                case 'Windspeed':
                                    existingData[index].windSpeed = parseFloat(
                                        dataPoint.value.toString(),
                                    );
                                    break;
                                case 'Irradiance':
                                    existingData[index].irradiance = parseFloat(
                                        dataPoint.value.toString(),
                                    );
                                    break;
                            }
                        }
                    }
                });

                if (
                    dataGatheringStep === DataGatheringSteps.Power ||
                    dataGatheringStep === DataGatheringSteps.Alternate
                ) {
                    if (line1Label.current === undefined) {
                        line1Label.current = dataLabel;
                        line1Source.current = dataSource;
                        setLine1Unit(result.data[0].unit);
                    } else {
                        line2Label.current = dataLabel;
                        line2Source.current = dataSource;
                        setLine2Unit(result.data[0].unit);
                    }
                }
            }

            if (dataGatheringStep === DataGatheringSteps.Power) {
                switch (technology) {
                    case Technologies.Wind:
                        fetchDataset('Windspeed', existingData, DataGatheringSteps.Alternate);
                        break;
                    case Technologies.Solar:
                        fetchDataset('Irradiance', existingData, DataGatheringSteps.Alternate);
                        break;
                    default:
                        filterDataForChart(existingData);
                }
            } else {
                filterDataForChart(existingData);
            }
        };

        const filterDataForChart = (dataSet: LiveDataScada[]) => {
            var filteredChartData: LiveDataScada[] = [];
            var runningSumPower = 0;
            var runningSumOther = 0;
            var runningCount = 0;

            var length = dataSet.length;
            var nextIndex = length / maxDatapoints - 1;
            runningSumPower = 0;
            runningCount = 0;

            dataSet.forEach((dataPoint, index) => {
                switch (technology) {
                    case Technologies.Wind:
                        runningSumOther += dataPoint.windSpeed;
                        break;
                    case Technologies.Solar:
                        runningSumOther += dataPoint.irradiance;
                        break;
                }
                runningSumPower += dataPoint.power;
                runningCount++;

                if (index >= nextIndex) {
                    var power = parseFloat((runningSumPower / runningCount).toFixed(3));
                    var windSpeed = 0;
                    var irradiance = 0;
                    switch (technology) {
                        case Technologies.Wind:
                            windSpeed = parseFloat((runningSumOther / runningCount).toFixed(3));
                            break;
                        case Technologies.Solar:
                            irradiance = parseFloat((runningSumOther / runningCount).toFixed(3));
                            break;
                    }

                    filteredChartData.push({
                        time: dataPoint.time,
                        power: power,
                        windSpeed: windSpeed,
                        irradiance: irradiance,
                    });

                    nextIndex = nextIndex + length / maxDatapoints;
                    runningSumPower = 0;
                    runningSumOther = 0;
                    runningCount = 0;
                }
            });

            filteredChartData.sort(sortLiveData);
            setChartData(filteredChartData);
            setHasPulledData(true);
        };

        var fullDataSet: LiveDataScada[] = [];
        fetchDataset('Power', fullDataSet, DataGatheringSteps.Power);
        //eslint-disable-next-line
    }, [assetKey, technology, startTime]);

    useEffect(() => {
        if (firstDataTime && lastDataTime) {
            calculateTickTimes({
                startTime: firstDataTime,
                endTime: lastDataTime,
                setTickTimes: setLiveTickTimes,
            });
        }
    }, [firstDataTime, lastDataTime]);

    const renderTooltipLabel = (label: string | number) => {
        var date = new Date(label);
        return formatTime(date);
    };

    return (
        <>
            <Grid item xs={12} className={classes.separator}></Grid>
            <Grid item xs={12} className={classes.leftColumn}>
                Live SCADA Signals
            </Grid>
            <Grid item xs={12}>
                {hasPulledData &&
                line1Label.current !== undefined &&
                line1Source.current !== undefined ? (
                    <>
                        {line2Label.current !== undefined && line2Source.current !== undefined ? (
                            <LineChart
                                width={910}
                                height={350}
                                data={chartData}
                                margin={{
                                    top: 0,
                                    right: 25,
                                    left: 25,
                                    bottom: 0,
                                }}
                                className={classes.chart}
                            >
                                <CartesianGrid fill={background6} stroke={border4} />
                                <Tooltip
                                    labelFormatter={renderTooltipLabel}
                                    labelStyle={{ color: theme.palette.text.secondary }}
                                    contentStyle={{ backgroundColor: opposite }}
                                />
                                <Legend
                                    wrapperStyle={{ paddingTop: 15 }}
                                    formatter={renderColorfulLegendText}
                                />
                                <XAxis
                                    dataKey="time"
                                    tickLine={false}
                                    ticks={liveTickTimes}
                                    type="number"
                                    axisLine={false}
                                    domain={['dataMin', 'dataMax']}
                                    tickFormatter={formatXAxis}
                                    minTickGap={15}
                                >
                                    <Label
                                        value="Time(UTC)"
                                        position="bottom"
                                        offset={-3}
                                        className={classes.labelColor}
                                    ></Label>
                                </XAxis>

                                <YAxis
                                    yAxisId="left"
                                    dataKey={line1Source.current}
                                    orientation="left"
                                    tickLine={false}
                                    tickCount={horizontalLines}
                                    type="number"
                                    axisLine={false}
                                >
                                    <Label
                                        value={`${line1Label.current} (${line1Unit})`}
                                        position="left"
                                        angle={-90}
                                        className={classes.labelColor}
                                        dy={-40}
                                        offset={0}
                                    ></Label>
                                </YAxis>
                                <Line
                                    dataKey={line1Source.current}
                                    name={`${line1Label.current} (${line1Unit})`}
                                    stroke={line1}
                                    strokeWidth={2}
                                    yAxisId="left"
                                />

                                <YAxis
                                    yAxisId="right"
                                    dataKey={line2Source.current}
                                    orientation="right"
                                    tickLine={false}
                                    tickCount={horizontalLines}
                                    type="number"
                                    axisLine={false}
                                >
                                    <Label
                                        value={`${line2Label.current} (${line2Unit})`}
                                        position="right"
                                        angle={-90}
                                        className={classes.labelColor}
                                        dy={23}
                                        offset={0}
                                    ></Label>
                                </YAxis>
                                <Line
                                    dataKey={line2Source.current}
                                    name={`${line2Label.current} (${line2Unit})`}
                                    stroke={line2}
                                    strokeWidth={2}
                                    yAxisId="right"
                                />
                            </LineChart>
                        ) : (
                            <LineChart
                                width={910}
                                height={350}
                                data={chartData}
                                margin={{
                                    top: 0,
                                    right: 25,
                                    left: 25,
                                    bottom: 0,
                                }}
                                className={classes.chart}
                            >
                                <CartesianGrid fill={background6} stroke={border4} />
                                <Tooltip
                                    labelFormatter={renderTooltipLabel}
                                    labelStyle={{ color: theme.palette.text.secondary }}
                                    contentStyle={{ backgroundColor: opposite }}
                                />
                                <Legend
                                    wrapperStyle={{ paddingTop: 15 }}
                                    formatter={renderColorfulLegendText}
                                />

                                <XAxis
                                    dataKey="time"
                                    tickLine={false}
                                    ticks={liveTickTimes}
                                    type="number"
                                    axisLine={false}
                                    domain={['dataMin', 'dataMax']}
                                    tickFormatter={formatXAxis}
                                    minTickGap={15}
                                >
                                    <Label
                                        value="Time(UTC)"
                                        position="bottom"
                                        offset={-3}
                                        className={classes.labelColor}
                                    ></Label>
                                </XAxis>

                                <YAxis
                                    yAxisId="left"
                                    dataKey={line1Source.current}
                                    orientation="left"
                                    tickLine={false}
                                    tickCount={horizontalLines}
                                    type="number"
                                    axisLine={false}
                                >
                                    <Label
                                        value={`${line1Label.current} (${line1Unit})`}
                                        position="left"
                                        angle={-90}
                                        className={classes.labelColor}
                                        dy={-40}
                                        offset={0}
                                    ></Label>
                                </YAxis>
                                <Line
                                    dataKey={line1Source.current}
                                    name={`${line1Label.current} (${line1Unit})`}
                                    stroke={line1}
                                    strokeWidth={2}
                                    yAxisId="left"
                                />
                            </LineChart>
                        )}
                    </>
                ) : (
                    <div className={classes.loadingSpace}>
                        {hasPulledData ? (
                            <div className={classes.loadingError}>
                                Error: No live data available
                            </div>
                        ) : (
                            <>
                                <span className={classes.loadingText}>Loading Live Data</span>
                                <div>
                                    <CircularProgress />
                                </div>
                            </>
                        )}
                    </div>
                )}
            </Grid>
        </>
    );
};
