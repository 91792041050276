import { NamedEntity } from '../utils/array-utils';
import { BASE_API_URL, HttpMethod, httpRequest, ApiResponse } from './sharedApi';

const MONITORING_URL = `${BASE_API_URL}/MonitoringGroups`;

export type MonitoringGroupDto = {
    id: number;
    notificationEmails: string;
    siteKeys: string[];
} & NamedEntity;

export const getAllMonitoringGroups = (): Promise<ApiResponse<MonitoringGroupDto[]>> => {
    return httpRequest<MonitoringGroupDto[]>(MONITORING_URL, HttpMethod.GET);
};

export const getMonitoringGroupById = (id: number): Promise<ApiResponse<MonitoringGroupDto>> => {
    return httpRequest<MonitoringGroupDto>(`${MONITORING_URL}/${id}`, HttpMethod.GET);
};

export const createMonitoringGroup = (
    group: MonitoringGroupDto,
): Promise<ApiResponse<MonitoringGroupDto>> => {
    return httpRequest<MonitoringGroupDto>(MONITORING_URL, HttpMethod.POST, group);
};

export const updateMonitoringGroup = (
    group: MonitoringGroupDto,
): Promise<ApiResponse<MonitoringGroupDto>> => {
    return httpRequest<MonitoringGroupDto>(`${MONITORING_URL}/${group.id}`, HttpMethod.PUT, group);
};

export const deleteMonitoringGroup = (group: MonitoringGroupDto): Promise<ApiResponse<void>> => {
    return httpRequest(`${MONITORING_URL}/${group.id}`, HttpMethod.DELETE);
};
