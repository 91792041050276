import React, { useState } from 'react';
import {
    makeStyles,
    createStyles,
    Grid,
    Select,
    MenuItem,
    FormControl,
    TextField,
} from '@material-ui/core';
import { CustomTheme } from '../../../../styles/theme';
import { PpaConditionDto } from '../../../../api/ppaConditionApi';
import {
    FieldOptions,
    ConditionOptions,
    createPPACondition,
    deletePPACondition,
    updatePPACondition,
} from '../../../../api/ppaConditionApi';
import { SiteDto } from '../../../../api/siteApi';
import ExpandMore from '@material-ui/icons/ExpandMore';
import { PopupContainer } from '../../../reusable/popupContainer';
import { selectMenuProp } from '../../exportEvents/exportEventsPage';

const useStyles = makeStyles((theme: CustomTheme) =>
    createStyles({
        popupContent: {
            marginTop: 5,
            color: theme.palette.text.secondary,
        },
        eventString: {
            color: theme.palette.text.disabled,
            paddingBottom: '0.5rem',
            overflow: 'wrap',
        },
        numberInput: {
            width: 75,
        },
        valueSymbol: {
            marginLeft: -15,
            color: theme.palette.text.secondary,
        },
        expandIcons: {
            color: theme.palette.extraColors.iconColor1,
            position: 'absolute',
            right: 0,
            top: 'calc(50% - 12px)',
            pointerEvents: 'none',
        },
    }),
);

export const formatConditionForDisplay = (condition: ConditionOptions, value: number | null) => {
    var conditionDisplay = condition.toString();
    if (
        condition === ConditionOptions.LessThanOrEqual ||
        condition === ConditionOptions.GreaterThanOrEqual ||
        condition === ConditionOptions.Equal
    ) {
        conditionDisplay += 'To';
    }
    conditionDisplay = conditionDisplay.replace(/([A-Z])/g, ' $1').trim() + ' ';
    conditionDisplay = conditionDisplay.toLowerCase();
    conditionDisplay = conditionDisplay.charAt(0).toUpperCase() + conditionDisplay.slice(1);

    if (value === null) {
        return conditionDisplay;
    } else {
        return conditionDisplay + value;
    }
};

export const formatFieldForDisplay = (field: FieldOptions, displaySymbol: boolean) => {
    var fieldDisplay = field.toString();
    fieldDisplay = fieldDisplay.replace(/([A-Z])/g, ' $1').trim() + ' ';
    if (displaySymbol) {
        fieldDisplay += `(${getUnitForField(FieldOptions[field as FieldOptions])})`;
    }
    return fieldDisplay;
};

export const getUnitForField = (field: FieldOptions) => {
    /*if (field === FieldOptions.Power) {
        return 'MW';
    } else if (field === FieldOptions.ReactivePower) {
        return 'var';
    } else */ if (
        field === FieldOptions.Capacity
    ) {
        return '%';
    } else {
        return '';
    }
};

interface PpaEventPopupProps {
    handleClose: (newData: boolean) => void;
    currentData: PpaConditionDto | null;
    siteList: SiteDto[];
}

export const PpaEventPopup = (props: PpaEventPopupProps) => {
    const classes = useStyles();
    const { handleClose, currentData } = props;
    var dataCopy: PpaConditionDto;
    if (currentData === null) {
        dataCopy = {
            siteKey: props.siteList ? props.siteList[0].key : '',
            siteName: props.siteList ? props.siteList[0].name : '',
            id: -1,
            field: FieldOptions.Capacity,
            eventCondition: ConditionOptions.LessThanOrEqual,
            minimumDuration: 0,
            value: 0,
        };
    } else {
        dataCopy = currentData;
    }

    const [updatedData, setUpdatedData] = useState(dataCopy);

    const getPPAConditionStringForDisplay = () => {
        return (
            'A PPA Event will be created for ' +
            updatedData.siteName +
            ' when the ' +
            formatFieldForDisplay(updatedData.field, false).toLowerCase() +
            ' is ' +
            formatConditionForDisplay(
                updatedData.eventCondition,
                +updatedData.value,
            ).toLowerCase() +
            getUnitForField(FieldOptions[updatedData.field as FieldOptions]) +
            ' for a duration of ' +
            updatedData.minimumDuration +
            ' hours'
        );
    };

    return (
        <PopupContainer
            titleText={currentData ? 'Edit Condition' : 'Add Condition'}
            isEditing={currentData ? true : false}
            handleClose={handleClose}
            addFunction={createPPACondition}
            updateFunction={updatePPACondition}
            deleteFunction={deletePPACondition}
            dataCopy={updatedData}
            getContent={() => {
                return (
                    <Grid container className={classes.popupContent} alignItems="center">
                        <Grid item xs={5}>
                            Site
                        </Grid>
                        <Grid item xs={7}>
                            <FormControl fullWidth={true}>
                                <Select
                                    value={updatedData.siteKey}
                                    onChange={(e) => {
                                        var key = e.target.value as string;
                                        var site = props.siteList.find((e) => e.key === key);
                                        if (site) {
                                            setUpdatedData({
                                                ...updatedData,
                                                siteName: site.name,
                                                siteKey: key,
                                            });
                                        } else {
                                            console.log('Error: site not found');
                                        }
                                    }}
                                    IconComponent={() => (
                                        <ExpandMore className={classes.expandIcons} />
                                    )}
                                    MenuProps={selectMenuProp}
                                >
                                    {updatedData.siteKey !== '' &&
                                        !props.siteList.find(
                                            (site) => site.key === updatedData.siteKey,
                                        ) && (
                                            <MenuItem
                                                key={updatedData.siteKey}
                                                value={updatedData.siteKey}
                                            >
                                                {updatedData.siteName}
                                            </MenuItem>
                                        )}
                                    {props.siteList.map((site) => {
                                        return (
                                            <MenuItem key={site.key} value={site.key}>
                                                {site.name}
                                            </MenuItem>
                                        );
                                    })}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={5}>
                            Field
                        </Grid>
                        <Grid item xs={7}>
                            <FormControl fullWidth={true}>
                                <Select
                                    value={updatedData.field}
                                    onChange={(e) => {
                                        setUpdatedData({
                                            ...updatedData,
                                            field: FieldOptions[e.target.value as FieldOptions],
                                        });
                                    }}
                                    IconComponent={() => (
                                        <ExpandMore className={classes.expandIcons} />
                                    )}
                                    MenuProps={selectMenuProp}
                                >
                                    {Object.keys(FieldOptions).map((field, index) => {
                                        return (
                                            <MenuItem key={index} value={field}>
                                                {formatFieldForDisplay(
                                                    FieldOptions[field as FieldOptions],
                                                    true,
                                                )}
                                            </MenuItem>
                                        );
                                    })}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={5}>
                            Condition
                        </Grid>
                        <Grid item xs={7}>
                            <FormControl fullWidth={true}>
                                <Select
                                    value={updatedData.eventCondition}
                                    onChange={(e) => {
                                        setUpdatedData({
                                            ...updatedData,
                                            eventCondition:
                                                ConditionOptions[
                                                    e.target.value as ConditionOptions
                                                ],
                                        });
                                    }}
                                    IconComponent={() => (
                                        <ExpandMore className={classes.expandIcons} />
                                    )}
                                    MenuProps={selectMenuProp}
                                >
                                    {Object.keys(ConditionOptions).map((condition, index) => {
                                        return (
                                            <MenuItem key={index} value={condition}>
                                                {formatConditionForDisplay(
                                                    ConditionOptions[condition as ConditionOptions],
                                                    null,
                                                )}
                                            </MenuItem>
                                        );
                                    })}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={5}>
                            Value
                        </Grid>
                        <Grid item xs={3}>
                            <TextField
                                className={classes.numberInput}
                                type="number"
                                value={updatedData.value.toString()}
                                onChange={(e) => {
                                    setUpdatedData({
                                        ...updatedData,
                                        value: +(e.target.value as string),
                                    });
                                }}
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <span className={classes.valueSymbol}>
                                {getUnitForField(FieldOptions[updatedData.field as FieldOptions])}
                            </span>
                        </Grid>
                        <Grid item xs={5}>
                            Minimum Duration
                        </Grid>
                        <Grid item xs={3}>
                            <TextField
                                className={classes.numberInput}
                                type="number"
                                value={updatedData.minimumDuration.toString()}
                                onChange={(e) => {
                                    setUpdatedData({
                                        ...updatedData,
                                        minimumDuration: +(e.target.value as string),
                                    });
                                }}
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <span className={classes.valueSymbol}>hours</span>
                        </Grid>
                        <Grid item xs={12}>
                            <div className={classes.eventString}>
                                {getPPAConditionStringForDisplay()}
                            </div>
                        </Grid>
                    </Grid>
                );
            }}
        />
    );
};
