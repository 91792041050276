import { authProvider } from '../auth/authProvider';

export interface ApiResponse<T> {
    isSuccess: boolean;
    message?: string;
    data?: T;
}

export enum HttpMethod {
    POST = 'POST',
    GET = 'GET',
    PUT = 'PUT',
    PATCH = 'PATCH',
    DELETE = 'DELETE',
}

export const BASE_API_URL: string = process.env.REACT_APP_COMET_API_BASE_URL!;

function buildRequest(
    requestUrl: string,
    method: string = 'get',
    contentType: string,
    body?: any,
    accessToken?: any,
): Request {
    const headers: Headers = new Headers();

    headers.append('Accept', contentType);

    if (accessToken) {
        headers.append('Authorization', 'Bearer ' + accessToken);
    }

    let bodyStringified: string | undefined;

    if (body) {
        bodyStringified = JSON.stringify(body);
        headers.append('Content-Type', contentType);
    }

    return new Request(requestUrl, {
        body: bodyStringified,
        headers,
        method,
    });
}

async function checkStatus<T>(response: Response): Promise<ApiResponse<T> | undefined> {
    const resolvedResponse = await parseJSON<T, ApiResponse<T>>(response);

    if (response.status >= 200 && response.status < 300) {
        return resolvedResponse;
    }

    let message =
        resolvedResponse && resolvedResponse.message
            ? resolvedResponse.message.toString()
            : undefined;
    throw new Error(message || `Failed to complete request (${response.status}).`);
}

async function checkStatusForStream(response: Response): Promise<ApiResponse<string>> {
    if (response.status >= 200 && response.status < 300) {
        const responseText = await response.text();
        const streamResponse: ApiResponse<string> = {
            isSuccess: true,
            message: undefined,
            data: responseText,
        };

        return streamResponse;
    }

    let message =
        response && response.statusText
            ? response.statusText
            : undefined;
    throw new Error(message || `Failed to complete request (${response.status}).`);
}

function parseJSON<S, T extends ApiResponse<S>>(response: Response): Promise<T | undefined> {
    if (response.status !== 204) {
        return response.json() as Promise<T>;
    } else {
        return Promise.resolve({} as T);
    }
}

export async function httpRequest<T>(
    requestUrl: string,
    method: HttpMethod = HttpMethod.GET,
    body?: any,
    contentType: string = 'application/json',
): Promise<ApiResponse<T>> {
    try {
        const token = await authProvider.getAccessToken();

        // Construct request
        const request = buildRequest(requestUrl, method, contentType, body, token.accessToken);

        // Get response
        let response = await fetch(request);
        let result = await checkStatus<T>(response);

        if (result === undefined) {
            throw new Error('Http request failed');
        }

        result.isSuccess = true;
        return result;
    } catch (error) {
        return {
            isSuccess: false,
            message: error.message,
        };
    }
}

export async function httpRequestForStream(
    requestUrl: string,
    method: HttpMethod = HttpMethod.GET,
    body?: any,
    contentType: string = 'application/json',
): Promise<ApiResponse<string>> {
    try {
        const token = await authProvider.getAccessToken();

        // Construct request
        const request = buildRequest(requestUrl, method, contentType, body, token.accessToken);

        // Get response
        let response = await fetch(request);
        let result = await checkStatusForStream(response);

        if (result === undefined) {
            throw new Error('Http request failed');
        }

        result.isSuccess = true;
        return result;
    } catch (error) {
        return {
            isSuccess: false,
            message: error.message,
        };
    }
}