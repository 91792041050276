import React, { useEffect, useMemo } from 'react';
import * as linq from 'linq';
import {
    Button,
    createStyles,
    FormControl,
    Grid,
    makeStyles,
    MenuItem,
    MenuProps,
    Select,
    Tooltip,
    Typography,
} from '@material-ui/core';
import { CustomTheme } from '../../../styles/theme';
import { useState } from 'react';
import { getMarkets, MarketDto } from '../../../api/marketApi';
import { getAllMonitoringGroups, MonitoringGroupDto } from '../../../api/monitoringGroupApi';
import { getSites, SiteDto } from '../../../api/siteApi';
import { EventType, formatTypeForDisplay } from '../../../enums/eventType';
import { KeyboardDatePicker } from '@material-ui/pickers';
import { startOfDay, endOfDay } from 'date-fns';
import { EventsExportOptionsDto, exportEvents, previewExportEvents } from '../../../api/eventApi';
import { CheckboxMultiSelect, optionIsSelected } from '../../reusable/checkboxMultiSelect';
import { Option } from '../../reusable/option';
import { formatTimeForCSV, typedFormatWithoutHours } from '../../reusable/formatTime';
import {
    ReturnToServiceOption,
    formatOptionForDisplay,
} from '../../../enums/returnToServiceOption';

const useStyles = makeStyles((theme: CustomTheme) =>
    createStyles({
        eventsRoot: {
            boxShadow: '0px 2px 4px #FFFFFF29',
        },
        eventsContent: {
            padding: '1.5rem',
            paddingTop: '0.5rem',
        },
        topBar: {
            backgroundColor: theme.palette.extraColors.background1,
            listStyleType: 'none',
            '& li': {
                textAlign: 'center',
                float: 'left',
                marginRight: theme.spacing(4),
            },
            height: 70,
            padding: '1.5rem',
        },
        dateRange: {
            paddingLeft: '1rem',
            paddingRight: '1rem',
        },
        utcText: {
            marginLeft: 5,
        },
        dateInputLabel: {
            paddingLeft: 10,
        },
    }),
);

const allSitesKey = 'All';

export var selectMenuProp: Partial<MenuProps> = {
    anchorOrigin: {
        vertical: 'bottom',
        horizontal: 'left',
    },
    transformOrigin: {
        vertical: 'top',
        horizontal: 'left',
    },
    getContentAnchorEl: null,
};

export const ExportEventsPage = () => {
    const classes = useStyles();

    const [markets, setMarkets] = useState<MarketDto[]>([]);
    const [monitoringGroups, setMonitoringGroups] = useState<MonitoringGroupDto[]>([]);
    const [sites, setSites] = useState<SiteDto[]>([]);

    const [selectedMarketKey, setSelectedMarketKey] = useState('');
    const [selectedMonitoringGroup, setSelectedMonitoringGroup] = useState<
        MonitoringGroupDto | undefined
    >(undefined);
    const [isMarketsFetched, setIsMarketsFetched] = useState(false);

    const [selectedSiteKeys, setSelectedSiteKeys] = useState<string[]>([]);
    const [selectedEventTypes, setSelectedEventTypes] = useState<number[]>([]);

    const [exportStart, setExportStart] = React.useState<Date | null>(new Date());
    const [exportEnd, setExportEnd] = React.useState<Date | null>(new Date());

    const [
        selectedReturnToServiceOption,
        setSelectedReturnToServiceOption,
    ] = useState<ReturnToServiceOption>(ReturnToServiceOption.Include);
    const [isReturnToServiceTooltipOpen, setIsReturnToServiceTooltipOpen] = useState(false);

    const [numEvents, setNumEvents] = useState<number | null>(null);

    const navMonitoringGroupId = parseInt(localStorage.getItem('navMonitoringGroupId') || '0');
    const navMarketKey = localStorage.getItem('navMarketKey') || '';
    useEffect(() => {
        const navMonitoringGroup = linq
            .from(monitoringGroups)
            .firstOrDefault((mg) => mg.id === navMonitoringGroupId);

        setSelectedMonitoringGroup(navMonitoringGroup);
        setSelectedMarketKey(navMarketKey);
    }, [navMonitoringGroupId, monitoringGroups, navMarketKey]);

    useEffect(() => {
        const fetchMarkets = async () => {
            var response = await getMarkets();

            if (response.data) {
                setMarkets(response.data);

                const defaultMarketKey = navMarketKey;
                if (defaultMarketKey) {
                    setSelectedMarketKey(defaultMarketKey);
                }
            }
            setIsMarketsFetched(true);
        };
        fetchMarkets();
    }, [navMarketKey]);

    useEffect(() => {
        const fetchMonitoringGroups = async () => {
            var response = await getAllMonitoringGroups();

            if (response.data) {
                setMonitoringGroups(response.data);
            }
        };
        fetchMonitoringGroups();
    }, []);

    useEffect(() => {
        const fetchSites = async () => {
            var response = await getSites(undefined, undefined, false);

            if (response.data) {
                const allSites = {
                    key: allSitesKey,
                    name: 'All',
                    marketKey: '',
                    technology: '',
                    grids: [],
                } as SiteDto;

                const orderedSites = linq
                    .from(response.data)
                    .orderBy((s) => s.name)
                    .toArray();

                setSites([allSites, ...orderedSites]);
            }
        };
        fetchSites();
    }, []);

    const filteredSites = useMemo(() => {
        const fSites = sites.filter(
            (s) =>
                ((s.marketKey === selectedMarketKey || selectedMarketKey === 'global') &&
                    (linq.from(selectedMonitoringGroup?.siteKeys ?? []).contains(s.key) ||
                        selectedMonitoringGroup === undefined)) ||
                s.key === allSitesKey,
        );

        return fSites;
    }, [sites, selectedMarketKey, selectedMonitoringGroup]);

    const eventTypes = useMemo(() => {
        const options = Object.keys(EventType).map((value, index) => {
            const displayString = formatTypeForDisplay(value as EventType);
            return { id: index, name: displayString } as Option;
        });

        return options;
    }, []);

    const exportOptions = useMemo(() => {
        var selectedTypes: EventType[] = [];
        selectedEventTypes.forEach((e) => {
            var type = EventType[Object.keys(EventType)[e] as EventType];
            selectedTypes.push(type);
        });

        const options: EventsExportOptionsDto = {
            selectedSiteKeys,
            exportStart: exportStart ? startOfDay(exportStart) : new Date(),
            exportEnd: exportEnd ? endOfDay(exportEnd) : new Date(),
            eventTypes: selectedTypes,
            returnToServiceFilter: selectedReturnToServiceOption,
        };

        return options;
    }, [
        selectedSiteKeys,
        exportStart,
        exportEnd,
        selectedEventTypes,
        selectedReturnToServiceOption,
    ]);

    useEffect(() => {
        const previewExport = async () => {
            const result = await previewExportEvents(exportOptions);
            if (result.isSuccess && result.data !== undefined) {
                setNumEvents(result.data);
            }
        };

        previewExport();
    }, [exportOptions]);

    const getEventsExport = async () => {
        const result = await exportEvents(exportOptions);
        if (result.isSuccess && result.data !== undefined) {
            var exportTime = formatTimeForCSV(new Date());
            // Create the blob link to download
            const url = window.URL.createObjectURL(new Blob([result.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `EventsExport_${exportTime}.csv`);
            // Append to html page
            document.body.appendChild(link);
            // Force download
            link.click();
            // Clean up and remove the link
            link?.parentNode?.removeChild(link);
        }
    };

    const handleMarketSelection = (marketKey: string) => {
        setSelectedMarketKey(marketKey);
        setSelectedSiteKeys([]);
    };

    const handleMonitoringGroupSelection = (monitoringGroupId: number) => {
        const selectedMonitoringGroup = linq
            .from(monitoringGroups)
            .firstOrDefault((mg) => mg.id === monitoringGroupId);

        setSelectedMonitoringGroup(selectedMonitoringGroup);
        setSelectedSiteKeys([]);
    };

    const handleSiteSelection = (site: SiteDto) => {
        let updatedSelectedSiteKeys = [...selectedSiteKeys];
        var isSelected = optionIsSelected(site.key, selectedSiteKeys);

        if (isSelected) {
            if (site.key === allSitesKey) {
                updatedSelectedSiteKeys = [];
            } else {
                const siteKeyIndex = updatedSelectedSiteKeys.indexOf(site.key);
                updatedSelectedSiteKeys.splice(siteKeyIndex, 1);

                const allSitesKeyIndex = updatedSelectedSiteKeys.indexOf(allSitesKey);
                if (allSitesKeyIndex > -1) {
                    // 'All' sites is currently selected: de-select it because a site has just been
                    // de-selected, so the 'All' sites selection is no longer valid
                    updatedSelectedSiteKeys.splice(allSitesKeyIndex, 1);
                }
            }
        } else {
            if (site.key === allSitesKey) {
                updatedSelectedSiteKeys = linq
                    .from(filteredSites)
                    .select((s) => s.key)
                    .toArray();
            } else {
                updatedSelectedSiteKeys.push(site.key);
                if (updatedSelectedSiteKeys.length === filteredSites.length - 1) {
                    updatedSelectedSiteKeys.push(allSitesKey);
                }
            }
        }

        setSelectedSiteKeys(updatedSelectedSiteKeys);
    };

    const handleExportStartChange = (date: Date | null, a: string | null | undefined) => {
        setExportStart(date);
    };
    const handleExportEndChange = (date: Date | null, a: string | null | undefined) => {
        setExportEnd(date);
    };

    const handleEventTypeSelection = (eventType: Option) => {
        const updatedSelectedEventTypes = [...selectedEventTypes];
        var isSelected = optionIsSelected(eventType.id, selectedEventTypes);

        if (isSelected) {
            const eventTypeIndex = updatedSelectedEventTypes.indexOf(eventType.id);
            updatedSelectedEventTypes.splice(eventTypeIndex, 1);
        } else {
            updatedSelectedEventTypes.push(eventType.id);
        }

        setSelectedEventTypes(updatedSelectedEventTypes);
    };

    const handleTooltipOpen = () => {
        setIsReturnToServiceTooltipOpen(true);
    };

    const handleTooltipClose = () => {
        setIsReturnToServiceTooltipOpen(false);
    };

    return (
        <div className={classes.eventsRoot}>
            <div className={classes.topBar}>Export Events</div>
            <div className={classes.eventsContent}>
                {isMarketsFetched && (
                    <Grid container direction="column">
                        <Grid container item direction="row" alignItems="flex-start">
                            <Grid item xs={2}>
                                <Typography>Market</Typography>
                            </Grid>
                            <Grid item xs={2}>
                                <FormControl fullWidth>
                                    <Select
                                        value={selectedMarketKey}
                                        onChange={(event) =>
                                            handleMarketSelection(event.target.value as string)
                                        }
                                        MenuProps={selectMenuProp}
                                    >
                                        {markets.map((m) => (
                                            <MenuItem key={m.key} value={m.key}>
                                                {m.name}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Grid>
                        </Grid>
                        <Grid container item direction="row" alignItems="flex-start">
                            <Grid item xs={2}>
                                <Typography>Monitoring Group</Typography>
                            </Grid>
                            <Grid item xs={2}>
                                <FormControl fullWidth>
                                    <Select
                                        value={selectedMonitoringGroup?.id ?? 0}
                                        onChange={(event) =>
                                            handleMonitoringGroupSelection(
                                                event.target.value as number,
                                            )
                                        }
                                        MenuProps={selectMenuProp}
                                    >
                                        <MenuItem value={0}>All</MenuItem>
                                        {monitoringGroups.map((m) => (
                                            <MenuItem key={m.id} value={m.id}>
                                                {m.name}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Grid>
                        </Grid>
                        <Grid container item direction="row" alignItems="flex-start">
                            <Grid item xs={2}>
                                <Typography>Sites</Typography>
                            </Grid>
                            <Grid item xs={2}>
                                <CheckboxMultiSelect
                                    options={filteredSites}
                                    selectedOptions={selectedSiteKeys}
                                    displayAccessor={(site: SiteDto) => site.name}
                                    valueAccessor={(site: SiteDto) => site.key}
                                    onOptionSelect={(site: SiteDto) => handleSiteSelection(site)}
                                />
                            </Grid>
                        </Grid>
                        <Grid container item direction="row" alignItems="flex-start">
                            <Grid item xs={2}>
                                <Typography>Date</Typography>
                            </Grid>
                            <Grid container item direction="row" xs={10} alignItems="center">
                                <KeyboardDatePicker
                                    disableToolbar
                                    value={exportStart}
                                    onChange={handleExportStartChange}
                                    format={typedFormatWithoutHours}
                                />
                                <Typography className={classes.utcText}>UTC</Typography>
                                <Typography className={classes.dateRange}>-</Typography>
                                <KeyboardDatePicker
                                    disableToolbar
                                    value={exportEnd}
                                    onChange={handleExportEndChange}
                                    format={typedFormatWithoutHours}
                                />
                                <Typography className={classes.utcText}>UTC</Typography>
                            </Grid>
                        </Grid>
                        <Grid container item direction="row" alignItems="flex-start">
                            <Grid item xs={2}>
                                <Typography>Event Types</Typography>
                            </Grid>
                            <Grid item xs={2}>
                                <CheckboxMultiSelect
                                    options={eventTypes}
                                    selectedOptions={selectedEventTypes}
                                    displayAccessor={(eventTypeOption: Option) =>
                                        eventTypeOption.name
                                    }
                                    valueAccessor={(eventTypeOption: Option) => eventTypeOption.id}
                                    onOptionSelect={(eventTypeOption: Option) =>
                                        handleEventTypeSelection(eventTypeOption)
                                    }
                                />
                            </Grid>
                        </Grid>
                        <Grid container item direction="row" alignItems="flex-start">
                            <Grid item xs={2}>
                                <Typography>Return To Service</Typography>
                            </Grid>
                            <Grid item xs={2}>
                                <FormControl fullWidth>
                                    <Tooltip
                                        id="returnToServiceTooltip"
                                        title="Return to Service indicates an event was active for more than 72 hours"
                                        placement="right"
                                        open={isReturnToServiceTooltipOpen}
                                    >
                                        <Select
                                            value={selectedReturnToServiceOption}
                                            onChange={(event) => {
                                                console.log(event.target.value);
                                                setSelectedReturnToServiceOption(
                                                    ReturnToServiceOption[
                                                        event.target.value as ReturnToServiceOption
                                                    ],
                                                );
                                            }}
                                            onMouseEnter={handleTooltipOpen}
                                            onMouseLeave={handleTooltipClose}
                                            onClick={handleTooltipClose}
                                            MenuProps={selectMenuProp}
                                        >
                                            {Object.keys(ReturnToServiceOption).map(
                                                (option, index) => (
                                                    <MenuItem key={index} value={option}>
                                                        {formatOptionForDisplay(
                                                            option as ReturnToServiceOption,
                                                        )}
                                                    </MenuItem>
                                                ),
                                            )}
                                        </Select>
                                    </Tooltip>
                                </FormControl>
                            </Grid>
                        </Grid>

                        <Grid container item direction="row" alignItems="flex-start">
                            <Grid item xs={12}>
                                <Typography>{numEvents} events will be exported</Typography>
                            </Grid>
                        </Grid>

                        <Grid container item direction="row" alignItems="flex-start">
                            <Grid item xs={12}>
                                <Button color="primary" onClick={getEventsExport}>
                                    Download Csv
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>
                )}
            </div>
        </div>
    );
};
