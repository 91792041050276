import React, { useEffect, useState } from 'react';
import { makeStyles, createStyles, Grid, Tooltip } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import { CustomTheme } from '../../../styles/theme';
import { PopupContainer } from '../../reusable/popupContainer';
import { EventCommentDto, EventDto, getEventById } from '../../../api/eventApi';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import Skeleton from '@material-ui/lab/Skeleton';
import { formatTime } from '../../reusable/formatTime';
import CopyToClipboard from 'react-copy-to-clipboard';
import { formatTypeForDisplay } from '../../../enums/eventType';
import { EventScadaChart } from './eventScadaChart';
import { EventStatusChart } from './eventStatusChart';
import { EventAlarmChart } from './eventAlarmChart';
import { getAssetForDisplay } from './helpers/event-helpers';
import { EventCommentList } from './eventCommentList';
import { EventNewComment } from './eventNewComment';
import { EscalateEventButtonContainer } from './escalation/escalateEventButtonContainer';

const useStyles = makeStyles((theme: CustomTheme) =>
    createStyles({
        popupContent: {
            marginTop: 5,
            color: theme.palette.text.secondary,
        },
        topHalf: {
            display: 'flex',
            height: 300,
        },
        column1: {
            width: '43%',
        },
        column2: {
            width: '46%',
            display: 'flex',
            flexDirection: 'column',
        },
        column3: {
            width: '11%',
            display: 'flex',
            flexDirection: 'column',
        },
        copyText: {
            marginRight: 8,
        },
        leftColumn: {
            fontWeight: 'bold',
        },
        commentInputBox: {},
        commentList: {
            flexGrow: 1,
            overflow: 'auto',
        },
        emptyRow: {
            height: 20,
        },
        hidden: {
            display: 'none',
        },
        copyLinkContainer: {
            margin: '3px',
            padding: '3px',
        },
        copyLink: {
            width: '100%',
            backgroundColor: theme.palette.primary.main,
            color: theme.palette.extraColors.opposite,
            borderRadius: 0,
        },
    }),
);

let iconStyles = {
    fontSize: 16,
    marginBottom: -3,
};

interface EventPopupProps {
    handleClose: (newData: boolean) => void;
    currentData: EventDto;
    durationString: string;
    addCommentLoading: boolean;
    commentMessage?: string;
    addComment: (
        newComment: string,
        commentList: EventCommentDto[],
        setCommentList: (comments: EventCommentDto[]) => void,
    ) => void;
}

export const EventPopup = (props: EventPopupProps) => {
    const classes = useStyles();
    const {
        handleClose,
        currentData,
        durationString,
        addCommentLoading,
        commentMessage,
        addComment,
    } = props;

    // the last occurrence of an event is only included when fetching event details
    // however, we load this popup with most of the event data ready to go
    // so we immedaitely display all the event information we have, but then asyncronously fetch the full details
    // once we have it, we augment what's currently displayed with the additional information we fetched
    const [lastOccurrence, setLastOccurrence] = useState<Date | null>();
    const [commentList, setCommentList] = useState<EventCommentDto[]>([]);
    const [confirmAssetNotThirdParty, setConfirmAssetNotThirdParty] = useState<boolean>(false);

    const copyData = () => {
        return (
            `Technology\t${currentData.technology}\n` +
            `Asset\t\t${getAssetForDisplay(currentData)}\n` +
            `Site\t\t${currentData.siteName}\n` +
            `Event Type\t${formatTypeForDisplay(currentData.eventType)}\n` +
            `Start Time\t${formatTime(currentData.startTime)}\n` +
            `Duration\t${durationString}\n` +
            `Message\t\t${currentData.message}\n` +
            `Last Comment\t${currentData.lastComment}\n` +
            `Last Occurrence\t${
                lastOccurrence && lastOccurrence !== null ? `${formatTime(lastOccurrence)}` : 'N/A'
            }`
        );
    };

    useEffect(() => {
        const fetchEventDetails = async () => {
            const result = await getEventById(props.currentData.id);
            if (result.data) {
                setLastOccurrence(result.data.previousOccurrence);
                setCommentList(result.data.eventComments);
                setConfirmAssetNotThirdParty(!result.data.isAssetThirdParty);
            }
        };

        fetchEventDetails();
    }, [props.currentData.id]);

    const addCommentInternal = async (newComment: string) => {
        await addComment(newComment, commentList, setCommentList);
    };

    return (
        <PopupContainer
            titleText={'Event Details'}
            isEditing={false}
            handleClose={handleClose}
            dataCopy={currentData}
            popupSize={'md'}
            hideButtons
            getContent={() => {
                return (
                    <div className={classes.popupContent}>
                        <div className={classes.topHalf}>
                            <div className={classes.column1}>
                                <Grid container id="areaToCopy" alignItems="flex-start">
                                    <Grid item xs={6} md={5} className={classes.leftColumn}>
                                        Technology
                                    </Grid>
                                    <Grid item xs={6} md={7}>
                                        {currentData.technology}
                                    </Grid>
                                    <Grid item xs={6} md={5} className={classes.leftColumn}>
                                        Asset
                                    </Grid>
                                    <Grid item xs={6} md={7}>
                                        {getAssetForDisplay(currentData)}
                                    </Grid>
                                    <Grid item xs={6} md={5} className={classes.leftColumn}>
                                        Site
                                    </Grid>
                                    <Grid item xs={6} md={7}>
                                        {currentData.siteName}
                                    </Grid>
                                    <Grid item xs={6} md={5} className={classes.leftColumn}>
                                        Event Type
                                    </Grid>
                                    <Grid item xs={6} md={7}>
                                        {formatTypeForDisplay(currentData.eventType)}
                                    </Grid>
                                    <Grid item xs={6} md={5} className={classes.leftColumn}>
                                        Start Time
                                    </Grid>
                                    <Grid item xs={6} md={7}>
                                        {formatTime(currentData.startTime)}
                                    </Grid>
                                    <Grid item xs={6} md={5} className={classes.leftColumn}>
                                        Duration
                                    </Grid>
                                    <Grid item xs={6} md={7}>
                                        {durationString}
                                    </Grid>
                                    <Grid item xs={6} md={5} className={classes.leftColumn}>
                                        Message
                                    </Grid>
                                    <Grid item xs={6} md={7}>
                                        {currentData.message}
                                    </Grid>
                                    <Grid item xs={6} md={5} className={classes.leftColumn}>
                                        Last Occurrence
                                    </Grid>
                                    <Grid item xs={6} md={7}>
                                        {lastOccurrence === undefined ? (
                                            <Skeleton />
                                        ) : lastOccurrence !== null ? (
                                            `${formatTime(lastOccurrence)}`
                                        ) : (
                                            'N/A'
                                        )}
                                    </Grid>
                                    <Grid item xs={12}>
                                        <div className={classes.emptyRow}></div>
                                    </Grid>
                                </Grid>
                            </div>
                            <div className={classes.column2}>
                                <div className={classes.commentList}>
                                    <EventCommentList comments={commentList} />
                                </div>
                                <div className={classes.commentInputBox}>
                                    <EventNewComment
                                        addComment={addCommentInternal}
                                        loading={addCommentLoading}
                                        commentsMessage={commentMessage}
                                    />
                                </div>
                            </div>
                            <div className={classes.column3}>
                                <div className={classes.copyLinkContainer}>
                                    <Tooltip title="Copy data for this event to your clipboard.">
                                        <Button
                                            onClick={copyData}
                                            className={classes.copyLink}
                                            endIcon={<FileCopyIcon style={iconStyles} />}
                                        >
                                            <CopyToClipboard text={copyData()}>
                                                <span className={classes.copyText}>Copy</span>
                                            </CopyToClipboard>
                                        </Button>
                                    </Tooltip>
                                </div>
                                <EscalateEventButtonContainer
                                    event={currentData}
                                    commentList={commentList}
                                />
                            </div>
                        </div>
                        <div>
                            <Grid container id="liveDataArea" alignItems="flex-start">
                                {currentData.assetKey !== null && confirmAssetNotThirdParty && (
                                    <>
                                        <EventScadaChart
                                            technology={currentData.technology}
                                            assetKey={currentData.assetKey}
                                            startTime={currentData.startTime}
                                        />
                                        <EventStatusChart
                                            assetKey={currentData.assetKey}
                                            startTime={currentData.startTime}
                                        />
                                        <EventAlarmChart
                                            assetKey={currentData.assetKey}
                                            startTime={currentData.startTime}
                                        />
                                    </>
                                )}
                            </Grid>
                        </div>
                    </div>
                );
            }}
        />
    );
};
