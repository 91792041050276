export type openEmailProps = {
    to: string;
    cc?: string;
    body: string;
    subject: string;
};
const encodeURL = (body: string) =>{
    return encodeURIComponent(body);
};

export const generateMail = (props: openEmailProps) => {
    let mail = `mailto:${encodeURL(props.to)}?body=${encodeURL(props.body)}&subject=${encodeURL(props.subject)}`;
    if (!!props.cc) {
        mail = `${mail}&cc=${encodeURL(props.cc)}`;
    }
    return mail;
};

export const openEmail = (props: openEmailProps) => {
    let mail = generateMail(props);
    window.location.href = mail;
};