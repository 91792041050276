import React, { useState } from 'react';
import { EventCommentDto, EventDto } from '../../../../api/eventApi';
import { authProvider } from '../../../../auth/authProvider';
import { EscalateEventButton } from './escalateEventButton';

type ContainerProps = {
    event: EventDto;
    commentList: EventCommentDto[];
};


const EscalateEventButtonContainer = ({ event, commentList }: ContainerProps) => {
    const [dataLoading, setDataLoading] = useState(true);

    Promise.resolve(new Promise((res) => setTimeout(res, 500))).then(() => {
        setDataLoading(false);
    });

    const currentUser = authProvider.getAccountInfo()?.account.name ?? '';

    return (
        <EscalateEventButton
            loading={dataLoading}
            event={event}
            commentList={commentList}
            currentUserName={currentUser}
        />
    );
};

export { EscalateEventButtonContainer };
