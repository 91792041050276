import { EventDto } from '../../../../api/eventApi';

export const getAssetForDisplay = (row: EventDto) => {
    return row.assetName;
};

export const sortAssets = (a: EventDto, b: EventDto) => {
    var str1 = getAssetForDisplay(a);
    var str2 = getAssetForDisplay(b);
    if (str2 < str1) {
        return -1;
    }
    if (str2 > str1) {
        return 1;
    }
    return 0;
};

export const sortDuration = (a: EventDto, b: EventDto) => {
    if (b.startTime < a.startTime) {
        return -1;
    }
    if (b.startTime > a.startTime) {
        return 1;
    }
    return 0;
};

export const commentPlaceholder =
    'Event Description : \nEvent code : \nSummary of action taken : \nAre faults resettable on this asset? : \nWas a reset attempted? : \nWas the reset successful? : \nHow many attempts? :';
