import {
    FormControl,
    FormControlLabel,
    Grid,
    Radio,
    RadioGroup,
    Checkbox,
    TextField,
} from '@material-ui/core';
import { RecurrenceDay } from '../../../enums/recurrenceDay';
import { RecurrenceType } from '../../../enums/recurrenceType';
import { useScheduledItemPopupStyles } from '../../../styles/scheduledItemPopupStyle';

interface RecurrenceSelectorProps {
    recurrence: RecurrenceType;
    setRecurrence: (recurrenceValue: RecurrenceType) => void;
    weeklyRecurrence: RecurrenceDay;
    updateWeeklyRecurrence: (day: RecurrenceDay, selected: boolean) => void;
    recurringDay: number;
    updateRecurringDay: (dayInput: string) => void;
    selectorError: boolean;
}

export const RecurrenceSelector = (props: RecurrenceSelectorProps) => {
    const classes = useScheduledItemPopupStyles() as any;
    // Generate array of Recurrence Day values that are equal to ints
    // Both int values and string keys are generated by Objects.values due to reverse mapping,
    // so the string keys need to be filtered out
    // Ignore the RecurrenceDay.None item as it should not be displayed as an option in the UI
    const recurrenceDays = Object.values(RecurrenceDay).filter(
        (v) => !isNaN(Number(v)) && Number(v) !== 0,
    ) as Array<keyof typeof RecurrenceDay>;

    const renderRecurrenceOptions = (recurrenceType: RecurrenceType) => {
        switch (recurrenceType) {
            case RecurrenceType.Weekly:
                return (
                    <>
                        {recurrenceDays.map((idx) => {
                            return (
                                <Grid item key={idx} xs={3}>
                                    <FormControlLabel
                                        onChange={(_, checked) => {
                                            const recurrenceDay = parseInt(idx);
                                            props.updateWeeklyRecurrence(recurrenceDay, checked);
                                        }}
                                        control={<Checkbox />}
                                        label={RecurrenceDay[idx]}
                                    />
                                </Grid>
                            );
                        })}
                    </>
                );
            case RecurrenceType.Monthly:
                return (
                    <>
                        <Grid item xs={2}>
                            Day
                        </Grid>
                        <Grid item xs={4}>
                            <TextField
                                type="number"
                                value={props.recurringDay}
                                variant="standard"
                                onChange={(e) => props.updateRecurringDay(e.target.value)}
                                error={props.selectorError}
                                helperText={props.selectorError ? 'Input out of bounds' : ''}
                            />
                        </Grid>
                    </>
                );
        }
    };

    return (
        <Grid container className={classes.popupContent}>
            <Grid item xs={3}>
                <FormControl className={classes.radioButtonSelector}>
                    <RadioGroup
                        defaultValue={RecurrenceType.Daily}
                        name="recurrence-types"
                        value={props.recurrence}
                    >
                        <FormControlLabel
                            value={RecurrenceType.Daily}
                            onClick={() => props.setRecurrence(RecurrenceType.Daily)}
                            control={<Radio />}
                            label="Daily"
                            className={classes.radioButton}
                        />
                        <FormControlLabel
                            value={RecurrenceType.Weekly}
                            onClick={() => props.setRecurrence(RecurrenceType.Weekly)}
                            control={<Radio />}
                            label="Weekly"
                            className={classes.radioButton}
                        />
                        <FormControlLabel
                            value={RecurrenceType.Monthly}
                            onClick={() => props.setRecurrence(RecurrenceType.Monthly)}
                            control={<Radio />}
                            label="Monthly"
                            className={classes.radioButton}
                        />
                    </RadioGroup>
                </FormControl>
            </Grid>
            <Grid container spacing={1} item xs={9} alignItems="center">
                {renderRecurrenceOptions(props.recurrence)}
            </Grid>
        </Grid>
    );
};
