export type NamedEntity = {
    name: string;
};

export const sortAlphabetically = <T extends NamedEntity>(
    arr: T[],
    sortDirection: 'asc' | 'desc' = 'asc',
    firstElementOverride: string | null = null,
): T[] => {
    const overrideElement = !!firstElementOverride
        ? arr.find((a) => a.name === firstElementOverride)
        : undefined;
    const arrayToOrder = !!overrideElement
        ? arr.filter((item) => item.name !== firstElementOverride)
        : [...arr];

    arrayToOrder.sort((a: T, b: T) => {
        return sortDirection === 'asc'
            ? a.name.localeCompare(b.name)
            : b.name.localeCompare(a.name);
    });

    return !!overrideElement ? [overrideElement, ...arrayToOrder] : arrayToOrder;
};

export const sort = <T, K extends keyof T>(
    arr: T[],
    sortDirection: 'asc' | 'desc' = 'asc',
    field: K,
): T[] => {
    const arrayToOrder = [...arr];
    arrayToOrder.sort((a: T, b: T) => {
        if (a[field] < b[field]) {
            return -1;
        }

        if (a[field] > b[field]) {
            return 1;
        }

        return 0;
    });

    return sortDirection === 'asc' ? arrayToOrder : arrayToOrder.reverse();
};
