import React from 'react';
import { createStyles, Link, makeStyles } from '@material-ui/core';
import { CustomTheme } from '../../../styles/theme';

const useStyles = makeStyles((theme: CustomTheme) =>
    createStyles({
        link: {
            marginRight: theme.spacing(1),
            color: theme.palette.text.secondary,
            opacity: '50%',
            textAlign: 'center',
        },
        activeLink: {
            marginRight: theme.spacing(1),
            color: theme.palette.text.secondary,
            opacity: '100%',
            textAlign: 'center',
        },
        circle: {
            borderRadius: 100,
            padding: '3px 0px',
            display: 'inline-block',
            height: 25,
            width: 25,
            textAlign: 'center',
        },
        redCircle: {
            backgroundColor: theme.palette.extraColors.button1,
        },
        greyCircle: {
            backgroundColor: theme.palette.extraColors.button3,
        },
        container: {
            display: 'flex',
            flexDirection: 'row',
            padding: theme.spacing(1),
        },
    }),
);

type ButtonWithCountProps = {
    onClick: () => void;
    text: string;
    count: number;
    isActive: boolean;
};
const ButtonWithCount = ({ onClick, text, count, isActive }: ButtonWithCountProps) => {
    const classes = useStyles();
    return (
        <div className={classes.container}>
            <Link
                component="button"
                onClick={onClick}
                className={isActive ? classes.activeLink : classes.link}
            >
                {text}
            </Link>
            <span
                className={
                    classes.circle + ' ' + (isActive ? classes.redCircle : classes.greyCircle)
                }
            >
                {count}
            </span>
        </div>
    );
};

export { ButtonWithCount };
