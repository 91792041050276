import moment from 'moment';

export const now = (): Date => new Date(Date.now());

export const dateIsInPast = (date?: string | Date, offsetInMinutes: number = 0): boolean => {
    if (!date) {
        return false;
    }

    const parsedDate = date instanceof Date ? date : new Date(Date.parse(date));

    const dateWithOffset = moment(parsedDate).add(offsetInMinutes, 'm').toDate();

    const timeNow = now();

    return !!dateWithOffset && dateWithOffset < timeNow;
};
