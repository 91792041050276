import { FormControl, MenuItem, Select } from '@material-ui/core';
import LinearProgress from '@material-ui/core/LinearProgress';
import { ExpandMore } from '@material-ui/icons';
import React, { useCallback } from 'react';
import { useGetAssetsForSiteQuery } from '../../../../api/apiSlice';
import { RecurringEventDto } from '../../../../api/recurringEventApi';
import { useScheduledItemPopupStyles } from '../../../../styles/scheduledItemPopupStyle';
import { selectMenuProp } from '../../exportEvents/exportEventsPage';

type AssetSelectProps = {
    data: RecurringEventDto;
    setData: (data: RecurringEventDto) => void;
};

const AssetSelection = ({ data, setData }: AssetSelectProps) => {
    const classes = useScheduledItemPopupStyles();

    const {
        isFetching: assetsLoading,
        data: assetList,
        isError: assetsError,
    } = useGetAssetsForSiteQuery(data.siteKey);

    const onChange = useCallback(
        (e: React.ChangeEvent<{ name?: string; value: unknown }>) => {
            const key = e.target.value as string;
            if (key === '') {
                setData({
                    ...data,
                    assetName: '',
                    assetKey: '',
                });
                return;
            }

            const asset = assetList?.find((e) => e.name === key);
            if (asset) {
                setData({
                    ...data,
                    assetName: asset.name,
                    assetKey: asset.key,
                });
            }
        },
        [assetList, data, setData],
    );

    if (assetsError) {
        return <>Failed to load assets for this site.</>;
    }

    if (assetsLoading) {
        return <LinearProgress />;
    }

    return (
        <FormControl fullWidth>
            <Select
                value={data.assetName}
                disabled={false}
                onChange={onChange}
                IconComponent={() => <ExpandMore className={classes.expandIcons} />}
                MenuProps={selectMenuProp}
            >
                <MenuItem key="NA" value="">
                    N/A
                </MenuItem>
                {assetList?.map((asset) => {
                    return (
                        <MenuItem key={asset.key} value={asset.name}>
                            {asset.name}
                        </MenuItem>
                    );
                })}
            </Select>
        </FormControl>
    );
};

export { AssetSelection };
