import { configureStore, ThunkAction, Action, autoBatchEnhancer } from '@reduxjs/toolkit';
import { apiSlice } from '../api/apiSlice';
import { eventFiltersSlice } from './eventFiltersSlice';
import { eventPopupSlice } from './eventPopupSlice';
import { eventsSlice } from './eventsSlice';
import { rootSlice } from './rootReducerSlice';
import { scheduledEventsSlice } from './scheduledEventsSlice';

export const store = configureStore({
    reducer: {
        [rootSlice.name]: rootSlice.reducer,
        [eventPopupSlice.name]: eventPopupSlice.reducer,
        [eventFiltersSlice.name]: eventFiltersSlice.reducer,
        [eventsSlice.name]: eventsSlice.reducer,
        [scheduledEventsSlice.name]: scheduledEventsSlice.reducer,
        [apiSlice.reducerPath]: apiSlice.reducer,
    },
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({ serializableCheck: false }).concat(apiSlice.middleware),
    enhancers: (existingEnhancers) => {
        return existingEnhancers.concat(autoBatchEnhancer());
    },
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
    ReturnType,
    RootState,
    unknown,
    Action<string>
>;
